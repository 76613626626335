import { RegisterZaloOaRequest, UpdateZaloOaRequest } from "../dataType/smsZaloOa";
import client from "./apiConfig";

export default {
  getInfoZaloOa() {
    const url = `/sms/zalo/search`;
    return client.get(url)
  },
  registerInfoZalo(params: RegisterZaloOaRequest) {
    const url = `/sms/zalo`;
    return client.post(url, params).then(response => {
      return response;
    });
  },
  updateInfoZaloOa(params: UpdateZaloOaRequest) {
    const url = `/sms/zalo`;
    return client.put(url, params).then(response => {
      return response;
    });
  },
  deleteInfoZaloOa(zalo_id: number) {
    const url = `/sms/zalo?zalo_id=${zalo_id}`;
    return client.delete(url).then(response => {
      return response;
    });
  }
}