import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../api";
import { DISCOUNT_TYPE } from "../../constants";
import { DrugInv } from "../../dataType/Inventory";
import { AddProductGiftToInvoiceRequest, InvoiceProduct, InvoiceRequest, PromotionProductInvoiceType } from "../../dataType/Invoice";
import { Promotion, PromotionConditionDetail } from "../../dataType/Promotion";
import { ResponseAxios } from "../../dataType/Response";
import { updateInvoice } from "../actions/invoice";
import { ADD_PRODUCT_GIFT_TO_INVOICE, UPDATE_PROMOTION_PRODUCT } from "../types";

function* updatePromotionProductSaga(action: { type: string, payload: PromotionProductInvoiceType }) {
  const { productUpdate, indexUpdate, activeKey, invoiceRequest } = action.payload;
  if (productUpdate.listPromotion && productUpdate.listPromotion.length > 0 && !productUpdate.promotion_flg) {
    for (const itemPromotion of productUpdate.listPromotion) {
      // Check điều kiện trong khuyến mãi
      if (itemPromotion.status) {
        const conditionAvailable: PromotionConditionDetail | null = yield call(checkPromotionAvailable, itemPromotion, productUpdate);
        if (conditionAvailable !== null) {
          const promotionCondition: PromotionConditionDetail = conditionAvailable;
          invoiceRequest.products[indexUpdate].promotion_id = itemPromotion.promotion_id
          // Tìm sản phẩm khuyến mãi trong đơn hàng.  Nếu có thì không thêm, nếu chưa có thì thêm sản phẩm khuyến mãi
          const productPromotion = invoiceRequest.products.find(item => (item.promotion_id === itemPromotion.promotion_id && item.promotion_flg === 1));
          if (!productPromotion) {
            const productNew: InvoiceProduct | null = yield call(addDiscountProduct, promotionCondition, productUpdate, invoiceRequest, itemPromotion, activeKey, indexUpdate);
            if (productNew !== null) {
              const productUpdateDiscount: InvoiceProduct = productNew
              invoiceRequest.products[indexUpdate] = productUpdateDiscount
            }
          }
        } else {
          // Nếu khuyến mãi không hợp lệ và trong danh sách sản phẩm đã có sản phẩm khuyến mại thì xóa sản phẩm đó khỏi đơn hàng
          const listProductNew = invoiceRequest.products.filter(item =>
            (item.promotion_id !== itemPromotion.promotion_id)
            || (item.promotion_id === itemPromotion.promotion_id && item.promotion_flg === 0)
          );
          invoiceRequest.products = listProductNew;
        }
      }
    }
  }
  yield put(updateInvoice(activeKey, invoiceRequest));
}

function* checkPromotionAvailable(promotionItem: Promotion, productUpdate: InvoiceProduct) {
  for (const itemCondition of promotionItem.promotion_conditions) {
    const conditionAvailable = itemCondition.condition_products.find(item => item.product_id === productUpdate.drug_id);
    if (conditionAvailable && conditionAvailable.min_quantity <= productUpdate.quantity && conditionAvailable.max_quantity >= productUpdate.quantity) {
      return itemCondition;
    }
  }
  return null;
}

function* addDiscountProduct(
  promotionCondition: PromotionConditionDetail,
  productUpdate: InvoiceProduct,
  invoiceRequest: InvoiceRequest,
  itemPromotion: Promotion,
  activeKey: string,
  indexUpdate: number,
) {
  for (const itemGift of promotionCondition.gift_requests) {
    // Nếu khuyến mãi discount cập nhật discount cho product
    if (itemGift.product_discount) {
      let discount_unit = itemGift.product_discount;
      const discount = Math.round(discount_unit * productUpdate.price * productUpdate.quantity / 10000) * 100;
      productUpdate.discount = discount;
      productUpdate.discount_type = DISCOUNT_TYPE.PERCENT;
      productUpdate.discount_unit = discount_unit;
      return productUpdate
    }
    // Nếu khuyến mãi là tặng sản phẩm thì thêm sản phẩm quà tặng và đơn hàng
    if (itemGift.gift_product) {
      yield* addProductGiftInvoice({ type: ADD_PRODUCT_GIFT_TO_INVOICE, payload: { data: itemGift, promotionRequest: itemPromotion, activeKey, invoiceRequest, indexUpdate } })
    }
  }
  return null
}

function* addProductGiftInvoice(action: { type: string, payload: AddProductGiftToInvoiceRequest }) {
  const { data, promotionRequest, activeKey, invoiceRequest, indexUpdate } = action.payload;
  const promotion = invoiceRequest.promotion
  try {
    const res: ResponseAxios = yield call(api.inventory.getListDrugInInv, { drug_id: data.gift_product, exp_date: true })
    const productInfo: DrugInv = res.data.data[0]
    const unitIndex: number = productInfo.units.findIndex(item => item.unit_name === data.gift_unit);
    const productGift: InvoiceProduct = {
      inv_id: productInfo.inv_id,
      drug_id: productInfo.drug_id,
      drg_drug_cd: productInfo.drg_drug_cd,
      lot: productInfo.lot,
      drg_drug_name: productInfo.drg_drug_name,
      quantity: data.gift_quantity,
      base_quantity: 1,
      price: productInfo.units[unitIndex].price,
      wholesale_price: productInfo.units[unitIndex].wholesale_price,
      dosage: productInfo.dosage,
      discount: productInfo.units[unitIndex].price,
      discount_type: DISCOUNT_TYPE.MONEY,
      discount_unit: productInfo.units[unitIndex].price,
      drug_unit_id: productInfo.units[unitIndex].drug_unit_id,
      unit_id: productInfo.units[unitIndex].unit_id,
      unit_name: productInfo.units[unitIndex].unit_name,
      promotion_flg: 1,
      units: productInfo.units,
      inv_qty: productInfo.quantity,
      import_price: productInfo.price ? productInfo.price : 0,
      promotion_id: promotionRequest ? promotionRequest.promotion_id : promotion?.promotion_id,
      promotion_name: promotionRequest ? promotionRequest.promotion_name : promotion?.promotion_name,
      promotion_reward: productInfo.units[unitIndex].price * data.gift_quantity,
    }
    invoiceRequest.products.splice(indexUpdate + 1, 0, productGift)
  } catch (error) {
    console.log('err')
  }
}

export default function* () {
  yield takeLatest(UPDATE_PROMOTION_PRODUCT, updatePromotionProductSaga);
  yield takeLatest(ADD_PRODUCT_GIFT_TO_INVOICE, addProductGiftInvoice)
}
