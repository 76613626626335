import { ApproveParam, CompanyRegisterRequest, RegisterFormRequest, RegisterParams } from "../dataType/SellingPoint"
import client from "./apiConfig"

export default {
  async getListRegisteredCompany(page: number, size: number, data: CompanyRegisterRequest) {
    const url = `/pharmacy/selling-point/store?page=${page}&size=${size}&company_name=${data.company_name}&status=${data.status}&company_id=${data.company_id}`
    const res = await client.get(url)
    return res
  },
  async createRegisterForm(data: RegisterParams) {
    const url = '/pharmacy/selling-point/store'
    const res = await client.post(url, data)
    return res
  },
  async updateRegisterForm(registration_id: number, data: RegisterParams) {
    const url = `/pharmacy/selling-point/store/${registration_id}`
    const res = await client.put(url, data)
    return res
  },
  async deleteRegisterForm(registration_id: number) {
    const url = `/pharmacy/selling-point/store/${registration_id}`
    const res = await client.delete(url)
    return res
  },
  async getListRegisterForm(page: number, size: number, data: RegisterFormRequest) {
    const url = `/pharmacy/selling-point/company?page=${page}&size=${size}&drg_name=${data.drg_name}&status=${data.status}&city=${data.city}&district=${data.district}`
    const res = await client.get(url)
    return res
  },
  async approveRegisterForm(registration_id: number, data: ApproveParam) {
    const url = `/pharmacy/selling-point/company/${registration_id}`
    const res = await client.put(url, data)
    return res
  }
}