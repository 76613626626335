import { SettingPrintParams, SettingPrintReqest } from "../dataType/Setting";
import client from "./apiConfig";

export default {
  async getSettingPrint(params: SettingPrintParams) {
    const res = await client.get(`/pharmacy/store/setting/print?document_type_code=${params.document_type_code}&drg_store_id=${params.drg_store_id}&document_size=${params.document_size}`)
    return res
  },
  async updateSettingNew(data: SettingPrintReqest, set_default?: boolean) {
    const url = `/pharmacy/store/setting/print?${set_default && `set_default=${set_default}`}`
    const res = await client.post(url, data)
    return res
  },
  async getHistorySetting(page: number, size: number, drg_store_id: number) {
    const url = `/pharmacy/store/setting/print/history?page=${page}&size=${size}&drg_store_id=${drg_store_id}`
    const res = await client.get(url)
    return res
  }
}
