import {PromotionNewCreateModel,} from "../../dataType/PromotionNew";
import {LOG_OUT, PROMOTION_NEW} from "../types";

const initPromotionNew: PromotionNewCreateModel = {
  promotion_name: '',
  promotion_type: '',
  applicable_type: '',
  priority: 0,
  from_time: '',
  to_time: '',
  send_noti_type: '',
  send_noti_time: '',
  description: '',
  short_description: '',
  drg_store_ids: [],
  customer_group_ids: [],
  business_type: [],
  sources: [],
  max_uses: 0,
  uses_left: 0,
  reason_edit: '',
  promotion_inv_condition_news: [],
  promotion_product_condition_news: [],
}

export default function promotionNew(state = initPromotionNew, action: any) {
  switch (action.type) {
    case PROMOTION_NEW.CHANGE_TYPE_APPLICABLE_TYPE:
      return {
        ...state,
        applicable_type: action.payload,
      }
    case PROMOTION_NEW.UPDATE_PROMOTION_NEW:
      return action.payload;
    case PROMOTION_NEW.RESET_PROMOTION_NEW:
      return {
        promotion_name: initPromotionNew.promotion_name,
        promotion_type: initPromotionNew.promotion_type,
        applicable_type: initPromotionNew.applicable_type,
        priority: initPromotionNew.priority,
        from_time: initPromotionNew.from_time,
        to_time: initPromotionNew.to_time,
        send_noti_type: initPromotionNew.send_noti_type,
        send_noti_time: initPromotionNew.send_noti_time,
        description: initPromotionNew.description,
        short_description: initPromotionNew.short_description,
        drg_store_ids: initPromotionNew.drg_store_ids,
        customer_group_ids: initPromotionNew.customer_group_ids,
        business_type: initPromotionNew.business_type,
        sources: initPromotionNew.sources,
        max_uses: initPromotionNew.max_uses,
        uses_left: initPromotionNew.uses_left,
        reason_edit: initPromotionNew.reason_edit,
        promotion_inv_condition_news: initPromotionNew.promotion_inv_condition_news,
        promotion_product_condition_news: initPromotionNew.promotion_product_condition_news
      };

    case LOG_OUT:
      return {
        promotion_name: initPromotionNew.promotion_name,
        promotion_type: initPromotionNew.promotion_type,
        applicable_type: initPromotionNew.applicable_type,
        priority: initPromotionNew.priority,
        from_time: initPromotionNew.from_time,
        to_time: initPromotionNew.to_time,
        send_noti_type: initPromotionNew.send_noti_type,
        send_noti_time: initPromotionNew.send_noti_time,
        description: initPromotionNew.description,
        short_description: initPromotionNew.short_description,
        drg_store_ids: initPromotionNew.drg_store_ids,
        customer_group_ids: initPromotionNew.customer_group_ids,
        business_type: initPromotionNew.business_type,
        sources: initPromotionNew.sources,
        max_uses: initPromotionNew.max_uses,
        uses_left: initPromotionNew.uses_left,
        reason_edit: initPromotionNew.reason_edit,
        promotion_inv_condition_news: initPromotionNew.promotion_inv_condition_news,
        promotion_product_condition_news: initPromotionNew.promotion_product_condition_news
      };
    default:
      return state;
  }
}
