import { put, select, takeLatest } from 'redux-saga/effects';
import { DrgUnit, SupplierProductData } from '../../dataType/Product';
import { OrderSupplierSelected } from '../../dataType/SupplierOrder';
import { RootState } from '../reducers';
import { addInvoiceCart, addProductCart, updateProductCart } from '../actions/cart';
import { ICON_MEDICINE } from "../../assets"

import {
  ACTION_CART_ADD_PRODUCT,
  ACTION_CART_REMOVE_PRODUCT,
  ACTION_CART_PRODUCT_UPDATE_QUANTITY
} from '../types';
import { CURRENCIES, DISCOUNT_TYPE, INVOICE_PAY_METHOD } from '../../constants';

type ActionAddProduct = {
  type: string
  payload: {
    product: SupplierProductData,
    quantity: number
  }
}

const totalMoney = (orderSupplier: OrderSupplierSelected) => {
  return orderSupplier.products.reduce((cur, acc) => cur + (acc.price * acc.quantity), 0);
}

function* addProduct(action: ActionAddProduct) {
  const { product, quantity } = action.payload
  const quantityNew = quantity ? quantity : 1
  const productUnitsMax: DrgUnit = product.units.reduce((prev, current) => ((prev.unit_qty > current.unit_qty) ? prev : current))
  let orderSuppliers: OrderSupplierSelected[] = yield select((state: RootState) => state.cart.orderSuppliers)
  const indexComapnyExits =
    orderSuppliers.length > 0 && orderSuppliers.findIndex(item => item.company_id === product.company_id)
  if ((indexComapnyExits && indexComapnyExits !== -1) || indexComapnyExits === 0) {
    const indexProductExist =
      orderSuppliers[indexComapnyExits].products.length > 0 &&
      orderSuppliers[indexComapnyExits].products.findIndex(item => item.drug_id === product.drug_id)
    if ((indexProductExist !== -1 && indexProductExist) || indexProductExist === 0) {
      const orderNew = JSON.parse(JSON.stringify(orderSuppliers))
      const numberProNew = orderNew[indexComapnyExits].products[indexProductExist].quantity + quantityNew;
      orderNew[indexComapnyExits].products[indexProductExist].quantity = Number(numberProNew)
      orderNew[indexComapnyExits].amount = totalMoney(orderNew[indexComapnyExits]);
      orderNew[indexComapnyExits].amount_paid = totalMoney(orderNew[indexComapnyExits]);
      yield put(updateProductCart(orderNew));
    }
    else {
      const productNew = {
        inv_id: 0,
        drug_id: product.drug_id,
        drg_drug_cd: product.drg_drug_cd,
        lot: "",
        drg_drug_name: product.drg_drug_name,
        quantity: quantityNew,
        base_quantity: 1,
        price: productUnitsMax.wholesale_price ? productUnitsMax.wholesale_price : 0,
        dosage: product.dosage,
        discount: 0,
        drug_unit_id: productUnitsMax.drug_unit_id,
        unit_id: 0,
        unit_name: productUnitsMax.unit_name,
        promotion_flg: 0,
        import_price: productUnitsMax.wholesale_price ? productUnitsMax.wholesale_price : 0,
        discount_type: DISCOUNT_TYPE.MONEY,
        discount_unit: 0,
        package_desc: product.package_desc ? product.package_desc : "",
        listing_price: productUnitsMax.price ? productUnitsMax.price : 0,
        image_url: product.image_url !== "" ? product.image_url.split("|")[0] : ICON_MEDICINE
      }
      const orderNew = JSON.parse(JSON.stringify(orderSuppliers))
      orderNew[indexComapnyExits].products.push(productNew)
      orderNew[indexComapnyExits].amount = totalMoney(orderNew[indexComapnyExits]);
      orderNew[indexComapnyExits].amount_paid = totalMoney(orderNew[indexComapnyExits]);
      yield put(addProductCart(orderNew))
    }
  }
  else {
    const orderProductSelected: OrderSupplierSelected = {
      drg_store_id: product.drg_store_id,
      company_id: product.company_id,
      supplier_name: product.supplier_name,
      invoice_type: 1,
      invoice_source: "",
      amount: productUnitsMax.wholesale_price ? productUnitsMax.wholesale_price : 0,
      amount_paid: productUnitsMax.wholesale_price ? productUnitsMax.wholesale_price : 0,
      amount_debt: 0,
      amount_vat: 0,
      discount_amount: 0,
      currency: CURRENCIES.VN,
      pay_method: INVOICE_PAY_METHOD.COD,
      status: 2,
      note: "",
      pay_reference: "",
      issue_datetime: new Date(),
      img_url: '',
      discount_type: DISCOUNT_TYPE.MONEY,
      vat: 0,
      products: [{
        inv_id: 0,
        drug_id: product.drug_id,
        drg_drug_cd: product.drg_drug_cd,
        lot: "",
        drg_drug_name: product.drg_drug_name,
        quantity: quantityNew,
        base_quantity: 1,
        price: productUnitsMax.wholesale_price ? productUnitsMax.wholesale_price : 0,
        dosage: product.dosage,
        discount: 0,
        drug_unit_id: productUnitsMax.drug_unit_id,
        unit_id: 0,
        unit_name: productUnitsMax.unit_name,
        promotion_flg: 0,
        import_price: productUnitsMax.wholesale_price ? productUnitsMax.wholesale_price : 0,
        discount_type: DISCOUNT_TYPE.MONEY,
        discount_unit: 0,
        package_desc: product.package_desc ? product.package_desc : "",
        listing_price: productUnitsMax.price ? productUnitsMax.price : 0,
        image_url: product.image_url !== "" ? product.image_url.split("|")[0] : ICON_MEDICINE
      }]
    }
    const orderNew = [...orderSuppliers, orderProductSelected]
    yield put(addInvoiceCart(orderNew))
  }
}


function* removeProduct(action: any) {

}


function* updateProductQuantity(action: any) {

}

export default function* () {
  yield takeLatest(ACTION_CART_ADD_PRODUCT, addProduct)
  yield takeLatest(ACTION_CART_REMOVE_PRODUCT, removeProduct)
  yield takeLatest(ACTION_CART_PRODUCT_UPDATE_QUANTITY, updateProductQuantity)
}