import { LIMIT, PAGE_DEFAULT } from "../constants";
import { SearchListSmsMessageRequest, SmsMessageRequest } from "../dataType/smsMessage";
import client from "./apiConfig";

export default {
  sendMessageCustomer(params: SmsMessageRequest) {
    const url = `/sms/sms/message`;
    return client.post(url, params).then(response => {
      return response;
    });
  },
  searchListMessageCustomer(params: SearchListSmsMessageRequest, pageAPI?: number, perPageAPI?: number) {
    let page = pageAPI ? pageAPI : PAGE_DEFAULT;
    let per_page = perPageAPI ? perPageAPI : LIMIT;
    const url = `/sms/sms/message/search?page=${page}&size=${per_page}`;
    return client.post(url, params).then(response => {
      return response;
    });
  },
  searchListTemplate() {
    const url = `/sms/template`
    return client.get(url).then(response => {
      return response;
    });
  }
};
