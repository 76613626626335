import { INIT_SETTING_PRINT } from '../types';
import { InitSettingPrint } from './../../dataType/Setting';


const initState: InitSettingPrint = {
  invoice: {
    template: '',
    print_size: '',
  },
  templateExport: '',
  templateImport: ''
}

export default function settingPrint(state = initState, action: any) {
  switch (action.type) {
    case INIT_SETTING_PRINT:
      return {
        ...state,
        invoice: {
          template: action.payload.invoice.template,
          print_size: action.payload.invoice.print_size,
        },
        templateExport: action.payload.templateExport,
        templateImport: action.payload.templateImport,
      }
    default:
      return state;
  }
}