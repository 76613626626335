import client from "./apiConfig";

export default {
  async getListCompanyType() {
    const url = `/pharmacy/company/type`;
    const response = await client.get(url);
    return response;
  },
  async getCompanyInfo(token?: string) {
    const url = `/pharmacy/company`;
    const axiosConfig = {
      setToken: token ? true : false,
      headers: {
        Authorization: "Bearer " + token
      }
    }
    return client
      .get(url, axiosConfig)
      .then(response => {
        return response;
      });
  }
}