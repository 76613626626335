import React, {useEffect} from 'react';
import {DOMAIN, META_DESCRIPTION} from './constants';

const UpdateMetaDescription: React.FC = () => {
  useEffect(() => {
    const updateMetaDescription = (content: string) => {
      let metaTag = document.querySelector<HTMLMetaElement>('meta[name="description"]');
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.name = 'description';
        document.head.appendChild(metaTag);
      }
      metaTag.content = content;
    };

    const hostname = window.location.hostname;
    let description = META_DESCRIPTION.PHARMACY;

    if (hostname.includes(DOMAIN.PHARMACY)) {
      description = META_DESCRIPTION.PHARMACY;
    }
    if (hostname.includes(DOMAIN.PHARMACO)) {
      description = META_DESCRIPTION.PHARMACO;
    }
    if (hostname.includes(DOMAIN.CLINIC)) {
      description = META_DESCRIPTION.CLINIC;
    }
    updateMetaDescription(description);
  }, []);

  return null;
};

export default UpdateMetaDescription;
