import client from "./apiConfig";
import {LIMIT, PAGE_DEFAULT} from "../constants/";
import {
  CreateGroupDrugRequest,
  CreateKindDrugRequest,
  DrgDrug,
  DrgNewDrug,
  DrgPriceSearch,
  DrgUnit,
  ProductLinkRequest,
  ProductUpdate,
  UpdateGroupDrugRequest,
  UpdateKindDrugRequest,
  UpdatePriceRequest
} from "../dataType/Product";
import {API_PATH} from "./apiPath";

export default {
  async createProduct(param: { new_drugs: DrgNewDrug[] }) {
    const {new_drugs} = param
    const url = `/pharmacy/product/create`;
    let timeout = Math.round(new_drugs.length / 100) * 10000
    const response = await client.post(url, param, {
      timeout: timeout
    });
    return response;
  },

  async getProductByBarCode(barcode: string) {
    const url = `/pharmacy/product/scan/barcode?barcode=${barcode}`;
    const response = await client.get(url);
    return response;
  },

  async getTemplate(data: {
    page: number;
    per_page?: number;
    drug_name: string;
    token: string | null;
  }) {
    let page = data.page ? data.page : PAGE_DEFAULT;
    let per_page = data.per_page ? data.per_page : LIMIT;
    let drug_name = data.drug_name ? data.drug_name : "";
    const url = `/pharmacy/product/templates/?page=${page}&size=${per_page}&drug_name=${drug_name}`;
    const response = await client.get(url, {
      headers: {
        Authorization: data.token
      }
    });
    return response;
  },
  async getUnitStandard() {
    const url = `/pharmacy/product/units/standard`;
    const response = await client.get(url);
    return response;
  },
  async getDrugStorePharmacy(
    data: {
      drg_barcode?: string;
      drug_name?: string;
      drug_group_cd?: string;
      drug_kind_cd?: string;
      company?: string;
      status?: number | string;
      provider_id?: number
    },
    pageAPI?: number,
    perPageAPI?: number
  ) {
    let page = pageAPI ? pageAPI : PAGE_DEFAULT;
    let per_page = perPageAPI ? perPageAPI : LIMIT;
    const url = `/pharmacy/product/list?page=${page}&size=${per_page}`;
    const response = await client.post(url, data);
    return response;
  },
  async getDrugByProvider(
    data: {
      drug_name?: string;
      provider_id?: number
    },
    pageAPI?: number,
    perPageAPI?: number
  ) {
    let page = pageAPI ? pageAPI : PAGE_DEFAULT;
    let per_page = perPageAPI ? perPageAPI : LIMIT;
    const {drug_name, provider_id} = data
    const url = `/pharmacy/product/list?page=${page}&size=${per_page}&drug_name=${drug_name}&provider_id=${provider_id}`;
    const response = await client.get(url);
    return response;
  },
  async getDescription() {
    const url = `/pharmacy/product/description`;
    const response = await client.get(url);
    return response;
  },
  async getAllDrugsOfStore() {
    const url = `/pharmacy/product/list-short`;
    const response = await client.get(url);
    return response;
  },
  async updateProduct(token: string, data: DrgDrug[]) {
    const url = `/pharmacy/product/update`;
    const response = await client.put(url, data, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },

  async updateInfoProduct(data: DrgDrug) {
    const url = `/pharmacy/product/update/info`;
    const response = await client.put(url, data);
    return response.data;
  },

  async updateUnits(data: DrgUnit[]) {
    const url = `/pharmacy/product/update/units`;
    const response = await client.put(url, data);
    return response.data;
  },

  async updatePrice(data: UpdatePriceRequest) {
    const drgStoreIds = data.drg_store_ids.join(',')
    const url = `/pharmacy/product/prices?drg_store_ids=${drgStoreIds}`;
    const response = await client.put(url, data.price_requests);
    return response.data;
  },


  async getScanBarCode(token: string, drug_id?: number) {
    const url = `/pharmacy/product/barcode?drug_id=${drug_id}`;
    try {
      const response = await client.get(url, {
        headers: {
          Authorization: token
        }
      });
      return response.data;
    } catch (err: any) {
      console.log(err);
    }
  },
  async getRecentlyImportProductInfo(token: string, drugId: number) {
    const url = `/pharmacy/product/detail?drug_id=${drugId}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },

  async deleteProductInfo(drugId: number) {
    const url = `/pharmacy/product/delete?drug_id=${drugId}`;
    const response = await client.get(url);
    return response.data;
  },

  async getPricesOfProduct(data: DrgPriceSearch, page?: number, size?: number) {
    let page_ = page ? page : PAGE_DEFAULT;
    let size_ = size ? size : LIMIT;
    const url = `/pharmacy/product/prices?page=${page_}&size=${size_}&drg_drug_name=${data.drg_drug_name}&drug_id=${data.drug_id}`;
    const response = await client.get(url);
    return response;
  },

  async updateProductInfoGpp(data: ProductUpdate[]) {
    const url = `/pharmacy/product/update/gpp`;
    const response = await client.put(url, data);
    return response;
  },

  async getCompanyName(
    token: string | null,
    page: number,
    size: number,
    companyName: string
  ) {
    const url = `/pharmacy/product/company-name?page=${page}&size=${size}&company_name=${companyName}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response;
  },

  async getHistoryPriceOfProduct(token: string | null, page: number, size: number, drug_id: number) {
    const url = `/pharmacy/product/prices/history?page=${page}&size=${size}&drug_id=${drug_id}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response;
  },
  async getHistoryImportPriceOfProduct(page: number, size: number, drug_id: number, unit_name: string) {
    const url = `/pharmacy/product/prices/import?page=${page}&size=${size}&drug_id=${drug_id}&unit_name=${unit_name}`;
    const response = await client.get(url);
    return response;
  },
  async getKindDrug() {
    const url = `/pharmacy/product/kind`;
    const response = await client.get(url);
    return response;
  },
  async createKindDrug(param: CreateKindDrugRequest) {
    const url = `/pharmacy/product/kind`
    const response = await client.post(url, param)
    return response
  },
  async updateKindDrug(param: UpdateKindDrugRequest) {
    const url = `/pharmacy/product/kind`
    const response = await client.put(url, param)
    return response
  },
  async deleteKindDrug(drugKindId: number) {
    const url = `/pharmacy/product/kind?drug_kind_id=${drugKindId}`
    const response = await client.delete(url)
    return response
  },
  async getGroupDrug() {
    const url = `/pharmacy/product/group`;
    const response = await client.get(url);
    return response;
  },
  async createGroupDrug(param: CreateGroupDrugRequest) {
    const url = `/pharmacy/product/group`
    const response = await client.post(url, param)
    return response
  },
  async updateGroupDrug(param: UpdateGroupDrugRequest) {
    const url = `/pharmacy/product/group`
    const response = await client.put(url, param)
    return response
  },
  async deleteGroupDrug(drugGroupId: number) {
    const url = `/pharmacy/product/group?drug_group_id=${drugGroupId}`
    const response = await client.delete(url)
    return response
  },
  async getProductLink(ref_company_id: number, ref_drug_cd: string) {
    const url = `/pharmacy/product/link?ref_company_id=${ref_company_id}&ref_drug_cd=${ref_drug_cd}`;
    const response = await client.get(url);
    return response;
  },
  async createProductLink(data: ProductLinkRequest[]) {
    const url = `/pharmacy/product/link`;
    const response = await client.post(url, data);
    return response;
  },
  async deleteListProduct(drugIds: number[]) {
    return await client.delete(API_PATH.DELETE_LIST_PRODUCT, {
      data: drugIds
    })
  }
};
