import React, { useEffect } from 'react';

function CustomerChat() {

  useEffect(() => {
    const script = document.createElement('script');
    var chatbox: any = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", "105440274765974");
    chatbox.setAttribute("attribution", "biz_inbox");
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat"></div>
    </>
  );
}



export default CustomerChat;