import { CashflowCreateParam, CashflowSearchParam, CashflowTypeEditParam, EditBalanceRequest, UpdateCustomerDebtParam, UpdateProviderDebtParam } from "../dataType/Cashflow";
import client from "./apiConfig";

export default {
  async createCashflowType(params: {
    type_code: string,
    type_group: string,
    type_name: string
  }) {
    const url = `/pharmacy/accounting/cashflow/type`
    const response = await client.post(url, params);
    return response.data;
  },

  async getListCashflowType() {
    const url = `/pharmacy/accounting/cashflow/type`
    const response = await client.get(url)
    return response
  },

  async editCashflowType(params: CashflowTypeEditParam) {
    const url = `/pharmacy/accounting/cashflow/type`
    const res = await client.put(url, params)
    return res.data
  },

  async createNewCashflow(params: CashflowCreateParam) {
    const url = `/pharmacy/accounting/cashflow`
    const res = await client.post(url, params)
    return res.data
  },

  async deleteCashflow(cashflowId: number) {
    const url = `/pharmacy/accounting/cashflow?cashflow_id=${cashflowId}`
    const res = await client.delete(url)
    return res.data
  },

  async getListCashflow(page: number, size: number, params: CashflowSearchParam) {
    const url = `/pharmacy/accounting/cashflow/search?page=${page}&size=${size}`
    const res = await client.post(url, params)
    return res.data
  },

  async getCashflowDashboard(params: CashflowSearchParam) {
    const url = `/pharmacy/accounting/cashflow/dashboard`
    const res = await client.post(url, params)
    return res.data
  },

  async getCashflowPartnerType() {
    const url = `/pharmacy/accounting/cashflow/partner/type`
    const res = await client.get(url)
    return res
  },

  async getDebtCustomer(page: number, size: number, customer_id: number) {
    const url = `/pharmacy/customer/${customer_id}/debt?page=${page}&size=${size}`
    const res = await client.get(url)
    return res.data
  },

  async updateDebtCustomer(customer_id: number, param: UpdateCustomerDebtParam) {
    const url = `/pharmacy/customer/${customer_id}/debt`
    const res = await client.post(url, param)
    return res
  },

  async getDebtProvider(page: number, size: number, provider_id: number) {
    const url = `/pharmacy/provider/${provider_id}/debt?page=${page}&size=${size}`
    const res = await client.get(url)
    return res.data
  },

  async updateDebtProvider(provider_id: number, param: UpdateProviderDebtParam) {
    const url = `/pharmacy/provider/${provider_id}/debt`
    const res = await client.post(url, param)
    return res
  },

  async editBalanceCustomer(customer_id: number, param: EditBalanceRequest) {
    const url = `/pharmacy/customer/${customer_id}/adjust`
    const res = await client.post(url, param)
    return res
  },

  async editBalanceProvider(provider_id: number, param: EditBalanceRequest) {
    const url = `/pharmacy/provider/${provider_id}/adjust`
    const res = await client.post(url, param)
    return res
  }

};