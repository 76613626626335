import client from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants";
import { updateNoti } from "../dataType/notification.d";
export default {
  async getAllNotification(token: string, _page?: number, _size?: number, notification_type?: string) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/notification?page=${page}&size=${size}&notification_type=${notification_type}`;
    const response = await client.get(url);
    return response.data;
  },

  async updateStatusNotification(data: updateNoti) {
    const url = `/pharmacy/notification`;
    const response = await client.put(url, data);
    return response;
  },
  async markerReadAll(token: string, data: {
  }) {
    const url = `/pharmacy/notification/all`;
    const response = await client.put(url, data, {
      headers: {
        Authorization: token
      }
    });
    return response;
  },

  async getActivity(_page: number, _size?: number, activity_type?: number) {
    let page = _page ? _page : PAGE_DEFAULT;
    let size = _size ? _size : LIMIT;
    const url = `/pharmacy/activity?page=${page}&size=${size}${activity_type ? `&activity_type=${activity_type}` : ``}`;
    const response = await client.get(url);
    return response.data;
  },

  async getNotification() {
    const url = `/pharmacy/notification/statistics`;
    const response = await client.get(url);
    return response.data;
  }
};
