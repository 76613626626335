import { CHANGE_QR_CODE, CHANGE_TYPE_BUSINESS_FORM, INIT_SETTING_GENERAL, LOG_OUT, UPDATE_OPTION_PRINT_INVOICE } from './../types/index';
import { LIST_TYPE_PRINT_STORE_INVOICE, TYPE_BUSINESS_FORM } from './../../constants/index';
import { PRINTER_BARCODE_TYPE, TYPE_BUSINESS, TYPE_OPTION_PRINT_BARCODE, TYPE_SHOW_PRODUCT, TYPE_SYNC } from "../../constants";
import {
  CHANGE_TYPE_PRINT, CHANGE_SHOW_USE_GUIDE, CHANGE_TYPE_SHOW_PRODUCT, CHANGE_POLICY_RETURN_INVOICE,
  CHANGE_TYPE_OPTION_PRINT_BARCODE, CHANGE_POLICY_RETURN_INVOICE_WHOLESALE,
  CHANGE_TYPE_BUSINESS, CHANGE_OPTIONS_PRINT_BARCODE, UPDATE_SETTING, CHANGE_TYPE_SYNC
} from "../types";
export type OptionPrintInvoice = {
  isShowLoyalty: boolean,
  showStore: {
    label: string,
    value: string,
  },
  storeInfo: string[],
  listStoreIdShow: number[]
}
export type InitStateSetting = {
  // typePrinted: string,
  showUseGuide: boolean,
  typeShowProduct: number,
  policyReturnProduct: string,
  policyReturnProductWholesale: string,
  typePrintBarcode: string,
  typeBusiness: string[],
  typeSync: number,
  optionsPrintBarcode: string[],
  typeBusinessForm: string[],
  optionPrintInvoice: OptionPrintInvoice,
  qr_code: string,
};
const initState: InitStateSetting = {
  // typePrinted: PRINTER_TYPE.MEDIUM.value,
  showUseGuide: true,
  typeShowProduct: TYPE_SHOW_PRODUCT.LIST,
  policyReturnProduct: "",
  policyReturnProductWholesale: "",
  typePrintBarcode: PRINTER_BARCODE_TYPE.ITEM_2.value,
  typeBusiness: [...TYPE_BUSINESS.RETAIL],
  typeSync: TYPE_SYNC.NONE.value,
  optionsPrintBarcode: [
    TYPE_OPTION_PRINT_BARCODE.PRINT_PRICE.value,
    TYPE_OPTION_PRINT_BARCODE.PRINT_LOT.value,
  ],
  typeBusinessForm: [...TYPE_BUSINESS_FORM.OFFLINE],
  optionPrintInvoice: {
    isShowLoyalty: false,
    showStore: LIST_TYPE_PRINT_STORE_INVOICE[0],
    storeInfo: ['BRANCH_NAME', 'ADDRESS', 'PHONE_NO'],
    listStoreIdShow: []
  },
  qr_code: ''
};
export default function setting(state = initState, action: any) {
  switch (action.type) {
    case INIT_SETTING_GENERAL: {
      return {
        ...state,
        optionsPrintBarcode: action.payload.barcode_include,
        typePrinted: action.payload.printing_size,
        typeBusiness: action.payload.business_type,
        typeShowProduct: action.payload.display_type,
        typePrintBarcode: action.payload.barcode_paper,
        typeBusinessForm: action.payload.selling_method,
        typeSync: action.payload.synchronization
      }
    }
    case CHANGE_TYPE_PRINT: {
      return {
        ...state,
        typePrinted: action.payload
      }
    }
    case CHANGE_SHOW_USE_GUIDE: {
      return {
        ...state,
        showUseGuide: action.payload
      }
    }
    case CHANGE_TYPE_SHOW_PRODUCT: {
      return {
        ...state,
        typeShowProduct: action.payload
      }
    }
    case CHANGE_POLICY_RETURN_INVOICE: {
      return {
        ...state,
        policyReturnProduct: action.payload
      }
    }
    case CHANGE_POLICY_RETURN_INVOICE_WHOLESALE: {
      return {
        ...state,
        policyReturnProductWholesale: action.payload
      }
    }
    case CHANGE_TYPE_BUSINESS: {
      return {
        ...state,
        typeBusiness: action.payload
      }
    }
    case CHANGE_TYPE_SYNC: {
      return {
        ...state,
        typeSync: action.payload
      }
    }
    case CHANGE_TYPE_OPTION_PRINT_BARCODE: {
      return {
        ...state,
        typePrintBarcode: action.payload
      }
    }
    case CHANGE_OPTIONS_PRINT_BARCODE: {
      return {
        ...state,
        optionsPrintBarcode: action.payload
      }
    }
    case UPDATE_SETTING: {
      return {
        ...state,
        ...action.payload
      }
    }
    case CHANGE_TYPE_BUSINESS_FORM: {
      return {
        ...state,
        typeBusinessForm: action.payload
      }
    }
    case CHANGE_QR_CODE: {
      return {
        ...state,
        qr_code: action.payload
      }
    }
    case UPDATE_OPTION_PRINT_INVOICE: {
      return {
        ...state,
        optionPrintInvoice: action.payload
      }
    }
    case LOG_OUT:
      return {
        ...state,
      }
    default:
      return state;
  }
}