import { UPDATE_AMOUNT_PAID_INVOICE_DRAFT_COMFIRM } from './../types/index';
import { InvoiceInfo } from './../../dataType/Invoice.d';
import {
  LOG_OUT,
  UPDATE_INVOICE_PRESCRIPTION,
  TRANSFER_BRANCH,
  CHANGE_TAB_INVOICE_DRAFT_CONFIRM,
  DELETE_INVOICE_DRAFT_CONFIRM,
  UPDATE_INVOICE_DRAFT_CONFIRM,
  UPDATE_INVOICE_DRAFT_CONFIRM_INFO,
  ADD_TAB_INVOICE_DRAFT_CONFIRM
} from "../types";
import { InvoiceDraftProductConfirm, InvoiceDraftConfirmRequest, TabInvoiceDraftConfirm } from "../../dataType/Invoice";
import { DISCOUNT_TYPE } from "../../constants";

export type InitStateInvoiceDraftConfirm = {
  arrayTabInvoice: TabInvoiceDraftConfirm[];
  activeKey: string;
};

const initialState: InitStateInvoiceDraftConfirm = {
  arrayTabInvoice: [],
  activeKey: "1"
};

export default function invoiceDraftConfirm(state = initialState, action: any) {
  const { arrayTabInvoice, activeKey } = state;

  switch (action.type) {
    case CHANGE_TAB_INVOICE_DRAFT_CONFIRM:
      return { ...state, activeKey: action.activeKey };

    case ADD_TAB_INVOICE_DRAFT_CONFIRM: {
      const maxKeyCur: string = arrayTabInvoice.length
        ? arrayTabInvoice[arrayTabInvoice.length - 1].key
        : "0";
      const key = (parseInt(maxKeyCur) + 1).toString();

      const invoice: InvoiceInfo = action.payload.invoice
      const newproducts: InvoiceDraftProductConfirm[] = action.payload.drg_invoice_details.map((data: InvoiceDraftProductConfirm) => {
        data.discount_type = DISCOUNT_TYPE.MONEY
        return data
      })
      const invoiceDraftRequest: InvoiceDraftConfirmRequest = {
        invoice: invoice,
        products: newproducts
      }
      const newInvoiceTab = {
        key,
        title: `Đơn nháp ${key}`,
        data: { ...invoiceDraftRequest }
      };
      return {
        arrayTabInvoice: [...state.arrayTabInvoice, newInvoiceTab],
        activeKey: key
      };
    }

    case DELETE_INVOICE_DRAFT_CONFIRM: {
      //lastIndex: tab ngay truoc tab muon xoa
      //activeKeyCur: key cua tab hien tai
      let lastIndex = 0,
        activeKeyCur = activeKey;
      arrayTabInvoice.forEach((invoiceTab, i) => {
        if (invoiceTab.key === action.keyTab) {
          lastIndex = i - 1;
        }
      });
      const invoices = arrayTabInvoice.filter(
        invoice => invoice.key !== action.keyTab
      );

      if (arrayTabInvoice.length && state.activeKey === action.keyTab) {
        if (lastIndex >= 0) {
          activeKeyCur = arrayTabInvoice[lastIndex].key;
        } else if (lastIndex === -1 && arrayTabInvoice.length > 1) {
          activeKeyCur = arrayTabInvoice[lastIndex + 2].key;
        } else activeKeyCur = arrayTabInvoice[0].key;
      }
      return {
        arrayTabInvoice: invoices,
        activeKey: activeKeyCur
      };
    }
    case UPDATE_INVOICE_DRAFT_CONFIRM_INFO: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceDraftConfirm) => element.key === action.keyTab);
      const invoice = action.invoiceInfo
      const products: InvoiceDraftProductConfirm[] = action.products
      const amount_product = getTotalAmount(products);
      invoice.amount = amount_product + invoice.amount_vat - invoice.discount_amount - invoice.ecoin_minus * 1000
      invoice.amount = invoice.amount < 0 ? 0 : invoice.amount
      invoice.amount_paid = invoice.amount
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0
      arrayTabInvoice[tabCur].data.invoice = invoice
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };

    }
    case UPDATE_AMOUNT_PAID_INVOICE_DRAFT_COMFIRM: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceDraftConfirm) => element.key === action.keyTab);
      const invoice = action.invoiceInfo
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0
      arrayTabInvoice[tabCur].data.invoice = invoice
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_INVOICE_DRAFT_CONFIRM: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceDraftConfirm) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data = action.invoiceRequest
      let invoiceRequest: InvoiceDraftConfirmRequest = action.invoiceRequest;
      let { invoice, products } = invoiceRequest;
      const amount_product = getTotalAmount(products);
      invoice.amount_vat = Math.round(amount_product * invoice.vat / 100);
      if (invoice.discount_type === DISCOUNT_TYPE.MONEY) {
        invoice.discount_amount = invoice.discount_amount_inv;
      } else {
        invoice.discount_amount = Math.round(invoice.discount_amount_inv * amount_product / 10000) * 100;
      }
      invoice.amount = amount_product + invoice.amount_vat - invoice.discount_amount - invoice.ecoin_minus * 1000;
      invoice.amount_paid = invoice.amount
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0;
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_INVOICE_PRESCRIPTION: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceDraftConfirm) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data.prescription = action.prescription
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case LOG_OUT: {
      return {
        ...initialState
      };
    }
    case TRANSFER_BRANCH: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}

function getTotalAmount(products: InvoiceDraftProductConfirm[]) {
  let total = 0;
  products.map((item: InvoiceDraftProductConfirm) => total += item.promotion_flg === 1 ? 0 : (item.price * item.quantity - item.discount));
  return total;
}



