import { put, select } from "redux-saga/effects";

import { ADD_INVOICE, DELETE_INVOICE, UPDATE_INVOICE } from "../types";
import { TabInvoice, InvoiceRequest } from "../../dataType/Invoice";
import { } from "../actions/invoice";
import { CURRENCIES } from "../../constants";

export const getArrayTabInvoice = (state: any) => state.invoice.arrayTabInvoice;

export function* addInvoice(action: any) {
  const invoiceTemp = {
    invoice: {
      invoice_type: 0,
      amount: 0,
      amount_paid: 0,
      amount_debt: 0,
      amount_vat: 0,
      discount_amount: 0,
      currency: CURRENCIES.VN,
      pay_method: "Tiền mặt",
      status: 1,
      note: "",
      customer_code: "",
      customer_name: "",
      pay_reference: "",
      customer_phone_no: "",
      ecoin_minus: 0,
      ecoin_plus: 0,
      issue_datetime: "",
      //xóa khi gọi API
      total_amount: 0,
      discount_amount_inv: 0
    },
    products: []
  };
  const arrayTabInvoice: TabInvoice[] = yield select(state => state);

  //Get max key's tabs in current
  const maxKeyCur: string = arrayTabInvoice.length
    ? arrayTabInvoice[arrayTabInvoice.length - 1].key
    : "0";
  const key = (parseInt(maxKeyCur) + 1).toString();
  const newInvoiceTab = {
    key,
    title: `Hóa đơn ${key}`,
    data: invoiceTemp
  };
  yield put({
    type: ADD_INVOICE,
    arrayTabInvoice: [...arrayTabInvoice, newInvoiceTab],
    activeKey: key
  });
}

export function* deleteInvoice(action: any) {
  const arrayTabInvoice: TabInvoice[] = yield select(
    (state: any) => state.invoice.arrayTabInvoice
  );
  const activeKey: string = yield select(
    (state: any) => state.invoice.activeKey
  );
  let lastIndex = 0,
    activeKeyCur = activeKey;
  arrayTabInvoice.forEach((invoiceTab, i) => {
    if (invoiceTab.key === action.keyTab) {
      lastIndex = i - 1;
    }
  });
  const newArrayTabInvoice = arrayTabInvoice.filter(
    invoice => invoice.key !== action.keyTab
  );

  if (arrayTabInvoice.length && activeKey === action.keyTab) {
    if (lastIndex >= 0) {
      activeKeyCur = arrayTabInvoice[lastIndex].key;
    } else {
      activeKeyCur = arrayTabInvoice[0].key;
    }
  }
  yield put({
    type: DELETE_INVOICE,
    arrayTabInvoice: newArrayTabInvoice,
    activeKey: activeKeyCur
  });
}

export function* updateInvoice(action: any) {
  const arrayTabInvoice: TabInvoice[] = yield select(
    (state: any) => state.invoice.arrayTabInvoice
  );
  const indexTabCur = arrayTabInvoice.findIndex(
    (invoice: TabInvoice) => invoice.key === action.keyTab
  );
  arrayTabInvoice[indexTabCur].data = action.invoice;
  arrayTabInvoice[indexTabCur].data.invoice.amount = getTotalAmount(
    action.invoice
  );
  arrayTabInvoice[indexTabCur].data.invoice.discount_amount = getDiscountAmount(
    action.invoice
  );
  arrayTabInvoice[indexTabCur].data.invoice.amount_debt = getAmountDebt(
    action.invoice
  );
  yield put({
    type: UPDATE_INVOICE,
    arrayTabInvoice: [...arrayTabInvoice]
  });
}

function getTotalAmount(invoice: InvoiceRequest) {
  let total = 0;
  invoice.products.map((item: any) =>
    item.promotion_flg ? total : (total += item.price * item.quantity)
  );
  return total;
}

function getDiscountAmount(invoice: InvoiceRequest) {
  let total = 0;
  invoice.products.map((item: any) =>
    item.promotion_flg
      ? (total += item.price * item.quantity)
      : (total += item.discount * item.quantity)
  );
  return total;
}

function getAmountDebt(invoice: InvoiceRequest) {
  return invoice.invoice.amount > invoice.invoice.amount_paid
    ? invoice.invoice.amount - invoice.invoice.amount_paid
    : 0;
}

export default function* () {
  // yield takeLatest(ADD_INVOICE, addInvoice);
  // yield takeLatest(DELETE_INVOICE, deleteInvoice);
  // yield takeLatest(UPDATE_INVOICE, updateInvoice);
}
