import client from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants/";
import { ComboRequest } from "../dataType/Combo";

export type ProductReturn = {
  invoice_detail_id: number;
  drug_id: number;
  quantity: number;
  unit_name: string;
}

export default {
  async getCombos(
    _page: number,
    _size: number,
    _param: {
      combo_type: string,
      combo_name?: string,
      combo_id?: string,
      status?: number
    }
  ) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const param = _param ? _param : {
      combo_type: '',
      combo_name: '',
      combo_id: ''
    };
    const paramId = param.combo_id ? `&combo_id=${param.combo_id}` : ''
    const paramStatus = param.status ? `&status=${param.status}` : ''
    const url = `/pharmacy/product/combo?page=${page}&size=${size}&combo_type=${param.combo_type}&combo_name=${param.combo_name}${paramId}${paramStatus}`;
    const response = await client.get(url);
    return response.data;
  },

  async editCombos(
    _param: {
      combo_id: number,
      status: number,
      active_flg: number,
      price: number,
      discount: number
    }
  ) {
    const url = `/pharmacy/product/combo`;
    const response = await client.put(url, _param);
    return response.data;
  },

  async createCombo(
    _param: ComboRequest
  ) {
    const url = `/pharmacy/product/combo`;
    const response = await client.post(url, _param);
    return response.data;
  },
};


