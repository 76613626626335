import client from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants/";
import {
  SearchParam,
  SearchParamDrugInv,
  RequestParamUpdateInvoice,
  InvoiceRequest,
  CreateInvoiceV2Request, 
  IReturnProductInvoice
} from "../dataType/Invoice";

export type ProductReturn = {
  invoice_detail_id: number;
  drug_id: number;
  quantity: number;
  unit_name: string;
  combo_id: number;
  product_ecoin_plus: number;
  product_discount_amount: number;
}

export default {
  async getInvoices(
    _page: number,
    _size: number,
    _param?: SearchParam
  ) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const param: SearchParam = _param
      ? _param
      : {
        invoice_code: "",
        customer_name: "",
        created_user: "",
        pay_method: "",
        invoice_type: null,
        status: null,
        sort_field: "",
        sort_type: "",
        drg_drug_name: "",
        from_time: "",
        to_time: ""
      };
    const url = `/pharmacy/invoice/search?page=${page}&size=${size}`;
    const response = await client.post(url, param);
    return response.data;
  },

  async getDrugListInv(
    token: string,
    _page?: number,
    _size?: number,
    _param?: SearchParamDrugInv
  ) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const param: SearchParamDrugInv = _param
      ? _param
      : {
        drug_barcode: "",
        drug_name: "",
        company: "",
        status: null,
        quantity: 1,
        exp_date: false,
      };
    const url = `/pharmacy/inventory/product?page=${page}&size=${size}`;
    const response = await client.post(url, param, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },



  async getCustomer(
    token: string,
    _page: number,
    _size: number,
    phone?: string,
    customer_name?: string,
    customer_type?: string,
    customer_group_cd?: number
  ) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/customer?page=${page}&size=${size}${phone ? `&phone=${phone}` : ``
      }${customer_name ? `&customer_name=${customer_name}` : ``}${customer_type ? `&customer_type=${customer_type}` : ``}${customer_group_cd ? `&customer_group_cd=${customer_group_cd}` : ``}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },

  async createInvoice(params: any) {
    const url = `/pharmacy/invoice`;
    const response = await client.post(url, params);
    return response.data;
  },

  async createInvoiceDraft(params: InvoiceRequest) {
    const url = `/pharmacy/invoice/draft`;
    const response = await client.post(url, params);
    return response.data;
  },
  async updateInvoiceDraft(params: InvoiceRequest) {
    const url = `/pharmacy/invoice/draft`;
    const response = await client.put(url, params);
    return response.data;
  },

  async confirmInvoiceDraft(params: InvoiceRequest) {
    const url = `/pharmacy/invoice/draft/approve`;
    const response = await client.post(url, params);
    return response.data;
  },

  async cancelInvoice(token: string, invoice_id: number) {
    const url = `/pharmacy/invoice/delete?invoice_id=${invoice_id}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },

  async returnInvoice(data: IReturnProductInvoice) {
    const url = `/pharmacy/invoice/return-product`;
    const response = await client.post(url, data);
    return response.data;
  },

  async updateInvoice(token: string, data: RequestParamUpdateInvoice) {
    const url = `/pharmacy/invoice`;
    const response = await client.put(url, data, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },

  async getPrescription(token: string, invoice_id: number) {
    const url = `/pharmacy/invoice/prescription?invoice_id=${invoice_id}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async getInvoiceSource() {
    const url = `/pharmacy/invoice/source`;
    const response = await client.get(url)
    return response;
  },

  async downloadExcelTemplate(token: string, param: any) {
    const url = `/pharmacy/invoice/export/file/viettel`;
    await client.post(url, param, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'lichsuhoadon.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  },
};


