export const LOGO = require("./images/medlink-logo@3x.png");
export const LOGO_MEDLINK = require("./images/logo-app.png");
export const ICON_MAP = require("./images/ggmap.png");
export const ICON_LIST = require("./images/showList.png");
export const ICON_ORDER_ONLINE = require("./images/show-order-online.png");
export const ICON_LOCATION = require("./images/pharmacy.png")
export const ICON_BELL = require("./images/bell.png");
export const ICON_CALENDAR_ALARM = require("./images/icon-calendar-alarm.png")
export const ICON_EXPIRED_DATE = require("./images/expired-date.png");
export const ICON_EMPTY_CART = require("./images/empty-cart.png");
export const ICON_GOOGLE_MAP = require("./images/google-maps.png");
export const ICON_SUPPORT = require("./images/support.png");
export const ICON_INVENTORY = require("./images/inventory.png");
export const ICON_PARTNER = require("./images/partner.png");
export const ICON_HELP = require("./images/help.png");
export const ICON_CUSTOMER_SIDEBAR = require("./images/customer_sidebar.png");
export const ICON_CUSTOMER_GROUP = require("./images/customer_group.png");
export const ICON_REPORT = require("./images/report.png");
export const ICON_PRODUCT = require("./images/product.png");
export const ICON_DASHBOARD = require("./images/dashboard.png");
export const ICON_PAYMENT = require("./images/bill.png");
export const ICON_UPDATE = require("./images/update.png");
export const ICON_ACCOUNTING = require("./images/accounting.png");
export const ICON_CASHBOOK = require("./images/cashbook.png");
export const ICON_CATEGORY = require("./images/category.png");
export const ICON_SETTING_PRICE = require("./images/settingPrice.png");
export const ICON_BARCODE = require("./images/barcode.png");
export const ICON_WARNING_EXPIRED = require("./images/WarningExpired.png");
export const ICON_SUPPLIER = require("./images/supplier.png");
export const ICON_CUSTOMER = require("./images/customer.png");
export const ICON_ORDER = require("./images/order.png");
export const ICON_SETTING = require("./images/setting.png");
export const ICON_ADD_USER = require("./images/addUser.png");
export const ICON_HOME = require("./images/home.png");
export const ICON_ONLINE_ORDER = require("./images/order_online.png");
export const ICON_ADD_CART = require("./images/add_cart.png");
export const ICON_CART_GRAY = require("./images/add_cart_gray.png");
export const ICON_SUPERMARKET = require("./images/supermarket.png");
export const ICON_IOS = require("./images/icon_ios.png");
export const ICON_ANDROID = require("./images/icon_play.png");
export const ICON_CONNECT_GPP = require("./images/connect.png");
export const ICON_MONEY = require("./images/money.png");
export const ICON_IMPORT = require("./images/import.png");
export const ICON_EXPORT = require("./images/export.png");
export const ICON_TRANSFER_WAREHOUSE = require("./images/transfer_warehouse.png");
export const ICON_CHECKING = require("./images/checking.png");
export const ICON_CHECK = require("./images/icon_check.png");
export const ICON_NO_SERVICE = require("./images/ic_no_service.png");
export const ICON_AROW = require("./images/arow-icon.png");
export const SIDEBAR_BG = require("./images/sidebar-bg.jpg");
export const CREATE_INVOICE = require("./images/create_invoice.png")
export const HELP_INVENTORY = require("./images/help_inventory.png")
export const CREATE_CATEGORY = require("./images/create_category.png")
export const ICON_MENU = require("./images/icon_menu.png")
export const ICON_CLOSE = require("./images/close.png")
export const IMAGE_SOFTWARE = require("./images/software-image.png")
export const IMAGE_SOFTWARE_PHARMACIST = require("./images/software-image-pharmacist.png")
export const IMAGE_SOFTWARE_COURSE = require("./images/intro-course.png")
export const IC_ARROW_DISABLE = require("./images/ic_arrow_disable.png")
export const IC_ARROW_ENABLE = require("./images/ic_arrow_enable.png")
export const IC_ARROW_LEFT = require("./images/ic_arrow_left.png")
export const IC_ARROW_FEATURE1 = require("./images/ic_feature_1.png")
export const IC_ARROW_FEATURE2 = require("./images/ic_feature_2.png")
export const IC_ARROW_FEATURE3 = require("./images/ic_feature_3.png")
export const IC_ARROW_FEATURE4 = require("./images/ic_feature_4.png")
export const IC_ARROW_FEATURE5 = require("./images/ic_feature_5.png")
export const BG_ARROW_FEATURE1 = require("./images/bg_feature_1.jpg")
export const BG_ARROW_FEATURE2 = require("./images/bg_feature_2.jpg")
export const BG_ARROW_FEATURE3 = require("./images/bg_feature_3.jpg")
export const BG_ARROW_FEATURE4 = require("./images/bg_feature_4.jpg")
export const BG_ARROW_FEATURE5 = require("./images/bg_feature_5.jpg")
export const IC_HOT = require("./images/ic_hot.png")
export const IC_STAR = require("./images/ic_star.png")
export const IC_CHECKED = require("./images/circle_checked.png")
export const IC_LOCATION = require("./images/ic_location.png")
export const IC_MOBILE = require("./images/ic_mobile.png")
export const IC_EMAIL = require("./images/ic_mail.png")
export const IC_CERTIFICATE = require("./images/img_registered.png")
export const IC_LOGO_DEFAULT = require("./images/company_logo_default.png")
export const IC_PLAY = require("./images/ic-play.png")
export const IC_IMAGE = require("./images/ic_image.png")

export const COURSE_OPERATION = require("./images/course_operation.png")
export const COURSE_DETAIL = require("./images/course_mobile.png")
export const INTRO_ORDER_ONLINE = require("./images/intro_order_online.png")
export const INTRO_ORDER_SUPPLIER = require("./images/intro_order_supplier.png")
export const MENTOR = require("./images/mentor.jpg")
export const NT_STD = require("./images/NT_ThaiDuong.jpg")
export const NT_HONGHAU = require("./images/NT_HongHau.jpg")
export const NT_HAIDANG = require("./images/NT_HaiDang.jpg")
export const NT_NHATNGUYEN = require("./images/NT_NhatNguyen.jpg")

export const ADD_NEW_PRODUCT = require("./images/add_new_product.png")
export const ADD_NEW_PRODUCT_EXCEL = require("./images/add_new_product_excel.png")
export const IMPORT_PRODUCT = require("./images/import_product.png")
export const IMPORT_PRODUCT_DETAIL = require("./images/import_product_detail.png")
export const CREATE_NEW_INVOICE = require("./images/create_new_invoice.png")
export const INVOICE = require("./images/invoice.png")

export const DIGITAL_MARKETING = require("./images/digital-marketing.png")
export const DIGITAL_MARKETING_PROMOTION = require("./images/digital-marketing-promotion.png")
export const DIGITAL_MARKETING_WALLET = require("./images/digital-marketing-wallet.png")
export const IMAGE_DEFAULT = require("./images/picture.png")

export const DIGITAL_MARKETING_CONFIG = require("./images/digital-marketing-config.png")
export const DIGITAL_MARKETING_SMS = require("./images/digital-marketing-sms.png")
export const DIGITAL_MARKETING_FPT_SERVICE = require("./images/digital-marketing-fpt-service.png")
export const DIGITAL_MARKETING_INTRODUCE_1 = require("./images/digital-marketing-introduce-1.png")
export const DIGITAL_MARKETING_INTRODUCE_2 = require("./images/digital-marketing-introduce-2.png")
export const DIGITAL_MARKETING_INTRODUCE_3 = require("./images/digital-marketing-introduce-3.png")
export const DIGITAL_MARKETING_INTRODUCE_BG = require("./images/digital-marketing-introduce-bg.png")
export const DIGITAL_MARKETING_LOGO_MEDLINK = require("./images/digital-marketing-logo-medlink.png")
export const LOGO_FPT = require("./images/logo-fpt.png")

export const INVOICE_RETAIL = require("./images/invoice_retail.png")
export const INVOICE_WHOLESALE = require("./images/invoice_wholesale.png")
export const ICON_COMBO = require("./images/combo.png")

export const ICON_REPORT_GPP = require("./images/report-gpp.png")
export const ICON_REPORT_IMPORT = require("./images/report-import.png")
export const ICON_REPORT_EXP = require("./images/report-exp.png")
export const ICON_REPORT_REVENUE = require("./images/report-revenue.png")
export const ICON_REPORT_IMPORT_EXPORT = require("./images/report-import-export.png")

export const ICON_STORE = require("../assets/images/store.png")
export const ICON_CHAIN_STORE = require("../assets/images/stores.png")
export const ICON_PHARMACIST = require("../assets/images/pharmacist.png")
export const ICON_CLINIC = require("../assets/images/clinic.png")
export const ICON_PROVIDER = require("../assets/images/provider.png")
export const ICON_COMPANY = require("../assets/images/company.png")
export const ICON_PASSWORD_VISIBLE = require("../assets/images/password-visible.png")
export const ICON_PASSWORD_INVISIBLE = require("../assets/images/password-invisible.png")
export const IMAGE_BANNER_1 = require("./images/banner-affiliate.png")
export const IMAGE_BANNER_2 = require("./images/banner-pharmacy.png")
export const IMAGE_BANNER_3 = require("./images/banner-affiliate.png")
export const IMAGE_BANNER_4 = require("./images/banner_loyalty.png")
export const IC_SUPPLIER = require("./images/icon_provider.png")

export const LOGO_DANTRI = require("./images/logo-dantri.png")
export const LOGO_TECHINASIA = require("./images/logo-techinasia.png")
export const LOGO_VNEXPRESS = require("./images/logo-vnexpress.png")
export const LOGO_VOVWORD = require("./images/logo-vovword.jpg")
export const LOGO_BAODAUTU = require("./images/logo-baodautu.png")
export const LOGO_STARTUPVNEXPRESS = require("./images/logo-startup.png")
export const LOGO_ZALO_OA = require("./images/zalo_oa.png")
export const LOGO_ZALO = require("./images/logo-zalo.jpeg")
export const ICON_ZALO = require("./images/icon-zalo.png")
export const ICON_NEW = require("./images/icon-new.png")
export const MARKETING_SMS_CAMPAIGN_IPHONE = require("./images/marketing-sms-campagin-iphone.png")
export const CONDITION = require("./images/condition.png")
export const GROUP_CUSTOMER_FIXED = require("./images/group-customer-fixed.png")
export const GROUP_CUSTOMER_AUTO = require("./images/group-customer-auto.png")
export const MARKETING_SMS_ICON_SUSCESS = require('./images/marketing-sms-status-suscess.png')
export const MARKETING_SMS_ICON_FAIL = require('./images/marketing-sms-status-fail.png')
export const ICON_CROWN = require('./images/icon_crown.png')
export const ICON_DRAG = require('./images/icon_drag.png')
export const ENDING_STOCK = require("./images/ending-stock.png")
export const ICON_ACCOUNT = require("./images/account.png")
export const ICON_TRUCK = require("./images/delivery-truck.png")
export const ICON_MEDICINE = require("./images/medicine.png")
export const DATA_ANALYTIC = require("./images/analytics.png")
export const ICON_POS = require("./images/point-of-sale.png")
export const ICON_DATA_PRODUCT = require("./images/product-data.png")

export const ICON_PHARMACY_TECHNOLOGY = require("./images/icon_pharmacy_technology.png")
export const SETTING_PRINTER = require("./images/setting_printer.png")
export const ICON_REWARD = require('./images/icon_reward.png')
export const ICON_GIFT = require('./images/icon_gift.png')
export const ICON_TIME = require('./images/icon_time.png')
export const ICON_LIMIT = require('./images/icon_limit.png')
export const ICON_USER = require('./images/userMainColor.png')
export const CHECK_IN = require("./images/check-in.png")
export const CHECK_OUT = require("./images/check-out.png")
export const ICON_EMPLOYEE = require("./images/icon-employee.png")
export const ICON_EMPLOYEE_INFO = require("./images/icon-employee-info.png")
export const ICON_SCHEDULE = require("./images/icon-schedule.png")
export const ICON_SHIFT = require("./images/icon-shift.png")
export const ICON_TIME_SHEET = require("./images/icon-time-sheet.png")
export const ICON_REPORT_TIME_SHIFT = require("./images/icon-report-time-sheet.png")
export const ICON_REGISTER = require("./images/registered.png")
export const ICON_NEW_FUNCTION = require("./images/icon_new.png")

//loyalty
export const ICON_UNLIMIT = require("./images/unlimit.png")
export const ICON_GIFT_PROMOTION = require("./images/gift.png")
export const ICON_DISCOUNT_PROMOTION = require("./images/deal.png")
export const ICON_PROMOTION = require("./images/promotion.png")
export const ICON_LOYALTY_MEMBER = require("./images/loyalty-member.png")
export const ICON_LOYALTY_PROGRAM = require("./images/loyalty-program.png")
export const ICON_LOYALTY_RANK = require("./images/loyalty-rank.png")
export const ICON_LOYALTY_REWARD = require("./images/loyalty-reward.png")
export const ICON_POLICY_SALE = require("./images/policy-sale.png")
export const ICON_NOT_PROMOTION = require("./images/icon_not_promotion.png")
export const BANNER_PROMOTION = require("./images/banner-promotion.png")
export const PHARMACY_SUPPLIER = require("./images/pharmacy_supplier.png")
export const ORDER_NEW = require("./images/dat-hang-ngay.gif")
export const QR_ANDROID = require("./images/QR-android.png")
export const QR_IOS = require("./images/QR-ios.png")
export const ORDER_NOW = require("./images/image-order.png")
export const ICON_BANNER = require("./images/icon_banner.png")