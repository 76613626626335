/* eslint-disable no-useless-concat */
// import themes  from './themes.tsx';
import {store} from "../index";
import moment from "moment";
import config from "../config";

export const EXPERT_TIME = 86400000;
export const LIMIT = 20;
export const PAGE_DEFAULT = 1;
export const MIN_SIZE = 1;
export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_DETAIL_FORMAT = "DD/MM/YYYY HH:mm:ss";
export const DATE_DETAIL_SCHEDULE_FORMAT = "DD/MM/YYYY HH:mm";
export const NUMBER_NULL = 0;
export const NUMBER_SIZE = [10, 20, 50, 100];
export const HEIGHT = window.innerHeight;
export const WIDTH = window.innerWidth;
export const GRANT_TYPE = {
  PASSWORD: 'password',
  REFRESH_TOKEN: 'refresh_token'
}
//DEFAULT_VALUE
const MONTH_CURRENT = moment().format("MM");
const YEAR_CURRENT = moment().format("YYYY");
const DAY_CURRENT = moment().format("DD");
export const DATE_CURRENT =
  YEAR_CURRENT + "-" + MONTH_CURRENT + "-" + DAY_CURRENT + "T23:59:59";
export const DATE_FIRST_CURRENT_MONTH =
  YEAR_CURRENT + "-" + MONTH_CURRENT + "-" + "01" + "T00:00:00";
export const DATE_CURRENT_LAST =
  YEAR_CURRENT + "-" + MONTH_CURRENT + "-" + DAY_CURRENT + "T23:59:59";
export const DEFAULT_VALUE = {
  NOT_ACTIVE: 0,
  ACTIVE: 1
};
export const ACTIVE_FLG = {
  NOT_ACTIVE: 0,
  ACTIVE: 1
};
export const STATUS_CUSTOMER = {
  NOT_ACTIVE: 0,
  ACTIVE: 1
};
export const LENGTH_CONTENT_LANGUAGE = {
  VIETNAMES: 70,
  ENGLISH: 160
};

//CURRENCIES
export const CURRENCIES = {
  VN: "VNĐ",
  US: "USD"
};
export const typePrintBarCode = [
  {
    key: 1,
    nameTypePrint: "Mẫu giấy cuộn 2 nhãn",
    info: "Khổ giấy in nhãn (35x2)x22mm"
  },
  {
    key: 2,
    nameTypePrint: "Mẫu giấy cuộn 3 nhãn",
    info: "Khổ giấy in nhãn (35x3)x22mm"
  },
  {
    key: 3,
    nameTypePrint: "Xuất file pdf",
    info: "Khổ giấy máy in"
  }

];
//DISCOUNT_TYPE
export const DISCOUNT_TYPE = {
  MONEY: "VNĐ",
  PERCENT: "%"
};

export const PREFIX_TYPE = {
  E: "E",
  S: "S"
};
//VAT_PERCENT
export const VAT = [0, 5, 8, 10];


export const GET_STATUS_DRUG = (status: number) => {
  switch (status) {
    case DEFAULT_VALUE.NOT_ACTIVE: {
      return {
        value: "Ngừng kinh doanh",
        color: "red"
      };
    }
    case DEFAULT_VALUE.ACTIVE: {
      return {
        value: "Đang kinh doanh",
        color: "green"
      };
    }
    default:
      return {
        value: "",
        color: ""
      };
  }
};

// INVOICE STATUS
export const INVOICE_STATUS = {
  CANCEL: 0,
  WAITING_APPROVE: 1,
  COMPLETE: 2,
  PARTIAL_PAYMENT: 3,
  NOT_PAYMENT: 4 // COD
};
export const INVOICE_CREATE_BUTTON_TYPE = {
  WAIT: 'WAIT',
  CREATE: 'CREATE',
  PRINT: 'PRINT',
};
export const INVOICE_SOURCE_TYPE = [
  {code: 'IN_STORE', value: 'Tại quầy'},
  {code: 'WEBSITE', value: 'Website'},
  {code: 'FACEBOOK', value: 'Facebook'},
  {code: 'SHOPEE', value: 'Shoppe'},
  {code: 'TIKI', value: 'Tiki'},
  {code: 'LAZADA', value: 'Lazada'},
];
export const INVOICE_TYPE = [
  {key: 0, value: 'Bán lẻ'},
  {key: 1, value: 'Bán buôn'},
];

export const LIST_INVOICE_TYPE = [
  {code: 'ADS', value: 'Hàng quảng cáo'},
  {code: 'POINT', value: 'Hàng điểm'}
]

export const IMPORT_STATUS = {
  CANCEL: 0,
  WAITING_APPROVE: 1,
  COMPLETE: 2,
  PARTIAL_PAYMENT: 3,
  NOT_PAYMENT: 4
}

export const EXPORT_STATUS = {
  CANCEL: 0,
  WAITING_APPROVE: 1,
  COMPLETE: 2,
}

// INVOICE STATUS
export const GET_STATUS_VALUE = (status: number) => {
  switch (status) {
    case INVOICE_STATUS.CANCEL: {
      return {
        value: "Đã huỷ",
        color: "red"
      };
    }
    case INVOICE_STATUS.WAITING_APPROVE: {
      return {
        value: "Chờ duyệt",
        color: "orange"
      };
    }
    case INVOICE_STATUS.COMPLETE: {
      return {
        value: "Đã hoàn thành",
        color: "green"
      };
    }
    case INVOICE_STATUS.PARTIAL_PAYMENT: {
      return {
        value: "Thanh toán một phần",
        color: "orange"
      };
    }
    case INVOICE_STATUS.NOT_PAYMENT: {
      return {
        value: "Chưa thanh toán",
        color: "orange"
      };
    }
    default:
      return {
        value: "",
        color: ""
      };
  }
};

export const STATUS_INVOICE = [
  {key: "", value: "Tất cả"},
  {key: "0", value: "Đã hủy"},
  {key: "1", value: "Chờ duyệt"},
  {key: "2", value: "Đã hoàn thành"},
  {key: "3", value: "Thanh toán một phần"},
  {key: "4", value: "Chưa thanh toán"}
];

export const MESSAGE_TYPE = [
  {key: "SMS_MESSAGE", value: "Tin nhắn chủ động"},
  {key: "SMS_AUTO", value: "Tin nhắn tự động"},
];
export const STATUS_SMS_CUSTOMER = [
  {color: "red", value: "Không gửi được", key: 0},
  {color: "green", value: "Đã nhận", key: 1},
  {color: "orange", value: "Đã gửi", key: 2},
];
export const TELCO_TYPE = [
  {
    key: "viettel",
    value: "Viettel"
  },
  {
    key: "vina",
    value: "Vinaphone"
  },
  {
    key: "US",
    value: "US"
  },
  {
    key: "mobi",
    value: "Mobiphone"
  },
];

//STATUS_INVENTORY

//PAYMENT_METHOD
export const PAY_METHOD = {
  BANK: "BT",
  CREDIT: "CC"
};
export const PAY_METHOD_ARRAY = () => {
  const payMethod: { code: string; value: string }[] = store.getState().dataPersist.listPaymentMethod;
  const result: any = {};
  payMethod.map(item => (result[item.code] = item.value));
  return result;
};

//ACTIVITIES_TYPE
export const ACTIVITIES_TYPE = {
  // LOGIN: "ACTIVITIES_LOGIN",
  // LOGOUT: "ACTIVITIES_LOGOUT",
  IMPORT: "ACTIVITIES_IMPORT",
  EXPORT: "ACTIVITIES_EXPORT",
  INVOICE: "ACTIVITIES_SELL",
  CHECK: "ACTIVITIES_CHECK",
  PRODUCT: "ACTIVITIES_PRODUCT",
  CANCEL_IMPORT: "ACTIVITIES_CANCEL_IMPORT",
  CANCEL_EXPORT: "ACTIVITIES_CANCEL_EXPORT",
  APPROVED_IMPORT: "ACTIVITIES_APPROVED_IMPORT",
  APPROVED_EXPORT: "ACTIVITIES_APPROVED_EXPORT",
  NON_BUSINESS_PRODUCT: "ACTIVITIES_NON_BUSINESS_PRODUCT"
};

//NOTIFICATION_TYPE
export const NOTIFICATION_TYPE = {
  EXPIRED: 1,
  WARNING_IMPORT: 2,
  ABOUT_TO_EXPIRED: 3,
  ONLINE_ORDER: 4,
  CREATE_INVOICE: 5,
  ORDER_STATUS: 7
};
export const NOTIFICATION_STATUS = {
  READ: 1,
  NO_READ: 0
};

export const NOTIFICATION_TYPE_DASHBOARD = {
  NOTIFICATION_PHARMACY: 'NOTIFICATION_PHARMACY',
  NOTIFICATION_SYSTEM: 'NOTIFICATION_SYSTEM',

}

export const UNIT_TYPE = {
  UNIT_BASE: 1,
  UNIT_CHANGE: 0
};
export const TYPE_SHOW_ORDER_ONLINE = [
  {key: 1, value: "Danh sách"},
  {key: 2, value: "Bản đồ đơn"}
]
export const API_KEY_MAP = "AIzaSyCrGQMvNb9XPPlRBSc0rt4FbKlUGes7BtA"
export const URL_MAP_DIRECTION = `https://maps.googleapis.com/maps/api/js?key=${API_KEY_MAP}&v=3.exp&libraries=geometry,drawing,places`
export const STATUS_ORDER_ONLINE = {
  NEW: 0,
  CONFIRM: 1,
  PROCESS: 2,
  COMPLETED: 3,
  CANCEL: 4
}
export const GET_STATUS_ORDER_ONLINE = (status: number) => {
  switch (status) {
    case STATUS_ORDER_ONLINE.CANCEL: {
      return {
        value: "Đơn đã hủy",
        color: "red"
      };
    }
    case STATUS_ORDER_ONLINE.NEW: {
      return {
        value: "Đơn mới",
        color: "orange"
      };
    }
    case STATUS_ORDER_ONLINE.COMPLETED: {
      return {
        value: "Đã hoàn thành",
        color: "green"
      };
    }
    case STATUS_ORDER_ONLINE.PROCESS: {
      return {
        value: "Đang chờ giao",
        color: "blue"
      };
    }
    case STATUS_ORDER_ONLINE.CONFIRM: {
      return {
        value: "Chờ xác nhận",
        color: "orange"
      };
    }
    default:
      return {
        value: "",
        color: ""
      };
  }
};
export const STATUS_ORDER_ONLINE_STORE = [
  {
    key: 0,
    value: "Xác nhận"
  },
  {
    key: 1,
    value: "Chờ giao hàng"
  },
  {
    key: 2,
    value: "Hoàn thành"
  },
  {
    key: 3,
    value: "Đã hủy"
  }
]
export const listDelivery = [
  {
    key: 0,
    nameDelivery: "Tự giao hàng"
  },
  {
    key: 1,
    nameDelivery: "Giao hàng tiết kiệm"
  },
  {
    key: 2,
    nameDelivery: "Giao hàng nhanh"
  },
  {
    key: 3,
    nameDelivery: "J&T"
  },
  {
    key: 4,
    nameDelivery: "VN Post"
  }
]
export const SPECIAL_CONTROL_DRUGS = [
  {
    key: 0,
    value: "Không"
  },
  {
    key: 1,
    value: "Thuốc gây nghiện"
  },
  {
    key: 2,
    value: "Thuốc hướng thần"
  },
  {
    key: 3,
    value: "Thuốc tiền chất"
  },
  {
    key: 4,
    value: "Thuốc dạng phối hợp có chứa dược chất gây nghiện"
  },
  {
    key: 5,
    value: "Thuốc dạng phối hợp có chứa dược chất hướng thần"
  },
  {
    key: 6,
    value: "Thuốc dạng phối hợp có chứa tiền chất"
  },
  {
    key: 7,
    value: "Thuốc phóng xạ, đồng vị phóng xạ"
  },
  {
    key: 8,
    value: "Nguyên liệu làm thuốc là dược chất HTT, chất GN, TC dùng làm thuốc hoặc chất phóng xạ để sản xuất thuốc"
  },
  {
    key: 9,
    value: "Thuốc độc, nguyên liệu độc làm thuốc"
  },
  {
    key: 10,
    value: "Thuốc, dược chất thuộc danh Mục chất bị cấm sử dụng trong một số ngành, lĩnh vực."
  },
]

export const ORDER_ONLINE_VIEW = {
  MAP: "MAP",
  TABLE: "TABLE"
}

export const SUPPLY_ORDER_STATUS = {
  // WAITING_CONFRIM: "draft",
  // APPROVED: "sale",
  // SHIPPING: "ship",
  // COMPLETE: "done",
  // CANCEL: "cancel"
  WAITING_CONFRIM: 1,
  APPROVED: 5,
  SHIPPING: 6,
  COMPLETE: 2,
  CANCEL: 0,
  PARTIAL_PAYMENT: 3,
  NOT_PAYMENT: 4,
}

export const STATUS_SYNC = {
  SYNC: "SYNC",
  UN_SYNC: "NOT_SYNC"
}

export const PERMISSION = {
  PRODUCT_READ: "PRODUCT_READ",
  PRODUCT_WRITE: "PRODUCT_WRITE",
  IMPORT_READ: "IMPORT_READ",
  IMPORT_WRITE: "IMPORT_WRITE",
  EXPORT_READ: "EXPORT_READ",
  EXPORT_WRITE: "EXPORT_WRITE",
  INVOICE_READ: "INVOICE_READ",
  INVOICE_WRITE: "INVOICE_WRITE",
  CUSTOMER_READ: "CUSTOMER_READ",
  CUSTOMER_WRITE: "CUSTOMER_WRITE",
  PROVIDER_READ: "PROVIDER_READ",
  PROVIDER_WRITE: "PROVIDER_WRITE",
  CHECKING_READ: "CHECKING_READ",
  CHECKING_WRITE: "CHECKING_WRITE",
  EMPLOYEE_READ: "EMPLOYEE_READ",
  EMPLOYEE_WRITE: "EMPLOYEE_WRITE",
  STORE_WRITE: "STORE_WRITE",
  STORE_READ: "STORE_READ",
  SUPPLIER_ORDER_READ: "SUPPLIER_ORDER_READ",
  SUPPLIER_ORDER_WRITE: "SUPPLIER_ORDER_WRITE",
  PRICE_WRITE: "PRICE_WRITE",
  PRICE_READ: "PRICE_READ",
  ACCOUNTING_READ: "ACCOUNTING_READ",
  ACCOUNTING_WRITE: "ACCOUNTING_WRITE",
  REPORT_REVENUE: "REPORT_REVENUE",
  COMBO_READ: "COMBO_READ",
  COMBO_WRITE: "COMBO_WRITE",
  PRICE_IMPORT_READ: "PRICE_IMPORT_READ",
  DEBT_READ: "DEBT_READ",
  DEBT_WRITE: "DEBT_WRITE",
  CASHBOOK_READ: "CASHBOOK_READ",
  CASHBOOK_WRITE: 'CASHBOOK_WRITE',
  LOYALTY_READ: "LOYALTY_READ",
  LOYALTY_WRITE: "LOYALTY_WRITE"
}

export const TYPE_OPTION_PRINT_BARCODE = {
  PRINT_LOT: {label: "In kèm lô", value: "PRINT_LOT"},
  PRINT_PRICE: {label: "In kèm giá", value: "PRINT_PRICE"},
  PRINT_DRUG_STORE: {label: "In kèm nhà thuốc", value: "PRINT_DRUG_STORE"},
  PRINT_BARCODE: {label: "In kèm mã vạch", value: "PRINT_BARCODE"},
  PRINT_ALL_PRICES: {label: "In tất cả giá bán", value: "PRINT_ALL_PRICES"}
}

export const LIST_TYPE_OPTION_PRINT_BARCODE = [
  {label: "In kèm lô", value: "PRINT_LOT"},
  {label: "In kèm giá", value: "PRINT_PRICE"},
  {label: "In kèm nhà thuốc", value: "PRINT_DRUG_STORE"},
  {label: "In kèm mã vạch", value: "PRINT_BARCODE"},
  {label: "In tất cả giá bán", value: "PRINT_ALL_PRICES"}
]

export const LIST_TYPE_PRINT_STORE_INVOICE = [
  {label: 'Kho hiện tại', value: 'BASE_DEPOT'},
  {label: 'Tất cả chi nhánh', value: 'ALL_STORE'},
  {label: 'Chọn chi nhánh', value: 'SELECT_STORE'},
]

export const TOPIC_NOTIFICATION = {
  NEW_VERSION: "NEW_VERSION",
}

export const MASTER_FLG = {
  IS_MASTER: 1, // KHO TONG
  NOT_MASTER: 0 // KHO LE
}

export const TYPE_SHOW_PRODUCT = {
  LIST: 0,
  IMAGE: 1
}

export const LIST_TYPE_SHOW_PRODUCT = [
  "Không có ảnh",
  "Có ảnh hiển thị"
]

export const TYPE_SYNC = {
  AUTO: {label: "Liên thông", value: 1},
  NONE: {label: "Không liên thông", value: 0}
}

export const LIST_AUTO_SYNC = [
  {label: "Liên thông", value: "AUTO"},
  {label: "Không liên thông", value: "NONE"}
]

export const TYPE_BUSINESS = {
  RETAIL: "RETAIL",
  WHOLESALE: "WHOLESALE",
  ALL: "ALL"
}
export const TYPE_BUSINESS_FORM = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  ALL: "ALL"
}
export const LIST_TYPE_BUSINESS_FORM = [
  {code: 'OFFLINE', value: "Bán tại quầy"},
  {code: 'ONLINE', value: "Bán trực tuyến"},
  {code: 'ALL', value: "Tất cả"},
]

export const LIST_TYPE_BUSINESS = [
  "Bán lẻ",
  "Bán buôn",
  "Tất cả"
]

export const VALUE_BUSINESS_TYPE = [
  {code: null, value: "Tất cả"},
  {code: 0, value: "Bán lẻ"},
  {code: 1, value: "Bán buôn"},
]

export const PRINTER_BARCODE_TYPE = {
  ITEM_2: {label: "Mẫu giấy cuộn 2 nhãn", value: "ITEM_2"},
  ITEM_3: {label: "Mẫu giấy cuộn 3 nhãn", value: "ITEM_3"},
  PDF: {label: "Xuất file pdf", value: "PDF"}
};

export const LIST_PRINTER_BARCODE_TYPE = [
  {label: "Mẫu giấy cuộn 2 nhãn", value: "ITEM_2"},
  {label: "Mẫu giấy cuộn 3 nhãn", value: "ITEM_3"},
  {label: "Xuất file pdf", value: "PDF"}
]

//PRINTER_TYPE
export const PRINTER_TYPE = {
  SMALL: {label: "Khổ 58mm", value: "SMALL"},
  MEDIUM: {label: "Khổ 80mm", value: "MEDIUM"},
  BIG: {label: "Khổ A5", value: "BIG"},
  PDF: {label: "PDF", value: "PDF"}
};

//PRINTER_TYPE
export const LIST_PRINTER_TYPE = [
  {label: "Khổ 58mm", value: "SMALL"},
  {label: "Khổ 80mm", value: "MEDIUM"},
  {label: "Khổ A5", value: "BIG"},
  {label: "PDF", value: "PDF"}
]
// COMBO_TYPE
export const COMBO_TYPE = [
  {code: 'DOSAGE', value: 'Liều bán', prefix: 'RX', unitName: 'Liều'},
  {code: 'PROMOTION', value: 'Combo', prefix: 'KM', unitName: 'Combo'},
  {code: '', value: 'Tất cả', prefix: '', unitName: ''}
]
export const COMBO_STATUS = {
  ACTIVE: 1,
  IN_ACTIVE: 0
}

export const GET_STATUS_COMBO = (status: number) => {
  switch (status) {
    case COMBO_STATUS.ACTIVE: {
      return {
        value: "Áp dụng",
        color: "green"
      };
    }
    case COMBO_STATUS.IN_ACTIVE: {
      return {
        value: "Ngừng áp dụng",
        color: "red"
      };
    }
    default:
      return {
        value: "",
        color: ""
      };
  }
};


export const TYPE_VALUE_INVOICE = {
  DOSAGE: 'DOSAGE',
  PROMOTION: 'PROMOTION',
  INVENTORY: 'INVENTORY'
}

export const TYPE_INVENTORY_PRODUCT = {
  EXIST: 1,
  OVER: 0
}

export const INVOICE_PAY_METHOD = {
  COD: 'COD',
  CS: 'CS',
}
export const CASHFLOW_TYPE = {
  PAYMENT: "PAYMENT",
  RECEIPT: "RECEIPT"
}
export const LIST_CASHFLOW_TYPE = [
  {code: 'RECEIPT', value: 'Phiếu thu', title: 'Loại phiếu thu'},
  {code: 'PAYMENT', value: 'Phiếu chi', title: 'Loại phiếu chi'},
]

export const USER_GROUP = {
  EMPLOYEE: 'EMPLOYEE',
  CUSTOMER: 'CUSTOMER',
  PROVIDER: 'PROVIDER',
  LOGISTIC: 'LOGISTIC',
  OTHER: 'OTHER',
}

export const CASHFLOW_PARTNER_GROUP = {
  CUSTOMER: 'CUSTOMER',
  PROVIDER: 'PROVIDER'
}

export const COMPANY_TYPE = {
  PHARMACY: "PHARMACY",
  CHAIN_PHARMACY: "CHAIN_PHARMACY",
  PROVIDER: "PROVIDER",
  CLINIC: "CLINIC",
  COMPANY: "COMPANY",
}

export const LIST_COMPANY_TYPE = [
  {
    type_code: "PHARMACY",
    type_id: 6,
    type_name: "Nhà thuốc lẻ"
  },
  {
    type_code: "CHAIN_PHARMACY",
    type_id: 7,
    type_name: "Chuỗi nhà thuốc"
  },
  {
    type_code: "PROVIDER",
    type_id: 8,
    type_name: "Nhà cung cấp"
  },
  {
    type_code: "COMPANY",
    type_id: 9,
    type_name: "Công ty dược"
  },
  {
    type_code: "CLINIC",
    type_id: 10,
    type_name: "Phòng khám"
  },

  {
    type_code: "PHARMACIST",
    type_id: 11,
    type_name: "Dược sĩ"
  },
]

export const ROLE_CODE = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
  HEAD: "HEAD"
}

export const divSubizChatId = 'cprfenukgktwnqkndzmev'

export const IMPORT_TYPE = {
  ORD: 'ORD',
  RET: 'RET',
  REV: 'REV',
  CHK: 'CHK',
  TRS: 'TRS',
}
export const EXPORT_TYPE = {
  ORD: 'ORD',
  DIS: 'DIS',
  DMG: 'DMG',
  OVER: 'OVER',
  RET: 'RET',
  REV: 'REV',
  TRS: 'TRS',
  EXW: 'EXW',
  CHK: 'CHK',
  OTHER: 'OTHER'
}
export const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
  DEFAULT: ''
}
export const SORT_PROPERTY = {
  AMOUNT: 'amount',
  DEFAULT: ''
}
export const REFERENCE_TYPE = {
  BRAND_NAME: "BRAND_NAME",
  ZALO_OA: "ZALO_OA"
}
export const SMS_COMPANY_STATUS_LIST = [
  {
    value: 'Ngắt kết nối',
    color: 'red'
  },
  {
    value: 'Đang chờ xử lý',
    color: 'orange'
  },
  {
    value: 'Đã kết nối',
    color: 'green'
  },
]

export const SMS_COMPANY_STATUS = {
  NOT_CONNECT: {value: 'Chưa kết nối', color: 'orange'},
  CANCEL_CONNECT: {value: 'Chưa kết nối', color: 'orange'},
  WAIT_CONNECT: {value: 'Chưa kết nối', color: 'orange'},
  CONNECTED: {value: 'Chưa kết nối', color: 'orange'}

}

export const STATUS_MESSEGE_SMS = {
  DISABLE: 0,
  NOT_ACTIVE: 1,
  ACTIVE: 2
};

export const TIMER_DELAY_OTP = 180;

export const CHANNEL_CODE = {
  SMS: 'SMS',
  ZALO_OA: 'ZALO_OA'
}
export const LIST_CHANNEL_CODE = [
  {key: CHANNEL_CODE.SMS, value: 'SMS'},
  {key: CHANNEL_CODE.ZALO_OA, value: 'Zalo OA'},
]

export const VERSION_SHOW_SMS_LANDING_PAGE = config.version
export const VERSION_UPDATE_DATA_PERSIST = config.version

export const CAMPAIGN_TYPE = {
  PROACTIVE: 'PROACTIVE',
  AUTO: 'AUTO',
  INVOICE_SUCCESS: 'INVOICE_SUCCESS',
}

export const LIST_CAMPAIGN_TYPE = [
  {key: CAMPAIGN_TYPE.PROACTIVE, value: 'Chiến dịch chủ động'},
  {key: CAMPAIGN_TYPE.AUTO, value: 'Chiến dịch tự động'}
]

export const LIST_CAMPAIGN_TYPE_AUTO = [
  {key: CAMPAIGN_TYPE.INVOICE_SUCCESS, value: 'Thông báo đơn hàng'}
]

export const CAMPAIGN_MESSAGE_TYPE = {
  CSKH: 'CSKH',
  QC: 'QC'
}
export const PRICE_CAMPAIGN_MESSAGE = {
  SMS_CSKH: 700,
  SMS_QC: 400,
  ZALO_CSKH: 200
}
export const LIST_CAMPAIGN_MESSAGE_TYPE = [
  {key: CAMPAIGN_MESSAGE_TYPE.QC, value: 'Tin nhắn quảng cáo'},
  {key: CAMPAIGN_MESSAGE_TYPE.CSKH, value: 'Tin nhắn CSKH'}
]
export const MESSAGE_ZALO_COMMAND = {
  NORMAL: 'NORMAL',
  PRIORITY: 'PRIORITY'
}
export const LIST_MESSAGE_ZALO_COMMAND = [
  {key: MESSAGE_ZALO_COMMAND.NORMAL, value: 'Zalo bình thường'},
  {key: MESSAGE_ZALO_COMMAND.PRIORITY, value: 'Zalo ưu tiên'}
]
export const CONDITION_TYPE = {
  AND: 'AND',
  OR: 'OR '
}
export const SEX = {
  MAN: {value: 'Nam', code: 'M'},
  WOMEN: {value: 'Nữ', code: 'W'}
}

export const CUSTOMER_GROUP_TYPE = {
  FIXED: 'FIXED',
  DYNAMIC: 'DYNAMIC'
}
export const TYPE_CUSTOMER_GROUP = [
  {code: 'FIXED', value: 'Cố định'},
  {code: 'DYNAMIC', value: 'Tự động'},
]
export const TYPE_SCHEDULE_TIME = {
  NOW: 'NOW',
  SCHEDULE: 'SCHEDULE'
}

export const MARKETING_TIMER_ALOW = {
  MORNING: ['08:30:00', '11:30:00'],
  AFTERNOON: ['13:30:00', '20:00:00'],
}

export const STATUS_CAMPAIGN = {
  CANCEL: {key: 0, value: "Đã hủy", color: "red"},
  CREATED: {key: 1, value: "Đang gửi", color: "orange"},
  ACTIVED: {key: 2, value: "Đã kích hoạt", color: "blue"},
  COMPLETED: {key: 3, value: "Đã hoàn thành", color: "green"},
}

export const LIST_STATUS_CAMPAIGN = [
  STATUS_CAMPAIGN.CANCEL,
  STATUS_CAMPAIGN.CREATED,
  STATUS_CAMPAIGN.ACTIVED,
  STATUS_CAMPAIGN.COMPLETED,
];
export const LIST_STATUS_CAMPAIGN_DETAIL = [
  {key: 0, value: "Đã hủy", color: "red"},
  {key: 1, value: "Đang gửi", color: "orange"},
  {key: 2, value: "Đã kích hoạt", color: "blue"},
  {key: 3, value: "Đã hoàn thành", color: "green"},
];

export const SMS_CUSTOMER_STATUS = [
  {key: 2, value: 'Đang gửi', color: "orange"},
  {key: 1, value: 'Thành công', color: "green"},
  {key: 0, value: 'Thất bại', color: "red"},
]

export const EXIST_PHONE_STATUS = [
  {key: 0, value: 'Chưa có SĐT'},
  {key: 1, value: 'Đã có SĐT'}
]

export const SYSTEM = {key: 'sys', value: 'Hệ thống'}

export const TEMPLATE_PARAM_NAME = (paramCode: string) => {
  switch (paramCode) {
    case "invoice_code":
    case "oder_number":
      return "Mã đơn hàng"
    case "invoice_info":
      return "Thông tin đơn hàng"
    case "sex":
      return "Giới tính"
    case "ecoin":
      return "Điểm thưởng"
    case "contact":
      return "Thông tin liên hệ"
    case "customer_name":
      return "Tên Khách hàng"
    default:
      return "Nhập thông tin"
  }
}

// export const STORE_PACKAGE = {
//   FREE: 1,
//   BASIC: 2,
//   PRO: 3
// }

export const PACKAGE_CODE = {
  PHARMACY_FREE: "PHARMACY_FREE",
  PHARMACY_BASIC: "PHARMACY_BASIC",
  PHARMACY_PRO: "PHARMACY_PRO",
}

export const PACKAGE_NAME = {
  PHARMACY_FREE: 'Gói miễn phí',
  PHARMACY_BASIC: "Gói cơ bản",
  PHARMACY_PRO: "Gói nâng cao",
}

export const PACKAGE_CODE_PRIMARY = {
  FREE: "FREE",
  BASIC: "BASIC",
  PRO: "PRO",
}

export const MODULE = {
  INVOICE: 'INVOICE',
  PRODUCT: 'PRODUCT',
  INVENTORY: 'INVENTORY',
  PARTNER: 'PARTNER',
  ANALYTIC: 'ANALYTIC',
  LOYALTY: 'LOYALTY',
  MARKETING: 'MARKETING',
  INVOICE_ONLINE: 'INVOICE_ONLINE',
  REPORT: 'REPORT',
  SYNC: 'SYNC',
  CASHBOOK: 'CASHBOOK',
  ACCOUNTING: 'ACCOUNTING',
  EMPLOYEE: 'EMPLOYEE',
  SUB_STORES: 'SUB_STORES',
  TIMEKEEPING: 'TIMEKEEPING',
}

export const PACKAGE_TYPE = {
  PHARMACY: 'PHARMACY',
  CLINIC: 'CLINIC',
  COMPANY: 'COMPANY',
}

export const TITLE_BUTTON_UPGRADE = {
  UPGRADE_PACKAGE: 'Nâng cấp gói',
  RENEW_PACKAGE: 'Gia hạn gói',
}

export const IS_TRIAL = {
  YES: 1,
  NO: 0,
}

export const SMS_CASHFLOW_TYPE = {
  ALL: "ALL"
}

export const CAMPAIGN_TYPE_STATUS = {
  ACTIVE: 1,
  NOT_ACTIVE: 0
}
export const PRODUCT_TYPE = {
  ADS: 'ADS',
  POINT: 'POINT',
}
export const convertProductType = (value: string) => {
  switch (value) {
    case PRODUCT_TYPE.ADS:
      return 'Hàng quảng cáo'
    case PRODUCT_TYPE.POINT:
      return 'Hàng điểm'
    default:
      return null
  }
}

export const SELLING_POINT_TYPE = {
  IS_SELLING_POINT: 1,
  NOT_SELLING_POINT: 0
}

export const LIST_LINKED_TYPE = [
  {code: 'NOT_LINKED', value: 0, name: 'Chưa liên kết'},
  {code: 'IS_LINKED', value: 1, name: 'Đã liên kết'},
]

export const TIMEKEEPING_TYPE = {
  CHECK_IN: 1,
  CHECK_OUT: 0
}

export const LIST_EMPLOYEE_STATUS = [
  {value: '', name: "Tất cả"},
  {value: 1, name: "Đang hoạt động"},
  {value: 0, name: "Không hoạt động"},
]

export const LIST_ROLE_CODE = [
  {value: '', name: "Tất cả"},
  {value: 'OWNER', name: "Chủ sở hữu"},
  {value: 'ADMIN', name: "Quản trị"},
  {value: 'MEMBER', name: "Nhân viên"},
  {value: 'HEAD', name: "Trưởng quầy"},
  {value: 'ACCOUNTING', name: "Kế toán"}
]

export const LIST_PRINT_TYPE = [
  {value: 'INVOICE', name: 'In hóa đơn'},
  {value: 'IMPORT', name: 'In phiếu nhập'},
  {value: 'EXPORT', name: 'In phiếu xuất'},
]

export const PRINT_TYPE = {
  INVOICE: "INVOICE",
  IMPORT: "IMPORT",
  EXPORT: "EXPORT"
}

export const STATUS = {
  ACTIVE: 1,
  NOT_ACTIVE: 0
}

export const LIST_STATUS = [
  {name: 'Tất cả', value: ''},
  {name: 'Đã kích hoạt', value: STATUS.ACTIVE},
  {name: 'Đã dừng', value: STATUS.NOT_ACTIVE},
]

export const TITLE_MODAL_REGISTER = {
  TITLE_CONFIRM: 'Xác nhận đăng ký',
  TITLE_REGISTER: 'Đăng ký điểm bán',
}

export const LIST_REGISTER_STATUS = [
  {key: '', name: 'Tất cả'},
  {key: 0, name: 'Đã từ chối'},
  {key: 1, name: 'Xác nhận'},
  {key: 2, name: 'Chờ duyệt'},
]

export const REGISTER_STATUS = {
  ALL: '',
  REJECT: 0,
  APPROVED: 1,
  PENDING: 2,
}

export const STATUS_VALUE = (status: number) => {
  switch (status) {
    case 0: {
      return {
        value: "Đã từ chối",
        color: "red"
      };
    }
    case 2: {
      return {
        value: "Chờ duyệt",
        color: "orange"
      };
    }
    case 1: {
      return {
        value: "Đã duyệt",
        color: "green"
      };
    }
    default:
      return {
        value: "",
        color: ""
      };
  }
};

export const STATUS_FORM_VALUE = {
  APPROVED: 1,
  WAITING_CONFRIM: 2,
  CANCEL: 0
}
export const LIST_BUSINESS_TYPE = [
  {code: "RETAIL", value: "Bán lẻ"},
  {code: "WHOLESALE", value: "Bán buôn"},
]

export const LIST_OPTION_PRINT_BARCODE = [
  {code: "PRINT_LOT", value: "In kèm lô"},
  {code: "PRINT_PRICE", value: "In kèm giá"},
  {code: "PRINT_DRUG_STORE", value: "In kèm nhà thuốc"},
  {code: "PRINT_BARCODE", value: "In kèm mã vạch"},
  {code: "PRINT_ALL_PRICES", value: "In tất cả giá bán"},
]

export const LIST_BUSINESS_TYPE_CODE = [
  {code: 'OFFLINE', value: "Bán tại quầy"},
  {code: 'ONLINE', value: "Bán trực tuyến"},
]

export const LIST_DISPLAY_IMAGE_TYPE = [
  {label: 'Có ảnh hiển thị', value: 1},
  {label: 'Không có ảnh', value: 0}
]

export const LIST_SYNC_TYPE = [
  {label: "Liên thông", value: 1},
  {label: "Không liên thông", value: 0}
]

export const ERR_PRODUCT = {
  NOT_PRODUCT: 'Không tìm thấy sản phẩm trong kho',
  EXITS_DATE: 'Hết hạn sử dụng',
  NOT_UNIT: 'Không tồn tại đơn vị',
  QUANTITY_NOT_ENOUGH: 'Không đủ số lượng',
}

export const LIST_IMPORT_TYPE = [
  {value: 'ORD', name: "Yêu cầu nhập kho"},
  {value: 'REV', name: "Trả lại kho"},
  {value: 'RET', name: "Trả lại bán hàng"},
  {value: 'CHK', name: "Kiểm Kho"},
  {value: 'TRS', name: "Nhập kho từ chi nhánh"},
]

export const PROGRAM_EARN_ECOIN_TYPE = [
  {value: 'INVOICE', name: 'Tích điểm theo hóa đơn'},
  {value: 'PRODUCT', name: 'Tích điêm theo sản phẩm'},
]

export const PROGRAM_VALUE = {
  INVOICE: 'INVOICE',
  PRODUCT: 'PRODUCT'
}

export const COMBO_TYPE_VALUE = {
  DOSAGE: 'liều',
  PROMOTION: 'combo',
}

export const LIST_TYPE_PROMOTION = [
  {name: 'Chương trình khuyến mãi', value: 'PROMOTION'},
  {name: 'Chính sách bán hàng', value: 'SALE_POLICY'}
]

export const PROMOTION_TYPE = {
  PROMOTION: 'PROMOTION',
  SALE_POLICY: 'POLICY'
}

export const PROMOTION_TYPE_CREATE = {
  INVOICE: 'INVOICE',
  PRODUCT: 'PRODUCT'
}

export const TYPE_CASHFLOW_ECOIN = {
  USE_ECOIN: 'USE_ECOIN',
  EARN_ECOIN: 'EARN_ECOIN',
  RE_USE_ECOIN: 'RE_USE_ECOIN',
  RE_EARN_ECOIN: 'RE_EARN_ECOIN'
}

export const LIST_TYPE_CASHFLOW_ECOIN = [
  {name: 'Sử dụng điểm', value: 'USE_ECOIN'},
  {name: 'Tích điểm', value: 'EARN_ECOIN'},
  {name: 'Trả lại điểm sử dụng', value: 'RE_USE_ECOIN'},
  {name: 'Trả lại điểm tích', value: 'RE_EARN_ECOIN'},
]

export const INVOICE_STATUS_ORDER = {
  CANCEL: 0,
  WAITING_CONFRIM: 1,
  APPROVED: 2,
  PREPARE: 3,
  SHIPPING: 4,
  COMPLETE: 5,// COD
};

export const GET_STATUS_ORDER_VALUE = (status: number) => {
  var value = "", color = "orange";
  switch (status) {
    case INVOICE_STATUS_ORDER.WAITING_CONFRIM:
      color = "orange"
      value = "Chờ xác nhận";
      break;
    case INVOICE_STATUS_ORDER.APPROVED:
      color = "gold"
      value = "Đã xác nhận";
      break;
    case INVOICE_STATUS_ORDER.PREPARE:
      color = "blue"
      value = "Chuẩn bị đơn hàng";
      break;
    case INVOICE_STATUS_ORDER.SHIPPING:
      color = "lime"
      value = "Đang giao";
      break;
    case INVOICE_STATUS_ORDER.COMPLETE:
      color = "green"
      value = "Đã hoàn thành";
      break;
    case INVOICE_STATUS_ORDER.CANCEL:
      color = "red"
      value = "Đã huỷ";
      break;
    default:
      return {
        color: "",
        value: ""
      };
  }
  return {color, value}
};

export const COLUMN_TYPE = {
  ORDER_STATUS: 'order_status',
  STATUS: 'status'
}

export const LIST_PRIORITY_TYPE = [
  {name: 'Không', value: STATUS.NOT_ACTIVE},
  {name: 'Có', value: STATUS.ACTIVE},
]

export const LIST_BANNER_TYPE = [
  {name: 'Banner khuyến mãi', value: 'PROMOTION'},
  {name: 'Banner chính sách', value: 'POLICY'},
  {name: 'Banner tin tức', value: 'NEWS'},
  {name: 'Banner thông báo', value: 'INFO'},
  {name: 'Banner quảng cáo', value: 'ADS'},
]

export const LIST_APPLICABLE_TYPE = [
  {name: 'Khuyến mãi sản phẩm', value: 'PRODUCT'},
  {name: 'Khuyến mãi hóa đơn', value: 'INVOICE'},
]

export const APPLICABLE_TYPE = {
  PRODUCT: 'PRODUCT',
  INVOICE: 'INVOICE',
}

export const BANNER_TYPE = {
  PROMOTION: 'PROMOTION',
  POLICY: 'POLICY',
  NEWS: 'NEWS',
  INFO: 'INFO'
}

export const LIST_BANNER_STATUS = [
  {name: 'Chờ kích hoạt', value: 0},
  {name: 'Đã kích hoạt', value: 1},
  {name: 'Yêu cầu chỉnh sửa', value: 2},
]

export const BANNER_STATUS = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
  REQUEST_EDIT: 2,
}

export const TYPE_CHANGE_INPUT = {
  PROMOTION_NAME: 'promotion_name',
  MAX_USES: 'max_uses',
  SHORT_DESCRIPTION: 'short_description',
  DESCRIPTION: 'description',
  FROM_TIME: 'from_time',
  TO_TIME: 'to_time',
  SEND_NOTI_TYPE: 'send_noti_type',
  SEND_NOTI_TIME: 'send_noti_time',
  CUSTOMER_GROUP_IDS: 'customer_group_ids',
  BUSINESS_TYPE: 'business_type',
  SOURCES: 'sources',
  DRG_STORE_IDS: 'drg_store_ids',
  FROM_AMOUNT: 'from_amount',
  TO_AMOUNT: 'to_amount',
  PRODUCT_PRICE: 'product_price',
  PRODUCT_QUANTITY: 'product_quantity',
  GIFT_QUANTITY: 'gift_quantity',
  MAXIMUM_QUANTITY: 'maximum_quantity',
  MINIMUM_QUANTITY: 'minimum_quantity',
  REASON_EDIT: 'reason_edit',
}

export const LIST_SEND_NOTI_TYPE = [
  {name: 'Hàng ngày', value: 'DAILY'},
  {name: 'Thời điểm', value: 'TIME'},
]

export const SEND_NOTI_TYPE = {
  DAILY: 'DAILY',
  TIME: 'TIME'
}

export const LIST_ERROR_EXPORT = [
  {
    name: 'Không tìm thấy chi nhánh chuyển kho',
    code: 4065,
    value: 'Store import not found'
  },
  {
    name: 'Không tìm thấy nhà thuốc',
    code: 4058,
    value: 'Store not found'
  },
  {
    name: 'Các cửa hàng không thuộc cùng một chuỗi nhà thuốc',
    code: 4059,
    value: 'Stores that are not part of the same pharmacy chain'
  },
  {
    name: 'Không tìm thấy hàng tồn kho',
    code: 4063,
    value: 'Inventory not found'
  },
  {
    name: 'Không tìm thấy đơn vị',
    code: 4064,
    value: 'Unit not found'
  },
  {
    name: 'Số lượng trong kho không đủ',
    code: 4060,
    value: 'Quantity invalid'
  },
  {
    name: 'Tạo phiếu lỗi',
    code: 4061,
    value: 'Create import store other fails'
  },
]

export const MESSAGE_WARNING = {
  INVALID_NAME: 'Tên sản phẩm không hợp lệ!',
  REQUEST_NAME: 'Nhập tên, mã thuốc hoặc hoạt chất',
  SETTING_PRINT_INVOICE: 'Cài đặt hiển thị tạo hóa đơn mới',
  CONFIRM_DELETE_PRODUCT: 'Bạn có muốn xóa thuốc này?',
  LIMIT_QUANTITY: 'Số lượng lớn hơn 0',
  PRICE_ERROR: 'Giá sai',
  NOT_SUPPORT_UNIT: 'Đơn vị không được hỗ trợ',
  UNIT_EMPTY: "Thiếu đơn vị",
  LIMIT_TAB_IMPORT: 'Tối đa 5 phiếu nhập',
  INDUSTRY_NAME_NOT_JUST_SPACES: 'Tên ngành hàng không được chỉ có dấu cách',
  CONFIRM_DELETE: "Toàn bộ dữ liệu của thuốc này sẽ bị xoá trong kho, Bạn có chắc chắn muốn xoá sản phẩm này?",
}

export const NUMBER_DEFAULT = {
  ONE: 1,
  TIME_DELAY_INPUT: 500,
  MAX_PERSENT: 100,
  EXCHANGE_VALUE_DEFALT: 10000,
  MAX_LENGTH_INPUT_NUMBER: 11,
  LIMIT_TAB: 5,
}

export const RESPONSE_CODE = {
  OK: 200,
}

export const MESSAGE_SUCCES = {
  RETURN_PRODUCT_SUCCES: 'Trả lại hàng thành công!',
  DELETE_PRODUCTS_SUCCES: "Xóa sản phẩm thành công!",
  UPDATE_PRODUCTS_SUCCES: 'Cập nhật thông tin thuốc thành công!'
}

export const MESSAGE_ERROR = {
  RETURN_PRODUCT_FAILED: 'Trả lại hàng thất bại!',
  CAN_NOT_SCAN_BARCODE: 'Không thể quét mã vạch',
  QUANTITY_PRODUCT_NOT_ENOUGH: 'Số lượng thuốc không đủ',
  QUANTITY_INVENTORY_NOT_ENOUGH: 'Số lượng tồn kho không đủ',
  NOT_FOUND_PRDUCT: 'Không tìm thấy thuốc trong kho',
  NOT_DATA: 'Không tìm thấy dữ liệu',
  DELETE_PRODUCTS_FAIED: "Xóa sản phẩm thất bại!",
  UPDATE_PRODUCTS_FAIED: "Cập nhật thông tin thuốc lỗi!",
}

export const DOMAIN = {
  PHARMACY: 'nhathuoc.medlink.vn',
  PHARMACO: 'pharmaco.medlink.vn',
  CLINIC: 'clinic.medlink.vn',
}

export const META_DESCRIPTION = {
  PHARMACY: 'Khám phá giải pháp quản lý nhà thuốc hiệu quả với MedLink. Tiết kiệm chi phí, tăng doanh thu và tối ưu hóa hoạt động kinh doanh của bạn ngay hôm nay!',
  PHARMACO: 'Pharmaco MedLink - Nền tảng kết nối nhà thuốc và nhà cung cấp dược phẩm uy tín. Cung cấp dịch vụ nhập thuốc trực tuyến nhanh chóng, tiện lợi và an toàn.',
  CLINIC: 'Clinic MedLink - Giải pháp quản lý phòng khám toàn diện. Nâng cao hiệu quả vận hành, quản lý lịch hẹn và hồ sơ bệnh nhân một cách dễ dàng và chính xác.',
}
