import { CAMPAIGN_MESSAGE_TYPE, CAMPAIGN_TYPE, CHANNEL_CODE, MESSAGE_ZALO_COMMAND } from "../../constants";
import { SmsCampaign, SmsCampaignDetailRequest } from "../../dataType/Campaign";
import { SmsTemplate } from "../../dataType/smsMessage";
import { convertTemplateContent } from "../../ultils/Campaign";
import { CREATE_CAMPAIGN, UPDATE_CAMPAIGN_DETAIL, LOG_OUT, RESET_CAMPAIGN, CREATE_CAMPAIGN_DETAIL } from "../types";

export type InitStateCampaign = {
  campaign_info: SmsCampaign,
  campaign_detail: SmsCampaignDetailRequest
}

export const initStateCampaign: InitStateCampaign = {
  campaign_info: {
    campaign_id: 0,
    campaign_name: '',
    campaign_code: '',
    campaign_main_type: CAMPAIGN_TYPE.PROACTIVE,
    campaign_type: CAMPAIGN_TYPE.PROACTIVE,
    channels: '',
    note: '',
    trigger_condition: '',
    time: '',
    status: 0,
    created_date: null,
    updated_date: null,
    updated_user: '',
    active_flg: 0,
    sms_campaign_details: []
  },
  campaign_detail: {
    campaign_id: 0,
    channel_code: CHANNEL_CODE.SMS,
    list_customer_group_cd: [],
    limit_customer: 0,
    template_id: 0,
    template_content: '',
    message_content: '',
    schedule_time: '',
    price_expected: 0,
    settings: '',
    updated_user: '',
    access_token: '',
    message_type: CAMPAIGN_MESSAGE_TYPE.CSKH,
    list_params: [],
    list_params_sms: []
  }
}

export default function campaign(state = initStateCampaign, action: any) {
  switch (action.type) {
    case CREATE_CAMPAIGN:
      return { ...state, campaign_info: action.payload }
    case CREATE_CAMPAIGN_DETAIL: {
      const campaignInfo = action.payload
      const campaignDetail = state.campaign_detail;
      campaignDetail.campaign_id = campaignInfo.campaign_id
      campaignDetail.channel_code = action.channelCode
      campaignDetail.template_id = 0
      campaignDetail.template_content = ''
      campaignDetail.message_content = ''
      campaignDetail.message_type = CAMPAIGN_MESSAGE_TYPE.CSKH
      let newSettings: Object
      if (action.channelCode === CHANNEL_CODE.ZALO_OA) {
        campaignDetail.message_type = CAMPAIGN_MESSAGE_TYPE.CSKH
        newSettings = {
          zalo_oa_name: '',
          zalo_oa_id: '',
          zalo_command: MESSAGE_ZALO_COMMAND.NORMAL,
          template_sms_id: 0,
          template_content_sms: '',
          message_content_sms: ''
        }
      } else {
        campaignDetail.message_type = CAMPAIGN_MESSAGE_TYPE.QC
        newSettings = {
          brand_name: '',
        }
      }
      campaignDetail.settings = newSettings
      switch (campaignInfo.campaign_type) {
        case CAMPAIGN_TYPE.INVOICE_SUCCESS: {
          const listTemplate: SmsTemplate[] = action.listTemplate
          const template = listTemplate.find(item => item.template_type === CAMPAIGN_TYPE.INVOICE_SUCCESS && item.channel_code === action.channelCode)
          if (!template) break;
          campaignDetail.message_type = CAMPAIGN_MESSAGE_TYPE.CSKH
          campaignDetail.template_id = template.template_id
          campaignDetail.template_content = template.template_content
          campaignDetail.message_content = template.template_content
          campaignDetail.list_params = convertTemplateContent(template.template_content)
          break;
        }
        default:
          break;
      }
      return { ...state, campaign_detail: campaignDetail }
    }
    case UPDATE_CAMPAIGN_DETAIL:
      return { ...state, campaign_detail: action.payload }
    case RESET_CAMPAIGN: {
      return JSON.parse(JSON.stringify(initStateCampaign))
    }
    case LOG_OUT: {
      return JSON.parse(JSON.stringify(initStateCampaign))
    }
    default:
      return state
  }
}