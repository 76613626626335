import client from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants/";
import { NewProviderRequest, UpdateProviderRequest } from "../dataType/Provider.d";
export default {
  getAllProvider(data: {
    page: number;
    per_page?: number;
    phone?: string;
    provider_name?: string;
    date_from?: string;
    date_to?: string
  }) {
    let page = data.page ? data.page : PAGE_DEFAULT;
    let per_page = data.per_page ? data.per_page : LIMIT;
    const url = `/pharmacy/provider/search?page=${page}&size=${per_page}`;
    return client
      .post(url, data)
      .then(response => {
        return response;
      });
  },
  createProvider(token: string, dataNewProvider: NewProviderRequest) {
    const url = `/pharmacy/provider`;
    return client
      .post(url, dataNewProvider, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  updateProvider(token: string, data: UpdateProviderRequest) {
    const url = `/pharmacy/provider`;
    return client
      .put(url, data, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },

  async getProviderByName(page: number, size: number, providerName?: string) {
    const params = {
      phone: '',
      provider_name: providerName ? providerName : '',
      date_from: '',
      date_to: ''
    }
    const url = `/pharmacy/provider/search?page=${page}&size=${size}`;
    const res = await client.post(url, params)
    return res.data
  },
  async checkProviderLinked(refCompanyId: number) {
    const url = `/pharmacy/provider/${refCompanyId}`;
    return client.get(url);
  },

  async getInfoProvider(id: number) {
    const url = `/pharmacy/provider/detail/${id}`;
    return client.get(url);
  }
};
