import { RESET_TABLE_CREATE_INVOICE_NEW } from './../types/index';
import { UPDATE_TABLE_INVOICE, UPDATE_TABLE_PRODUCT_PHARMACY, UPDATE_TABLE_CREATE_INVOICE_NEW, RESET_TABLE_INVOICE, RESET_TABLE_PRODUCT_PHARMACY } from "../types"

export interface ListColumn {
  tableInvoice: ColumnSetting[]
  tableProductPharmacy: ColumnSetting[]
  tableInvoiceCreateNew: ColumnSetting[]
}

export interface ColumnSetting {
  key: string,
  title: string,
  isShow: boolean,
  editable: boolean,
}

const initTableInvoice = [
  {
    key: 'invoice_code',
    title: 'Mã hóa đơn',
    isShow: true,
    editable: false,
  },
  {
    key: 'customer_code',
    title: 'Mã KH',
    isShow: true,
    editable: false,
  },
  {
    key: 'customer_name',
    title: 'Tên khách hàng',
    isShow: true,
    editable: false,
  },
  {
    key: 'customer_phone_no',
    title: 'Số điện thoại',
    isShow: true,
    editable: false,
  },
  {
    key: 'pay_method',
    title: 'Thanh toán',
    isShow: true,
    editable: false,
  },
  {
    key: 'ecoin_plus',
    title: 'Tích điểm',
    isShow: true,
    editable: false,
  },
  {
    key: 'discount_amount',
    title: 'Tổng giảm',
    isShow: true,
    editable: false,
  },
  {
    key: 'amount',
    title: 'Thành tiền',
    isShow: true,
    editable: false,
  },
  {
    key: 'invoice_type',
    title: 'Loại đơn',
    isShow: true,
    editable: true,
  },
  {
    key: 'created_date',
    title: 'Ngày tạo đơn',
    isShow: true,
    editable: false,
  },
  {
    key: 'status',
    title: 'Trạng thái',
    isShow: true,
    editable: true,
  },
  {
    key: 'order_status',
    title: 'Trạng thái đơn online',
    isShow: true,
    editable: true,
  },
  {
    key: 'ecoin_minus',
    title: 'Điểm sử dụng',
    isShow: false,
    editable: true,
  },
  {
    key: 'drg_store_id',
    title: 'Mã chi nhánh',
    isShow: false,
    editable: true,
  },
  {
    key: 'account_id',
    title: 'Người tạo',
    isShow: false,
    editable: true,
  },
  {
    key: 'amount_debt',
    title: 'Nợ cũ',
    isShow: false,
    editable: true,
  }
]

const initTableProductPharmacy = [
  {
    key: 'image_url',
    title: 'Hình ảnh',
    isShow: true,
    editable: false,
  },
  {
    key: 'drg_drug_cd',
    title: 'Mã sản phẩm',
    isShow: true,
    editable: false,
  },
  {
    key: 'drg_drug_name',
    title: 'Tên sản phẩm',
    isShow: true,
    editable: false,
  },
  {
    key: 'license_cd',
    title: 'Số đăng ký',
    isShow: true,
    editable: false,
  },
  {
    key: 'active_ingredient',
    title: 'Hoạt chất',
    isShow: true,
    editable: false,
  },
  {
    key: 'company_name',
    title: 'Nhà SX',
    isShow: true,
    editable: false,
  },
  {
    key: 'status',
    title: 'Trạng thái',
    isShow: true,
    editable: false,
  },
  {
    key: 'updated_date',
    title: 'Cập nhật',
    isShow: true,
    editable: false,
  },
  {
    key: 'account_id',
    title: 'Người tạo',
    isShow: false,
    editable: true,
  },
  {
    key: 'drg_barcode',
    title: 'Mã vạch',
    isShow: false,
    editable: true,
  },
  {
    key: 'drg_ref_cd',
    title: 'Mã dược quốc gia',
    isShow: false,
    editable: true,
  },
  {
    key: 'drug_group',
    title: 'Nhóm thuốc',
    isShow: false,
    editable: true,
  },
  {
    key: 'employee_commissions',
    title: 'Điểm thưởng NV',
    isShow: false,
    editable: true,
  },
  {
    key: 'loyalty_flg',
    title: 'Tích điểm',
    isShow: false,
    editable: true,
  },
  {
    key: 'dosage',
    title: 'Liều dùng',
    isShow: false,
    editable: true,
  },
  {
    key: 'vat_percent',
    title: 'Thuế',
    isShow: false,
    editable: true,
  },
  {
    key: 'provider_name',
    title: 'Nhà cung cấp',
    isShow: false,
    editable: true,
  },
  {
    key: 'package_desc',
    title: 'Quy cách đóng gói',
    isShow: false,
    editable: true,
  },
]

const initTableInvoiceCreateNew = [
  {
    key: '',
    title: '',
    isShow: true,
    editable: false,
  },
  {
    key: 'promotion_flg',
    title: 'KM',
    isShow: true,
    editable: false,
  },
  {
    key: 'drg_drug_name',
    title: 'Tên sản phẩm',
    isShow: true,
    editable: false,
  },
  {
    key: 'lot',
    title: 'Số lô',
    isShow: true,
    editable: false,
  },
  {
    key: 'dosage',
    title: 'Liều dùng',
    isShow: true,
    editable: true,
  },
  {
    key: 'inv_qty',
    title: 'Tồn',
    isShow: true,
    editable: true,
  },
  {
    key: 'units',
    title: 'Đơn vị',
    isShow: true,
    editable: false,
  },
  {
    key: 'price',
    title: 'Đơn giá',
    isShow: true,
    editable: false,
  },
  {
    key: 'quantity',
    title: 'Số lượng',
    isShow: true,
    editable: false,
  },
  {
    key: 'discount_unit',
    title: 'Giảm giá / SP',
    isShow: true,
    editable: false,
  },
  {
    key: 'amount',
    title: 'Tổng tiền',
    isShow: true,
    editable: false,
  },
  {
    key: 'key',
    title: '',
    isShow: true,
    editable: false,
  }
]
const initListColumn: ListColumn = {
  tableInvoice: JSON.parse(JSON.stringify(initTableInvoice)),
  tableProductPharmacy: JSON.parse(JSON.stringify(initTableProductPharmacy)),
  tableInvoiceCreateNew: JSON.parse(JSON.stringify(initTableInvoiceCreateNew))
}

export default function settingUIDisplay(state = initListColumn, action: any) {
  switch (action.type) {
    case UPDATE_TABLE_INVOICE:
      return { ...state, tableInvoice: action.payload }
    case UPDATE_TABLE_PRODUCT_PHARMACY:
      return { ...state, tableProductPharmacy: action.payload }
    case UPDATE_TABLE_CREATE_INVOICE_NEW:
      return { ...state, tableInvoiceCreateNew: action.payload }
    case RESET_TABLE_INVOICE:
      return { ...state, tableInvoice: JSON.parse(JSON.stringify(initTableInvoice)) }
    case RESET_TABLE_CREATE_INVOICE_NEW:
      return { ...state, tableInvoiceCreateNew: JSON.parse(JSON.stringify(initTableInvoiceCreateNew)) }
    case RESET_TABLE_PRODUCT_PHARMACY:
      return { ...state, tableProductPharmacy: JSON.parse(JSON.stringify(initTableProductPharmacy)) }
    default:
      return state
  }
}