import React, { Component } from "react";
import { IC_LOCATION, IC_MOBILE, IC_EMAIL } from "../../assets";
import "./footer.scss"

const list = [
  {
    icon: IC_LOCATION,
    text:
      "P.305 - 306, Tầng 3, TTTM tòa 48B Keangnam, Đường Phạm Hùng, Phường Mễ Trì, Quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam"
  },
  {
    icon: IC_MOBILE,
    text: "Hotline: 0969 191 355"
  },
  {
    icon: IC_EMAIL,
    text: "Email: hotro@medlink.vn"
  }
];

const list2 = [
  {
    title: "Giới thiệu",
    data: [
      {
        text: "Về chúng tôi",
        url: "https://medlink.vn/"
      },
      {
        text: "Tin tức",
        url: "https://medlink.vn/blog"
      },
      {
        text: "Liên hệ ",
        url: "https://medlink.vn/contact-us"
      }
    ]
  },
  {
    title: "Sản phẩm",
    data: [
      {
        text: "Phần mềm quản lý nhà thuốc",
        url: "https://medlink.vn/pharmacy"
      },
      {
        text: "Phần mềm quản lý công ty dược",
        url: "https://medlink.vn/company"
      },
      {
        text: "Phần mềm quản lý phòng khám",
        url: "https://clinic.medlink.vn/"
      }
    ]
  },
  {
    title: "Điều khoản",
    data: [
      {
        text: "Chính sách sử dụng",
        url: "https://medlink.vn/policy"
      },
      {
        text: "Giải đáp, tư vấn sản phẩm",
        url: "https://medlink.vn/contact-us"
      },
      {
        text: "Chính sách bán hàng",
        url: "https://medlink.vn/policy"
      }
    ]
  }
];

type Props = {

}

type State = {

}

class Footer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6 info">
              <div className="title-1">
                {"CÔNG TY CỔ PHẦN ĐẦU TƯ MEDLINK"}
              </div>
              {list.map((e, i) => (
                <div className="d-flex line" key={i}>
                  <div>
                    <img alt="icon footer" src={e.icon} />
                  </div>
                  <div className="common-text-medium">{e.text}</div>
                </div>
              ))}
            </div>
            <div className="col-md-6 links">
              <div className="row">
                {list2.map((e, i) => (
                  <div className="col-6 col-md-4" key={i}>
                    <div className="title-2 common-text-medium">
                      {e.title}
                    </div>
                    {e.data.map((e, i) => (
                      <div key={i} className="common-text-medium item" onClick={() => window.open(e.url)}>
                        {e.text}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
