import client from "./apiConfig";

export default {
  async getPresignedURL(prefix: string) {
    const url = `/pharmacy/media/presigned-url?prefix=${prefix}`;
    const response = await client.get(url);
    return response.data;
  },

  async uploadFile(presignedUrl: string, data: any, type: string) {
    let binary = atob(data.split(',')[1]);
    let array = []
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
    }
    let blobData = new Blob([new Uint8Array(array)], { type: type })
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: blobData
    });
    return response
  },

  async deleteImage(keyName: string) {
    const url = `/pharmacy/media/delete?keyName=${keyName}`;
    const response = await client.delete(url);
    return response.data;
  },
}