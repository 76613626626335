import client from "./apiConfig";

export default {
  getRoleTemplate() {
    const url = `/oauth/permission`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },

  updateRolePermission(params: any) {
    const url = `/oauth/permission`;
    return client
      .put(url, params)
      .then(response => {
        return response;
      });
  }
}