import client from "./apiConfig";
type paramNewUser = {
  email: string;
  phone_no: string;
  password?: string;
  role_cd?: string;
  full_name: string;
  drg_store_id?: number;
  status: number;
};
interface updateUserParam extends paramNewUser {
  active_flg: number;
  avatar_url: string;
  facebook_id: string;
  group_cd: string | null
}
export default {
  getUserInfo(token?: string) {
    const url = `/oauth/account/info`;
    const axiosConfig = {
      setToken: token ? true : false,
      headers: {
        Authorization: "Bearer " + token
      }
    }
    return client
      .get(url, axiosConfig)
      .then(response => {
        return response;
      });
  },
  getAllUserByAdmin(active_flg: number, status: number) {
    const url = `/oauth/account/all?active_flg=${active_flg}&status=${status}`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },
  createNewUser(token: string, dataNewUser: paramNewUser) {
    const url = `/oauth/account`;
    return client
      .post(url, dataNewUser, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  updateUserByAdmin(token: string, updateUser: any) {
    const url = `/oauth/account`;
    return client
      .put(url, updateUser, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  createAccToken(token: string, data: {
    device_token: string,
    device_name: string,
    platform: string,
    mac_address: string
  }) {
    const url = `/pharmacy/user/device_token`;
    return client
      .post(url, data, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  updateAccToken(token: string, data: {
    active_flg: number,
    mac_address: string,
    device_token: string | null
  }) {
    const url = `/pharmacy/user/device_token`;
    return client
      .put(url, data, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  getAddress: async (address: string, API_KEY_MAP: string) => {
    let response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + API_KEY_MAP)
    let json = await response.json();
    const value = json.results[0].geometry.location
    const location = {
      latitude: value.lat,
      longitude: value.lng
    }
    return location
  },

  getListUserGroup: async () => {
    const url = `/pharmacy/user/group`
    const res = await client.get(url)
    return res
  }
}
