import {
  KIND_DRUG,
  UNIT_TYPE,
  GROUP_DRUG,
  DESCRIPTION,
  SAVE_USER_INFO,
  GET_INFO_USER,
  ADD_LIST_DRUG_PHARMACY,
  TYPE_IMPORT,
  PAYMENT_METHOD,
  TYPE_EXPORT,
  GROUP_CUSTOMER,
  REMOVE_DRUG_PHARMACY,
  GET_ALL_STORE_PHARMACY,
  GET_ALL_INITIAL_DATA,
  GET_LIST_USER,
  REQUEST_UPDATE_PRODUCT_SORT,
  ADD_LIST_CASHFLOW_TYPE,
  ADD_LIST_USER_GROUP,
  ADD_LIST_CASHFLOW_PARTNER_TYPE,
  ADD_CURRENT_VERSION,
  TRANSFER_BRANCH,
  REQUEST_UPDATED_LIST_USER,
  SAVE_INVOICE_SOURCE,
  UPDATE_LIST_PRODUCT_PHARMACY,
  SAVE_INFO_COMPANY,
  SAVE_INFO_BRAND_NAME,
  SAVE_INFO_ZALO_OA,
  SAVE_STORE_LICENSE,
  SAVE_STORE_PACKAGE,
  GET_LIST_PACKAGE,
  GET_LIST_CAMPAGIN,
  SAVE_COMPANY_TYPE,
  SAVE_COMPANY_LOYALTY,
  UPDATE_SHOW_BANNER,
} from "../types";
import {
  DrugKind,
  UnitStandard,
  DrugGroup,
  DrgUpdatePrice,
} from "../../dataType/Product";
import { DrgStore, DrgStoreLicense, DrgStorePackage } from "../../dataType/Store";
import { DrgAccount, CashflowPartnerType, UserGroup } from "../../dataType/User.d";
import { ProductSort } from "../../dataType/Product.d";
import { ImportType, InvoiceSource, PaymentMethod } from "../../dataType/Import.d";
import { CustomerGroup } from "../../dataType/Customer.d";
import { Properties } from "../../dataType/Properties";
import { CashflowType } from "../../dataType/Cashflow";
import { SmsBrandName, SmsCompany, SmsZaloOa } from "../../dataType/SmsCompany";
import { SmsCampaignType } from "../../dataType/smsCampaign";
import { CompanyInfoResponse } from "../../dataType/Company";
import { LoyaltyCompany } from "../../dataType/loyalty";
import { DrgEmployeeType } from "../../dataType/Employee";
export const saveDrugKind = (data: DrugKind[]) => ({
  type: KIND_DRUG,
  payload: data
});
export const saveUnitsStandard = (data: UnitStandard[]) => ({
  type: UNIT_TYPE,
  payload: data
});
export const saveDrugGroup = (data: DrugGroup[]) => ({
  type: GROUP_DRUG,
  payload: data
});
export const saveImportTypes = (data: ImportType[]) => ({
  type: TYPE_IMPORT,
  payload: data
});
export const saveExportType = (data: Properties[]) => ({
  type: TYPE_EXPORT,
  payload: data
});
export const savePaymentMethods = (data: PaymentMethod[]) => ({
  type: PAYMENT_METHOD,
  payload: data
});
export const saveDrugDescriptions = (data: Properties[]) => ({
  type: DESCRIPTION,
  payload: data
});
export const saveInfoStore = (data: DrgStore) => ({
  type: SAVE_USER_INFO,
  payload: data
});
export const saveInfoUser = (data: DrgAccount) => ({
  type: GET_INFO_USER,
  payload: data
});
export const saveListCampaginType = (data: SmsCampaignType[]) => ({
  type: GET_LIST_CAMPAGIN,
  payload: data
});
export const saveLicense = (data: DrgStoreLicense) => ({
  type: SAVE_STORE_LICENSE,
  payload: data
});
export const savePackage = (data: DrgStorePackage[]) => ({
  type: SAVE_STORE_PACKAGE,
  payload: data
});
export const getListPackage = () => ({
  type: GET_LIST_PACKAGE
});
export const remove_drug_pharmacy = () => ({
  type: REMOVE_DRUG_PHARMACY
});

export const saveListDrugPharmacy = (data: ProductSort[]) => ({
  type: ADD_LIST_DRUG_PHARMACY,
  payload: data
});

export const saveCustomerGroups = (data: CustomerGroup) => ({
  type: GROUP_CUSTOMER,
  payload: data
});
export const saveAllStoreChainPharmacies = (data: DrgStore[]) => ({
  type: GET_ALL_STORE_PHARMACY,
  payload: data
});

export const getInitialData = () => {
  return {
    type: GET_ALL_INITIAL_DATA,
  }
}

export const saveListUser = (data: DrgEmployeeType[]) => {
  return {
    type: GET_LIST_USER,
    payload: data
  }
}

export const requestUpdateProduct = () => {
  return {
    type: REQUEST_UPDATE_PRODUCT_SORT,
  }
}

export const transferBranch = () => {
  return {
    type: TRANSFER_BRANCH,
  }
}

export const saveListCashflowType = (data: CashflowType[]) => {
  return {
    type: ADD_LIST_CASHFLOW_TYPE,
    payload: data
  }
}

export const saveListUserGroup = (data: UserGroup) => {
  return {
    type: ADD_LIST_USER_GROUP,
    payload: data
  }
}

export const saveListCashflowPartnerType = (data: CashflowPartnerType[]) => {
  return {
    payload: data,
    type: ADD_LIST_CASHFLOW_PARTNER_TYPE
  }
}

export const saveVersion = (data: string) => {
  return {
    payload: data,
    type: ADD_CURRENT_VERSION
  }
}

export const requestUpdateListUser = () => {
  return {
    type: REQUEST_UPDATED_LIST_USER
  }
}
export const updateListProductPharmacy = (data: DrgUpdatePrice[]) => {
  return {
    type: UPDATE_LIST_PRODUCT_PHARMACY,
    payload: data
  }
}
export const saveInvoiceSource = (data: InvoiceSource[]) => {
  return {
    payload: data,
    type: SAVE_INVOICE_SOURCE
  }
}
export const saveCompanyInfo = (data: SmsCompany) => {
  return {
    payload: data,
    type: SAVE_INFO_COMPANY
  }
}
export const saveBrandNameInfo = (data: SmsBrandName[]) => {
  return {
    payload: data,
    type: SAVE_INFO_BRAND_NAME
  }
}
export const saveZaloOaInfo = (data: SmsZaloOa[]) => {
  return {
    payload: data,
    type: SAVE_INFO_ZALO_OA
  }
}
export const saveCompanyType = (data: CompanyInfoResponse) => {
  return {
    type: SAVE_COMPANY_TYPE,
    payload: data
  }
}

export const saveCompanyLoyalty = (data: LoyaltyCompany) => ({
  type: SAVE_COMPANY_LOYALTY,
  payload: data
})

export const updateShowBanner = (data: string) => ({
  type: UPDATE_SHOW_BANNER,
  payload: data
})