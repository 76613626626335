import {
  LOG_OUT,
  UPDATE_COMBO,
  RESET_COMBO,
  TRANSFER_BRANCH
} from "../types";
import { COMBO_TYPE } from "../../constants";
import { ComboRequest } from "../../dataType/Combo";
import moment from "moment";

export type InitStateCombo = ComboRequest

const initialState: InitStateCombo = {
  combo_code: '',
  combo_type: COMBO_TYPE[0].code,
  combo_name: '',
  combo_description: '',
  combo_url: '',
  price: 0,
  discount: 0,
  start_date: moment().format(),
  end_date: '',
  combo_detail_requests: [],
};

export default function combo(state = JSON.parse(JSON.stringify(initialState)), action: any) {

  switch (action.type) {

    case UPDATE_COMBO: {
      return { ...action.payload };
    }
    case RESET_COMBO: {
      const stateReset = JSON.parse(JSON.stringify(initialState))
      return { ...stateReset };
    }

    case LOG_OUT: {
      return {
        ...initialState
      };
    }

    case TRANSFER_BRANCH: {
      return {
        ...initialState
      };
    }
    
    default:
      return state;
  }
}




