import {PromotionNewCreateModel, PromotionSearchModel} from "../dataType/PromotionNew";
import client from "./apiConfig";

export default {
  async getAllPromotion(data: PromotionSearchModel) {
    const url = `/loyalty/new-promotion`;
    return await client.get(url, {
      params: data,
    })
  },
  async createNewPromotion(data: PromotionNewCreateModel) {
    const url = `/loyalty/new-promotion`;
    return await client.post(url, data);
  },
  async deletePromotion(promotion_code: string) {
    const url = `/loyalty/new-promotion/${promotion_code}`;
    return await client.delete(url);
  },
  async updatePromotion(promotion_code: string, data: PromotionNewCreateModel) {
    const url = `/loyalty/new-promotion/${promotion_code}`;
    return await client.put(url, data);
  },
}
