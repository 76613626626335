import {
  ADD_DRUG_CREATE,
  DELETED_DRUG_CREATE,
  UPDATE_DRUG_CREATE,
  REMOVE_DRUG_CREATE,
  LOG_OUT,
  ARRAY_DRUG_ERROR,
  REMOVE_DRUG_ERROR,
  ADD_DRUG_UPDATE_EXCEL,
  ARRAY_DRUG_UPDATE_ERROR,
  DELETED_DRUG_UPDATE_CREATE,
  UPDATE_DRUG_EXCEL_CREATE,
  REMOVE_DRUG_UPDATE_CREATE,
  REMOVE_DRUG_UPDATE_ERROR,
  UPDATE_DRUG_PRINT,
  TRANSFER_BRANCH,
  CHANGE_STORE_UPDATE_PRICE
} from "../types";
import { DrgNewDrug, DrgDrugError } from "../../dataType/Product.d";
import { DrgInvPrint } from "../../dataType/Inventory";

export type InitStateProduct = {
  listNewProducts: DrgNewDrug[];
  listDrugsError: DrgDrugError[];
  listProductUpdate: DrgNewDrug[];
  listDrugsUpdateError: DrgDrugError[];
  listDrugsPrint: DrgInvPrint[];
  listStoreUpdatePrice: number[]
};

type actionType = {
  type: string;
  payload: any;
};

const initialState: InitStateProduct = {
  listNewProducts: [],
  listDrugsError: [],
  listProductUpdate: [],
  listDrugsUpdateError: [],
  listDrugsPrint:[],
  listStoreUpdatePrice: [],
};
export default function product(state = initialState, action: actionType) {
  const { type } = action;
  switch (type) {
    case ADD_DRUG_CREATE:
      const data = state.listNewProducts.concat(action.payload);
      return { ...state, listNewProducts: data };
    case ARRAY_DRUG_ERROR:
      return { ...state, listDrugsError: action.payload };
    case DELETED_DRUG_CREATE: {
      return {
        ...state,
        listNewProducts: state.listNewProducts.filter(
          item => item !== action.payload
        )
      };
    }
    case UPDATE_DRUG_CREATE: {
      return {
        ...state,
        listNewProducts: [...state.listNewProducts]
      };
    }
    case REMOVE_DRUG_CREATE: {
      return {
        ...state,
        listNewProducts: []
      };
    }
    case REMOVE_DRUG_ERROR: {
      return {
        ...state,
        listDrugsError: []
      };
    }
    // reduce update drug excel
    case ADD_DRUG_UPDATE_EXCEL:
      const dataUpdate = state.listProductUpdate.concat(action.payload);
      return { ...state, listProductUpdate: dataUpdate };
    case ARRAY_DRUG_UPDATE_ERROR:
      return { ...state, listDrugsUpdateError: action.payload };
    case DELETED_DRUG_UPDATE_CREATE: {
      return {
        ...state,
        listProductUpdate: state.listProductUpdate.filter(
          item => item !== action.payload
        )
      };
    }
    case UPDATE_DRUG_EXCEL_CREATE: {
      return {
        ...state,
        listProductUpdate: [...state.listProductUpdate]
      };
    }
    case REMOVE_DRUG_UPDATE_CREATE: {
      return {
        ...state,
        listProductUpdate: []
      };
    }
    case REMOVE_DRUG_UPDATE_ERROR: {
      return {
        ...state,
        listDrugsUpdateError: []
      };
    }

    case LOG_OUT: {
      return {
        ...initialState
      };
    }

    case TRANSFER_BRANCH: {
      return {
        ...initialState
      };
    }
    
    case UPDATE_DRUG_PRINT: {
      return {
        ...state,
        listDrugsPrint: action.payload,
      }
    }

    case CHANGE_STORE_UPDATE_PRICE : {
      return {
        ...state,
        listStoreUpdatePrice: action.payload,
      }
    }
    
    default:
      return state;
  }
}
