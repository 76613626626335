
import {
  LOGIN, LOG_OUT, ADD_DEVICE_TOKEN, SAVE_LOCATION, SAVE_TOKEN, LOGIN_REQUEST,
  LOGIN_FAIL, RESET_MESSAGE_LOGIN, EXPIRED_LICENSE, UPDATE_REQUEST_LOGIN, UPDATE_USER_INFO, REFRESH_TOKEN, UPDATE_FISRT_LOGIN
} from '../types';
import { LocationStore, UserLogin } from '../reducers/auth';
import { LoginRequest } from '../../dataType/auth';

 export type RequestLoginParam = {
  user: LoginRequest
  moreInfor: MoreInforParam
}

type MoreInforParam = {
  history: any,
  remember: boolean,
  loading: boolean,
}

type ResponseLoginParam = {
  access_token: string,
  token_type: string,
  refresh_token: string,
  expires_in: number,
  scope: string,
  login_id: string,
  phone_no: string,
  account_id: number,
  company_id: number,
  user_name: string,
  drg_store_id: number,
  role_cd: string,
  jti: string,
  userInfo: UserLogin | null
}

export const loginSuccess = (payload: ResponseLoginParam) => ({
    type: LOGIN,
    payload
  })

export const requestLogin = (params: RequestLoginParam) => {
  return {
    type: LOGIN_REQUEST,
    payload: params
  }
}

export const saveToken = (token: string) => {
  return {
    type: SAVE_TOKEN,
    token
  }
}
export const saveLocationOfStore = (location: LocationStore) => {
  return {
    type: SAVE_LOCATION,
    location
  }
}
export const logout = () => ({
  type: LOG_OUT
})

export const updateDeviceToken = (deviceToken: string) => {
  return {
    type: ADD_DEVICE_TOKEN,
    deviceToken,
  }
}

export const loginFail = (message: string) => {
  return {
    type: LOGIN_FAIL,
    payLoad: message
  }
}

export const changeDataLogin = () => {
  return {
    type: RESET_MESSAGE_LOGIN
  }
}

export const updateExpiredLicense = (expiredLicense: boolean) => {
  return {
    type: EXPIRED_LICENSE,
    payload: expiredLicense
  }
}

export const updateRequestLogin = (status: boolean) => {
  return {
    type: UPDATE_REQUEST_LOGIN,
    payload: status
  }
}

export const updateUserInfo = (userInfo: UserLogin) => {
  return {
    type: UPDATE_USER_INFO,
    userInfo,
  }
}

export const refreshToken = () => ({
  type: REFRESH_TOKEN,
})

export const updateFisrtLogin= (isFisrtLogin: boolean) => ({
  type: UPDATE_FISRT_LOGIN,
  payload: isFisrtLogin
})