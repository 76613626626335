import React, { Component } from "react";
import { LOGO, ICON_MENU, ICON_CLOSE } from "../../assets";
import { Link } from "react-router-dom";
import "./auth.scss"

type MenuItem = {
  title: string;
  type: string;
  onPress: any;
  icon: string;
  href: string;
}
type Props = {
  listMenu: MenuItem[];
}
type State = {
  openMenu: boolean,
}

export default class NavAuth extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openMenu: false
    }
  }

  toggleMenu = () => {
    const { openMenu } = this.state
    this.setState({ openMenu: !openMenu })
  }

  toggleMenuInPage = (item: MenuItem) => {
    this.toggleMenu()
    item.onPress();
  }

  renderMenuNav = () => {
    const { listMenu } = this.props;
    return (
      <div className={`menu-nav d-md-block d-lg-none ${this.state.openMenu && "open"}`}>
        <div className="menu-item">
          <p className="text">Menu</p>
          <img alt="icon close"
            className="icon-close"
            src={ICON_CLOSE}
            onClick={this.toggleMenu}
          />
        </div>
        {listMenu.map((item: MenuItem, index: number) => {
          if (item.type === "_blank") {
            return (
              <a href={item.href} target={"_blank"} rel="noopener noreferrer" className="menu-item" onClick={this.toggleMenu} key={index}>
                <p className="text">{item.title}</p>
              </a>
            )
          } else if (item.type === "link") {
            return (
              <Link to={item.href} className="menu-item" onClick={this.toggleMenu} key={index}>
                <p className="text">{item.title}</p>
              </Link>
            )
          } else {
            return (
              <div className="menu-item" onClick={() => this.toggleMenuInPage(item)} key={index}>
                <p className="text">{item.title}</p>
              </div>
            )
          }
        })}
      </div>
    );
  };

  render() {
    const { listMenu } = this.props;
    return (
      <nav className="nav-auth">
        <Link to={""} className="text-item">
          <img alt="logo medlink" src={LOGO} className="logo" />
        </Link>
        <div className="info">
          {listMenu.map((item: MenuItem, index: number) => {
            if (item.type === "_blank") {
              return (
                <a href={item.href} target={"_blank"} className="text-item" key={index} rel="noopener noreferrer">
                  <p className="text-item">{item.title}</p>
                </a>
              )
            } else if (item.type === "link-news") {
              return (
                <a href={item.href} className="text-item" rel="noopener noreferrer" target="_blank" key={index}>
                  <p className="text-item">{item.title}</p>
                </a>
              )
            } else if (item.type === "link") {
              return (
                <Link to={item.href} className="text-item" key={index}>
                  <p className="text-item">{item.title}</p>
                </Link>
              )
            } else {
              return (
                <div className="text-item" onClick={item.onPress} key={index}>
                  <p className="text-item">{item.title}</p>
                </div>
              )
            }
          })}
        </div>
        <img alt="icon menu" src={ICON_MENU} className="menu" onClick={this.toggleMenu} />
        {this.renderMenuNav()}
      </nav>
    )
  }
}