import { CAMPAIGN_MESSAGE_TYPE, CHANNEL_CODE, LENGTH_CONTENT_LANGUAGE, PRICE_CAMPAIGN_MESSAGE } from "../../constants"
import { SmsTemplateParam } from "../../dataType/smsMessage"

export const calculatorPriceExpectedCampaign = (content: string, channelCode: string, messageType: string, numCustomer: number) => {
  const contentLength = content.length
  switch (channelCode) {
    case CHANNEL_CODE.SMS: {
      switch (messageType) {
        case CAMPAIGN_MESSAGE_TYPE.QC: {
          const lengthMessage = content.normalize('NFD') === content ? LENGTH_CONTENT_LANGUAGE.ENGLISH : LENGTH_CONTENT_LANGUAGE.VIETNAMES
          const numberMessage = Math.ceil(contentLength / lengthMessage)
          return numberMessage * PRICE_CAMPAIGN_MESSAGE.SMS_QC * numCustomer
        }
        case CAMPAIGN_MESSAGE_TYPE.CSKH: {
          // const lengthMessage = content.normalize('NFD') === content ? LENGTH_CONTENT_LANGUAGE.ENGLISH : LENGTH_CONTENT_LANGUAGE.VIETNAMES
          // const numberMessage = Math.ceil(contentLength / lengthMessage)
          // return numberMessage * PRICE_CAMPAIGN_MESSAGE.SMS_CSKH * numCustomer
          return PRICE_CAMPAIGN_MESSAGE.SMS_CSKH
        }
        default: return 0
      }
    }
    case CHANNEL_CODE.ZALO_OA: {
      return PRICE_CAMPAIGN_MESSAGE.ZALO_CSKH
    }
    default:
      return 0
  }
}

export const convertTemplateContent = (templateContent: string) => {
  const regContent = /^.*\{{2}\w+\}{2}.*$/g
  if (!regContent.test(templateContent)) return []
  const regParam = /\{{2}\w+\}{2}/g
  const params = templateContent.match(regParam)
  if (!params) return []
  const newListParams: SmsTemplateParam[] = []
  params.forEach(content => {
    const param: SmsTemplateParam = {
      name: content.replace("{{", "").replace("}}", ""),
      value: ''
    }
    if (!newListParams.find(item => item.name === param.name)) {
      newListParams.push(param)
    }
  })
  return newListParams
}