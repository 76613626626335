import client from "./apiConfig";

export default {
	getSmsWallet() {
		const url = `sms/wallet`;
		return client.get(url);
	},
	createSmsRecharge(param: number) {
		const url = `sms/wallet/recharge?amount=${param}`;
		return client.get(url);
	}
}