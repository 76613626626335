import { ADD_CART, UPDATE_CART, CLEAR_ORDER, ACTION_CART_ADD_PRODUCT, UPDATE_PRODUCT_TO_CART, ADD_PRODUCT_TO_CART, ADD_INVOICE_TO_CART } from '../types/'
import { OrderSupplierSelected } from '../../dataType/SupplierOrder'
import { SupplierProductData } from '../../dataType/Product'
export const addProductToCart = (data: OrderSupplierSelected) => ({
  type: ADD_CART,
  payload: data
})

export const addProduct = (data: { product: SupplierProductData, quantity: number }) => ({
  type: ACTION_CART_ADD_PRODUCT,
  payload: data,
})

export const updateProductCart = (data: OrderSupplierSelected[]) => ({
  type: UPDATE_PRODUCT_TO_CART,
  payload: data
})
export const addProductCart = (data: OrderSupplierSelected[]) => ({
  type: ADD_PRODUCT_TO_CART,
  payload: data
})

export const addInvoiceCart = (data: OrderSupplierSelected[]) => ({
  type: ADD_INVOICE_TO_CART,
  payload: data
})

export const updateProductInCart = (data: OrderSupplierSelected[]) => ({
  type: UPDATE_CART,
  payload: data
})

export const clearSupplierOrder = () => ({
  type: CLEAR_ORDER,
})
