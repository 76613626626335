import { ADD_CART, LOG_OUT, UPDATE_CART, CLEAR_ORDER, TRANSFER_BRANCH, UPDATE_PRODUCT_TO_CART, ADD_PRODUCT_TO_CART, ADD_INVOICE_TO_CART } from "../types";
import { OrderSupplierSelected } from "../../dataType/SupplierOrder";

export type InitStateCart = {
  orderSuppliers: OrderSupplierSelected[];
};
const initState: InitStateCart = {
  orderSuppliers: [],
};

export default function cart(state = initState, action: any) {
  switch (action.type) {
    case ADD_CART:
      const newOrderSuppliers = [...state.orderSuppliers, action.payload]
      return {
        ...state,
        orderSuppliers: newOrderSuppliers
      };
    case UPDATE_CART:
      const newOrderSuppliersUpdate = [...action.payload];
      return {
        ...state,
        orderSuppliers: newOrderSuppliersUpdate
      }
    case LOG_OUT:
      return initState
    case TRANSFER_BRANCH: {
      return {
        ...initState
      };
    }
    case UPDATE_PRODUCT_TO_CART: {
      return {
        ...state,
        orderSuppliers: action.payload,
      }
    }
    case ADD_PRODUCT_TO_CART: {
      return {
        ...state,
        orderSuppliers: action.payload,
      }
    }
    case ADD_INVOICE_TO_CART: {
      return {
        ...state,
        orderSuppliers: action.payload,
      }
    }
    case CLEAR_ORDER:
      return initState
    default:
      return state;
  }
}
