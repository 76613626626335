import React from "react";
import "antd/dist/antd.css";
import "./login.scss";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { Link } from "react-router-dom";
import {
  LOGO, IMAGE_SOFTWARE_PHARMACIST,
  ICON_IOS, ICON_ANDROID
} from '../../assets';
import { WrappedFormUtils } from "antd/lib/form/Form";
import { connect } from "react-redux";
import { requestLogin, changeDataLogin, updateExpiredLicense } from "../../redux/actions/auth";
import { saveAllStoreChainPharmacies, saveInfoUser, saveInfoStore, getListPackage } from "../../redux/actions/dataPersist";
import { GRANT_TYPE } from "../../constants";
import ModalExpiredLicense from "../../components/ModalExpiredLicense";
import { DrgStore } from "../../dataType/Store";
import { RootState } from "../../redux/reducers";
import { DrgAccount } from "../../dataType/User";

import Carousel from 'react-bootstrap/Carousel'
import FooterLayout from "../../components/FooterLayout";
import BottomFooter from "../../components/BottomFooter";
import NavAuth from "../../components/NavbarAuth";
import FbChat from "../../components/ChatPlugin/FbChat";

type Props = {
  form: WrappedFormUtils;
  history: any;
  changeDataLogin: Function;
  requestLogin: Function;
  saveAllStoreChainPharmacies: Function;
  saveInfoUser: Function;
  saveInfoStore: Function;
  userInfo: {
    username: string;
    password: string;
  } | null;
  location: any;
  token: string;
  loading: boolean;
  message: string;
  infoStore: DrgStore;
  account: DrgAccount;
  expiredLicense: boolean;
  updateExpiredLicense: Function;
  getListPackage: Function;
};
type State = {
  username: string;
  password: string;
  remember: boolean;
  status: boolean;
  device_token: string;
  location: {
    latitude: number,
    longitude: number,
  } | null;
  index: number;
};


class WrappedNormalLoginForm extends React.Component<Props, State> {
  myRefFeature: any;
  myRefCourse: any;
  myRefService: any;
  constructor(props: Props) {
    super(props);
    this.myRefFeature = React.createRef();
    this.myRefCourse = React.createRef();
    this.myRefService = React.createRef();
    const { userInfo } = props;
    this.state = {
      username: userInfo ? userInfo.username : "",
      password: userInfo ? userInfo.password : "",
      remember: false,
      status: false,
      device_token: "",
      location: null,
      index: 0
    };
  }

  componentDidMount() {
    const { getListPackage } = this.props
    getListPackage()
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (err) return;
      this.setState({
        username: values.username,
        password: values.password,
        remember: values.remember
      }, () => this.login());
    });
  };

  login = () => {
    try {
      const { username, password, remember } = this.state;
      const { history, requestLogin, loading } = this.props;
      const params = {
        user: {
          username: username,
          password: password,
          grant_type: GRANT_TYPE.PASSWORD
        },
        moreInfor: {
          history,
          remember: remember ? { username: username, password } : null,
          loading,
        }
      };
      requestLogin(params);
    } catch (error: any) {
    }
  };

  handleSelect = (selectedIndex: number) => {
    this.setState({ index: selectedIndex })
  };

  _renderIntroduction = () => {
    const { index } = this.state
    const pathname = window.location.host;
    let productName = "", description = "", subDescription = "";
    if (pathname.includes("nhathuoc.medlink.vn")) {
      productName = "Phần mềm quản lý nhà thuốc - Medlink"
      description = "Tối ưu vận hành quản lý, giải pháp gia tăng doanh thu cho nhà thuốc"
      subDescription = "Chuyển đổi mô hình O2O cho lĩnh vực dược phẩm"
    } else if (pathname.includes("pharmaco.medlink.vn")) {
      productName = "Phần mềm quản lý công ty dược, nhà phân phối"
      description = "Giải pháp xây dựng kênh phân phối B2B2C cho thị trường dược phẩm"
      subDescription = "Xây dựng hệ thống loyalty, marketing, đào tạo, phân tích dữ liệu... "
    } else if (pathname.includes("clinic.medlink.vn")) {
      productName = "Phần mềm quản lý phòng khám"
      description = "Số hóa dịch vụ khám chữa bệnh, tối ưu vận hành quản lý cho phòng khám "
      subDescription = "Giải pháp xây dựng dịch vụ telehealht, telemedicine cho lĩnh vực y tế "
    } else {
      productName = "Phần mềm quản lý nhà thuốc - Medlink"
      description = "Tối ưu vận hành quản lý, giải pháp gia tăng doanh thu cho nhà thuốc"
      subDescription = "Chuyển đổi mô hình O2O cho lĩnh vực dược phẩm"
    }
    return (
      <div className="introduction">
        <Carousel
          fade={true}
          activeIndex={index}
          onSelect={this.handleSelect}
          className="slide-carousel"
          controls={false}
        >
          <Carousel.Item>
            <div className="image-container">
              <img alt="Phần mềm quản lý" src={IMAGE_SOFTWARE_PHARMACIST} className="slide-image" />
            </div>
            <div className="description-container">
              <h1 className="title">{productName}</h1>
              <h3 className="description-detail">{description}</h3>
              <h3 className="description-detail">{subDescription}</h3>
              <Link to="/dang-ky">
                <Button className="bt-registration">Đăng ký ngay</Button>
              </Link>
              <h3 className="free-trail"> Miễn phí 7 ngày dùng thử</h3>
            </div>

          </Carousel.Item>
          {/* <Carousel.Item >
            <div className="image-container">
              <img alt="Khoá học online" src={IMAGE_SOFTWARE_COURSE} className="slide-image" />
            </div>
            <div className="description-container">
              <h3 className="title">Khoá học - Vận hành nhà thuốc 4.0</h3>
              <h3 className="description-detail">Thay đổi để thích ứng với mô hình kinh doanh nhà thuốc 4.0.</h3>
              <h3 className="description-detail">Hỗ trợ tư vấn kiến thức chuyên môn, tối ưu ngành hàng, tư vấn setup nhà thuốc.</h3>
              <Button className="bt-registration" onClick={this._onPressCourse}>Xem chi tiết</Button>
            </div>
          </Carousel.Item>
          <Carousel.Item >
            <div className="image-container">
              <img alt="Khoá học online" src={INTRO_ORDER_ONLINE} className="slide-image" />
            </div>
            <div className="description-container">
              <h3 className="title">Nhận đơn online - Gia tăng doanh thu nhà thuốc</h3>
              <h3 className="description-detail">Cùng Medlink bán hàng, giải pháp gia tăng doanh thu cho nhà thuốc.</h3>
              <h3 className="description-detail">Nhận đơn thuốc từ các phòng khám liên kết với Medlink.</h3>
              <Link to="/dang-ky">
                <Button className="bt-registration">Đăng ký ngay</Button>
              </Link>
            </div>
          </Carousel.Item>
          <Carousel.Item >
            <div className="image-container">
              <img alt="Nhập hàng với nhà cung cấp" src={INTRO_ORDER_SUPPLIER} className="slide-image" />
            </div>
            <div className="description-container">
              <h3 className="title">Nhập hàng trực tiếp với nhà cung cấp</h3>
              <h3 className="description-detail">Nhập hàng với giá ưu đãi từ các nhà cung cấp.</h3>
              <h3 className="description-detail">Hỗ trợ liên kết với các nhãn hàng lớn.</h3>
              <Link to="/dang-ky">
                <Button className="bt-registration">Đăng ký ngay</Button>
              </Link>
            </div>
          </Carousel.Item> */}
        </Carousel>
      </div>
    )
  }

  _renderFormLogin = () => {
    const { getFieldDecorator } = this.props.form;
    const { userInfo } = this.props;
    const { username, password } = this.state;
    const { message, changeDataLogin, loading } = this.props;
    return (
      <div className="login-form-container">
        <Form onSubmit={this.handleSubmit} className="login-form">
          <div className="login-logo">
            <img alt="logo medlink" src={LOGO} className="logo" />
          </div>
          <Form.Item style={{ height: 50 }}>
            {getFieldDecorator("username", {
              rules: [
                {
                  required: true,
                  message: "Vui lòng nhập email hoặc số điện thoại"
                },
                {
                  pattern: new RegExp(
                    "^((\\+84|0)[0-9]{9}$)|([A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3})$"
                  ),
                  message: "Định dạng không đúng"
                }
              ],
              initialValue: username
            })(
              <Input
                placeholder="Nhập email hoặc số điện thoại"
                onChange={() => changeDataLogin()}
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.7)" }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item style={{ height: 50 }}>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Vui lòng nhập mật khẩu !" },
                { min: 6, message: "Mật khẩu ít nhất 6 ký tự" }
              ],
              initialValue: password
            })(
              <Input.Password
                autoComplete="off"
                type="password"
                onChange={() => changeDataLogin()}
                placeholder="Nhập mật khẩu"
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.7)" }} />
                }
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("remember", {
              valuePropName: "checked",
              initialValue: userInfo ? true : false
            })(<Checkbox>Lưu mật khẩu</Checkbox>)}
            <Link to={"/quen-mat-khau"} className="login-form-forgot">
              Quên mật khẩu?
            </Link>

            <div className="view-error">
              {message !== "" && (
                <p className="login-error">
                  {message}
                </p>
              )}
            </div>

            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
            >
              Đăng nhập
            </Button>
            <div className="textRegister">
              <Link to="/dang-ky" className="txtLinkRegister">
                Đăng ký?
              </Link>{" "}
              nếu bạn chưa có tài khoản!
            </div>
          </Form.Item>
        </Form>
        <div className="app-store">
          <a href="https://apps.apple.com/vn/app/medlink-pharmacy/id1470921954" target="_blank" rel="noopener noreferrer">
            <img alt="ứng dụng quản lý trên ios" src={ICON_IOS} className="icon_app_store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.ecomedic.medlink&hl=vi" target="_blank" rel="noopener noreferrer">
            <img alt="ứng dụng quản lý trên android" src={ICON_ANDROID} className="icon_app_store" />
          </a>
        </div>
      </div>
    )
  }

  renderLoginForm = () => {
    const { infoStore, account, expiredLicense, updateExpiredLicense } = this.props;
    return (
      <div className="login-info">
        {this._renderIntroduction()}
        {this._renderFormLogin()}
        {infoStore && account &&
          <ModalExpiredLicense
            drgStoreId={infoStore.drg_store_id}
            accountId={account.account_id}
            showAboutToExpire={expiredLicense}
            hiddenModal={() => updateExpiredLicense()}
          />
        }
      </div>
    )
  }

  _onPressFeature = () => {
    const top = this.myRefFeature.current.offsetTop;
    window.scrollTo({ top: top, behavior: "smooth" })
  }

  _onPressCourse = () => {
    const top = this.myRefCourse.current.offsetTop;
    window.scrollTo({ top: top, behavior: "smooth" })
  }

  _onPressService = () => {
    const top = this.myRefService.current.offsetTop;
    window.scrollTo({ top: top, behavior: "smooth" })
  }

  _onPressLogin = () => {
    window.scrollTo({ top: 0, behavior: "auto" })
  }



  render() {
    const listMenu = [
      {
        title: "Trang chủ",
        type: "link-news",
        onPress: () => {
        },
        icon: "",
        href: "https://medlink.vn"
      },
      {
        title: "Tin tức",
        type: "link-news",
        onPress: () => { },
        icon: "",
        href: "https://medlink.vn/blog"
      },
      // {
      //   title: "Tính năng",
      //   type: "page",
      //   onPress: this._onPressFeature,
      //   icon: "",
      //   href: "dang-nhap"
      // },
      // {
      //   title: "Khoá học",
      //   type: "page",
      //   onPress: this._onPressCourse,
      //   icon: "",
      //   href: ""
      // },
      // {
      //   title: "Phí dịch vụ",
      //   type: "page",
      //   onPress: this._onPressService,
      //   icon: "",
      //   href: ""
      // },
      {
        title: "Đăng nhập",
        type: "page",
        onPress: this._onPressLogin,
        icon: "",
        href: ""
      },
      {
        title: "Đăng ký",
        type: "link",
        onPress: () => { },
        icon: "",
        href: "dang-ky"
      }
    ]
    return (
      <div>
        <NavAuth listMenu={listMenu} />
        {this.renderLoginForm()}
        <FooterLayout />
        <BottomFooter />
        <FbChat />
      </div>
    );
  }
}
const Login = Form.create({ name: "normal_login" })(WrappedNormalLoginForm);
function mapStateToProps(state: RootState) {
  return {
    infoStore: state.dataPersist.infoStore,
    account: state.dataPersist.infoUser,
    userInfo: state.auth.userInfo,
    expiredLicense: state.auth.expiredLicense,
    token: state.auth.token,
    loading: state.auth.loadingLogin,
    message: state.auth.message
  };
}
const mapDispatchToProps = {
  requestLogin,
  changeDataLogin,
  saveAllStoreChainPharmacies,
  saveInfoUser,
  saveInfoStore,
  updateExpiredLicense,
  getListPackage
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
