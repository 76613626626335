import { SmsCampaignDetailRequest, SmsCampaignRequest, SearchListCampaignRequest, SearchCampaignMessageDetailRequest } from "../dataType/Campaign";
import { LIMIT, PAGE_DEFAULT } from "../constants";
import client from "./apiConfig";
import { SmsCampaignPriceRequest } from "../dataType/smsCampaign";

export default {
    createCampaign(campaign: SmsCampaignRequest) {
        const url = `/sms/campaign`
        return client.post(url, campaign)
    },
    disconnectCampaign(campaignId: number) {
        const url = `/sms/campaign?campaign_id=${campaignId}`
        return client.delete(url)
    },
    updateCampaignDetail(campaignDetail: SmsCampaignDetailRequest) {
        const url = `/sms/campaign/detail`
        return client.post(url, campaignDetail)
    },
    searchListCampaign(params: SearchListCampaignRequest, pageAPI?: number, perPageAPI?: number) {
        let page = pageAPI ? pageAPI : PAGE_DEFAULT;
        let per_page = perPageAPI ? perPageAPI : LIMIT;
        const url = `/sms/campaign/search?page=${page}&size=${per_page}`;
        return client.post(url, params).then(response => {
            return response;
        })
    },
    getCampaignDetail(campaign_detail_id: number) {
        const url = `/sms/campaign/detail?campaign_detail_id=${campaign_detail_id}`;
        return client.get(url).then(respone => {
            return respone
        })
    },
    searchCampaignMessageDetail(params: SearchCampaignMessageDetailRequest, pageAPI?: number, perPageAPI?: number) {
        let page = pageAPI ? pageAPI : PAGE_DEFAULT;
        let per_page = perPageAPI ? perPageAPI : LIMIT;
        const url = `/sms/campaign/detail/message?page=${page}&size=${per_page}`;
        return client.post(url, params).then(response => {
            return response;
        })
    },
    getSmsCampaignType() {
        const url = `/sms/campaign/type`;
        return client.get(url);
    },
    smsCampaignPrice(params: SmsCampaignPriceRequest) {
        const url = `/sms/campaign/price`;
        return client.post(url, params);
    }
};
