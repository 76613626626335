import {BannerRequestModel, GetAllBannerModel} from "../dataType/Banner";
import client from "./apiConfig";

export default {
  async createBanner(data: BannerRequestModel) {
    const url = `/pharmacy/banner`;
    return await client.post(url, data);
  },
  async getAllBanner(data: GetAllBannerModel ) {
    const url = '/pharmacy/banner';
    return await client.get(url, {
      params: data
    });
  },
  async deleteBanner(banner_code: string ) {
    const url = `/pharmacy/banner/${banner_code}`;
    return await client.delete(url);
  },
  async updateBanner(banner_code: string, data: BannerRequestModel ) {
    const url = `/pharmacy/banner/${banner_code}`;
    return await client.put(url, data);
  },
}
