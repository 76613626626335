import {
  LoyaltyCustomerParam,
  LoyaltyCustomerRegister,
  LoyaltyCustomerUpdate,
  WalletChangeRequest
} from '../dataType/loyalty';
import client from "./apiConfig";

export default {
  async getListLoyaltyCustomer(page: number, size: number, param?: LoyaltyCustomerParam) {
    const url = `/loyalty/customer?page=${page}&size=${size}&customer_name=${param?.customer_name}&rank_name=${param?.rank_name}&status=${param?.status}&customer_code=${param?.customer_code}&phone_no=${param?.phone_no}`;
    const response = await client.get(url);
    return response.data;
  },

  async updateLoyaltyCustomer(data: LoyaltyCustomerUpdate) {
    const url = `/loyalty/customer`;
    const response = await client.put(url, data);
    return response.data;
  },

  async registerCustomerLoyalty(data: LoyaltyCustomerRegister) {
    const url = `/loyalty/customer`;
    const response = await client.post(url, data);
    return response;
  },

  async changeWalletCustomer(data: WalletChangeRequest) {
    const url = `/loyalty/cashflow/wallet`;
    const response = await client.put(url, data);
    return response.data;
  }
}
