import { UPDATE_IS_LOADING_DASHBOARD } from "../types";

export type IsLoadingDashboard = boolean
const initLoading: IsLoadingDashboard = false
type actionType = {
  type: string;
  payload: any;
};
export default function isLoadingDashboard(state = initLoading, action: actionType) {
  const { type } = action
  switch (type) {
    case UPDATE_IS_LOADING_DASHBOARD: {
      return action.payload
    }
    default:
      return state;
  }
} 