import { LoyaltyEcoinRequest, LoyaltyEarnEcoinRequest, LoyaltyExchangeCreaetRequest } from '../dataType/loyalty';
import client from './apiConfig';
export default {
  async getEarnEcoin(page: number, size: number, param: LoyaltyEcoinRequest) {
    const url = `/loyalty/earn?page=${page}&size=${size}&program_name=${param?.program_name}&rank_id=${param?.rank_id}&date_from=${param?.date_from}&date_to=${param?.date_to}&status=${param?.status}`;
    const response = await client.get(url);
    return response.data;
  },
  async createNewEarnEcoin(data: LoyaltyEarnEcoinRequest) {
    const url = `/loyalty/earn`;
    const response = await client.post(url, data);
    return response;
  },
  async updateEarnEcoin(id: number, data: LoyaltyEarnEcoinRequest) {
    const url = `/loyalty/earn/${id}`;
    const response = await client.put(url, data);
    return response;
  },
  async deleteEarnEcoin(id: number) {
    const url = `/loyalty/earn/${id}`;
    const response = await client.delete(url);
    return response;
  },
  async getExchangeEcoin(page: number, size: number, param: LoyaltyEcoinRequest) {
    const url = `/loyalty/exchange?page=${page}&size=${size}&program_name=${param?.program_name}&rank_id=${param?.rank_id}&date_from=${param?.date_from}&date_to=${param?.date_to}&status=${param?.status}`;
    const response = await client.get(url);
    return response.data;
  },
  async createNewExchangeEcoin(data: LoyaltyExchangeCreaetRequest) {
    const url = `/loyalty/exchange`;
    const response = await client.post(url, data);
    return response;
  },
  async updateExchangeEcoin(id: number, data: LoyaltyExchangeCreaetRequest) {
    const url = `/loyalty/exchange/${id}`;
    const response = await client.put(url, data);
    return response;
  },
  async deleteExchangeEcoin(id: number) {
    const url = `/loyalty/exchange/${id}`;
    const response = await client.delete(url);
    return response;
  },
  async getaEcoin(exchange_id: number, data: {is_return_product: boolean}) {
    const url = `loyalty/exchange/${exchange_id}`;
    const response = await client.get(url, {params: data});
    return response;
  },
}