import {combineReducers} from "redux";
import auth, {InitStateAuth} from "./auth";
import cart, {InitStateCart} from "./cart";
import invoice, {InitStateInvoice} from "./invoice";
import inventory, {InitStateInventory} from "./inventory";
import product, {InitStateProduct} from "./product";
import dataPersist, {InitStateDataPersist} from "./dataPersist";
import setting, {InitStateSetting} from "./setting";
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import invoiceWholesale, {InitStateInvoiceWholesale} from "./invoiceWholesale";
import combo, {InitStateCombo} from "./combo";
import invoiceCombo, {InitStateInvoiceCombo} from "./invoiceCombo";
import invoiceDraft, {InitStateInvoiceDraft} from "./invoiceDraft";
import invoiceDraftConfirm, {InitStateInvoiceDraftConfirm} from "./invoiceDraftConfirm";
import campaign, {InitStateCampaign} from "./campaign";
import settingUIDisplay, {ListColumn} from "./settingUIDisplay";
import isLoadingDashboard, {IsLoadingDashboard} from "./isLoadingDashboard";
import settingPrint from "./settingPrint";
import {InitSettingPrint} from "../../dataType/Setting";
import promotion from "./promotion";
import {PromotionCreateRequest} from "../../dataType/Promotion";
import promotionNew from "./promotionNew";
import {PromotionNewCreateModel} from "../../dataType/PromotionNew";

export type RootState = {
  auth: InitStateAuth,
  cart: InitStateCart,
  invoice: InitStateInvoice,
  invoiceDraft: InitStateInvoiceDraft,
  invoiceDraftConfirm: InitStateInvoiceDraftConfirm
  invoiceWholesale: InitStateInvoiceWholesale,
  invoiceCombo: InitStateInvoiceCombo,
  product: InitStateProduct,
  inventory: InitStateInventory,
  dataPersist: InitStateDataPersist,
  setting: InitStateSetting,
  combo: InitStateCombo,
  campaign: InitStateCampaign,
  settingUIDisplay: ListColumn,
  isLoadingDashboard: IsLoadingDashboard,
  settingPrint: InitSettingPrint,
  promotion: PromotionCreateRequest,
  promotionNew: PromotionNewCreateModel
}

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['message', 'loadingLogin']
};

const rootReducers = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  cart,
  invoice,
  invoiceWholesale,
  invoiceCombo,
  invoiceDraft,
  invoiceDraftConfirm,
  inventory,
  product,
  dataPersist,
  setting,
  combo,
  campaign,
  settingUIDisplay,
  isLoadingDashboard,
  settingPrint,
  promotion,
  promotionNew,
});

export default rootReducers;
