import { LoyaltyProductRegister, LoyaltyProductRequest, LoyaltyProductUpdate } from '../dataType/loyalty';
import client from "./apiConfig";

export default {
  async getAllLoyaltyProduct(page: number, size: number, data: LoyaltyProductRequest) {
    const url = `/loyalty/earn/product?page=${page}&size=${size}&drg_store_id=${data?.drg_store_id}&drg_drug_name=${data?.drg_drug_name}&status=${data?.status}`;
    const response = await client.get(url);
    return response.data;
  },
  async updateProductLoyalty(id: number, data: LoyaltyProductUpdate) {
    const url = `/loyalty/earn/product`;
    const response = await client.put(url, data);
    return response.data;
  },
  async registerLoyaltyProduct(data: LoyaltyProductRegister[]) {
    const url = `/loyalty/earn/product`;
    const response = await client.post(url, data);
    return response;
  },
  async updateLoyaltyFlg(data: number[], loyalty_flg: number) {
    const url = `/pharmacy/product/loyalty?loyalty_flg=${loyalty_flg}`;
    const response = await client.put(url, data);
    return response;
  },
  async getLoyaltyProductDetail(drg_store_id: number, drg_drug_cd: string) {
    const url = `/loyalty/earn/product/detail?drg_store_id=${drg_store_id}&drg_drug_cd=${drg_drug_cd}`;
    const response = await client.get(url);
    return response;
  }
}
