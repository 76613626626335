import {
  LOGIN, LOG_OUT, ADD_DEVICE_TOKEN, SAVE_LOCATION, SAVE_TOKEN, LOGIN_REQUEST, LOGIN_FAIL,
  RESET_MESSAGE_LOGIN, EXPIRED_LICENSE, UPDATE_REQUEST_LOGIN, UPDATE_USER_INFO, UPDATE_FISRT_LOGIN
} from "../types";

export interface LocationStore {
  latitude: number,
  longitude: number,
  drg_store_id: number | null
}
export interface UserLogin {
  username: string;
  password: string;
}
export interface InitStateAuth {
  deviceToken: string | null;
  token: string;
  tokenType: string;
  refreshToken: string;
  expertTime: number | null;
  scope: string;
  drgStoreId: number;
  expiredLicense: boolean;
  userInfo: UserLogin | null;
  location: LocationStore | null;
  loadingLogin: boolean | false;
  message: string;
  isFirstLogin: boolean;
}

const initialState: InitStateAuth = {
  deviceToken: null,
  token: '',
  tokenType: 'bearer',
  refreshToken: '',
  expertTime: 0,
  scope: '',
  drgStoreId: 0,
  expiredLicense: false,
  userInfo: null,
  location: null,
  loadingLogin: false,
  message: "",
  isFirstLogin: false,
};

export default function auth(state = initialState, action: any) {
  const { type, payload } = action
  switch (type) {
    case LOGIN:
      return {
        ...state,
        token: payload.access_token,
        tokenType: payload.token_type,
        refreshToken: payload.refresh_token,
        expertTime: Date.now() + (payload.expires_in - 60) * 1000,
        scope: payload.scope,
        drgStoreId: payload.drg_store_id,
        userInfo: payload.userInfo ? payload.userInfo : state.userInfo,
        // location: payload.location,
        loadingLogin: false,
        message: ""
      };
    case SAVE_LOCATION:
      return {
        ...state,
        location: action.location
      };
    case LOG_OUT:
      return {
        ...state,
        token: null,
        expertTime: null,
        deviceToken: null,
        location: null,
        loadingLogin: false,
        refreshToken: ''
      };
    case ADD_DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.deviceToken
      }
    case SAVE_TOKEN:
      return {
        ...state,
        token: action.token
      }
    case LOGIN_REQUEST:
      return {
        ...state,
        loadingLogin: true
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loadingLogin: false,
        message: action.payLoad
      }
    case RESET_MESSAGE_LOGIN:
      return {
        ...state, message: ""
      }
    case EXPIRED_LICENSE:
      return {
        ...state,
        expiredLicense: action.payload,
        loadingLogin: false
      }
    case UPDATE_REQUEST_LOGIN:
      return {
        ...state,
        loadingLogin: false
      }
    case UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo
      }
    case UPDATE_FISRT_LOGIN:
      return {
        ...state,
        isFirstLogin: action.payload
      }
    default:
      return state;
  }
}
