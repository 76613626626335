import {RESET_PROMOTION, UPDATE_DETAIL_PROMOTION, UPDATE_TYPE_PRMOTION} from '../types';
import {PromotionCreateRequest} from './../../dataType/Promotion';

const initPromotion: PromotionCreateRequest = {
  company_id: 0,
  promotion_name: '',
  promotion_type: '',
  from_time: '',
  to_time: '',
  description: '',
  drg_store_ids: [],
  customer_group_ids: [],
  business_type: [],
  source: [],
  max_uses: 0,
  status: 0,
  promotion_conditions: [],
  img_url: '',
  noti_schedule: '',
  applicable_type: ""
}

export default function promotion(state = initPromotion, action: any) {
  switch (action.type) {
    case UPDATE_TYPE_PRMOTION:
      return {
        ...state,
        applicable_type: action.payload,
      }
    case UPDATE_DETAIL_PROMOTION:
      return action.payload;
    case RESET_PROMOTION:
      return {
        company_id: 0,
        promotion_name: '',
        promotion_type: '',
        from_time: '',
        to_time: '',
        description: '',
        drg_store_ids: [],
        customer_group_ids: [],
        business_type: [],
        source: [],
        max_uses: 0,
        status: 0,
        promotion_conditions: [],
        img_url: '',
        noti_schedule: '',
        applicable_type: ""
      };
    default:
      return state;
  }
}
