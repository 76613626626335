import { DrgDrug } from './../../dataType/Product.d';
import { DrgCustomer } from './../../dataType/Customer.d';
import { RootState } from './../reducers/index';
import { ResponseAxios } from './../../dataType/Response.d';
import { CREATE_SAMPLE_DATA } from './../types/index';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import api from '../../api';
import { LIST_CUSTOMER_SAMPLE, LIST_DRUG_SAMPLE, LIST_PROVIDER_SAMPLE } from '../../constants/mock';
import { saveListDrugPharmacy } from '../actions/dataPersist';
import { ImportInfoRequest, ImportInvDetailRequest } from '../../dataType/Import';
import moment from 'moment';
import { DrugInv } from '../../dataType/Inventory';
import { Provider } from '../../dataType/Provider';
import { updateLoadingDashboard } from '../actions/isLoadingDashboard';
const param = {
  drug_barcode: "",
  drug_name: '',
  company: "",
  status: null,
  quantity: 1,
  exp_date: true,
};
let infoInventoryImport: ImportInfoRequest = {
  amount: 2520000,
  amount_debt: 0,
  amount_vat: 0,
  classification: '',
  discount_amount: 0,
  discount_type: "VNĐ",
  import_type: "ORD",
  invoice_code: "",
  note: "",
  pay_method: "CS",
  payment_reference: "{}",
  process_date: moment().subtract('days', 7).format(),
  provider_id: 58034,
  provider_name: "Công ty cổ phần Dược Hậu Giang",
  sync_flg: 0,
  vat: 0,
}
let productInventoryImport: ImportInvDetailRequest[] = [
  {
    discount_amount: 0,
    discount_type: "VNĐ",
    discount_unit: 0,
    drg_drug_cd: "S00000001",
    drg_drug_name: "Panadol Extra giảm đau, hạ sốt (15 vỉ x 12 viên)",
    drug_id: 0,
    drug_units: [
      {
        active_flg: 1,
        default_flg: 1,
        drug_id: 0,
        drug_price_id: 0,
        drug_unit_id: 0,
        inv_qty_alarm: 50,
        max_price: 0,
        parent_unit: 14,
        parent_unit_name: null,
        price: 3500,
        price_before_vat: 3500,
        unit_id: 14,
        unit_name: "Viên",
        unit_qty: 1,
        wholesale_price: 0,
        wholesale_price_before_vat: 0,
      },
      {
        active_flg: 1,
        default_flg: 0,
        drug_id: 0,
        drug_price_id: 2175133,
        drug_unit_id: 2870213,
        inv_qty_alarm: 5,
        max_price: 0,
        parent_unit: 14,
        parent_unit_name: null,
        price: 35000,
        price_before_vat: 35000,
        unit_id: 2,
        unit_name: "Vỉ",
        unit_qty: 10,
        wholesale_price: 0,
        wholesale_price_before_vat: 0,
      }
    ],
    exp_date: "20231027",
    key: 1,
    lot: "N01",
    mfg_date: "",
    price: 12600,
    quantity: 100,
    total_amount: 1260000,
    unit_cd: "",
    unit_name: "Vỉ",
    validate: true,
    vat_percent: 0,
  },
  {
    discount_amount: 0,
    discount_type: "VNĐ",
    discount_unit: 0,
    drg_drug_cd: "S00000002",
    drg_drug_name: "Alpha Chymotrypsine Choay (Vỉ x 15 viên)",
    drug_id: 1406699,
    drug_units: [
      {
        active_flg: 1,
        default_flg: 1,
        drug_id: 1406699,
        drug_price_id: 2175130,
        drug_unit_id: 2870210,
        inv_qty_alarm: 20,
        max_price: 0,
        parent_unit: 14,
        parent_unit_name: null,
        price: 4200,
        price_before_vat: 4200,
        unit_id: 14,
        unit_name: "Viên",
        unit_qty: 1,
        wholesale_price: 0,
        wholesale_price_before_vat: 1,
      },
      {
        active_flg: 1,
        default_flg: 0,
        drug_id: 1406699,
        drug_price_id: 2175131,
        drug_unit_id: 2870211,
        inv_qty_alarm: 2,
        max_price: 0,
        parent_unit: 14,
        parent_unit_name: null,
        price: 42000,
        price_before_vat: 42000,
        unit_id: 2,
        unit_name: "Vỉ",
        unit_qty: 10,
        wholesale_price: 0,
        wholesale_price_before_vat: 1,
      }
    ],
    exp_date: "20221027",
    key: 2,
    lot: "N01",
    mfg_date: "",
    price: 25200,
    quantity: 50,
    total_amount: 1260000,
    unit_cd: "",
    unit_name: "Vỉ",
    validate: true,
    vat_percent: 0,
  },
]
let listInvoice = [
  {
    invoice: {
      amount: 360000,
      amount_debt: 0,
      amount_paid: 360000,
      amount_vat: 0,
      currency: 'VNĐ',
      current_pay: 360000,
      customer_address: '',
      customer_code: '',
      customer_id: 0,
      customer_name: '',
      customer_phone_no: '',
      discount_amount: 0,
      discount_amount_inv: 0,
      discount_type: 'VNĐ',
      ecoin_minus: 0,
      ecoin_plus: 0,
      fee_ship: 0,
      img_url: '',
      invoice_source: 'IN_STORE',
      invoice_type: 0,
      is_debit: false,
      issue_datetime: moment().format(),
      note: '',
      pay_method: 'CS',
      pay_reference: "\"\"",
      status: 2,
      vat: 0,
    },
    products: [
      {
        base_quantity: 1,
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        dosage: '',
        drg_drug_cd: 'S00000001',
        drg_drug_name: 'Panadol Extra giảm đau, hạ sốt (15 vỉ x 12 viên)',
        drug_id: 0,
        drug_unit_id: 0,
        import_price: 12600,
        inv_id: 0,
        lot: 'NO1',
        price: 18000,
        promotion_flg: 0,
        quantity: 10,
        unit_id: 2,
        unit_name: "Vỉ",
        wholesale_price: 12600,
      },
      {
        inv_id: 0,
        drug_unit_id: 0,
        drug_id: 0,
        drg_drug_cd: "S00000002",
        lot: 'NO1',
        drg_drug_name: "Alpha Chymotrypsine Choay (2 vỉ x 15 viên)",
        quantity: 5,
        base_quantity: 1,
        price: 36000,
        wholesale_price: 25200,
        dosage: '',
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        unit_id: 2,
        unit_name: "Vỉ",
        promotion_flg: 0,
        import_price: 25200,
      }
    ]
  },
  {
    invoice: {
      amount: 450000,
      amount_debt: 0,
      amount_paid: 450000,
      amount_vat: 0,
      currency: 'VNĐ',
      current_pay: 450000,
      customer_address: '',
      customer_code: '',
      customer_id: 0,
      customer_name: '',
      customer_phone_no: '',
      discount_amount: 0,
      discount_amount_inv: 0,
      discount_type: 'VNĐ',
      ecoin_minus: 0,
      ecoin_plus: 0,
      fee_ship: 0,
      img_url: '',
      invoice_source: 'IN_STORE',
      invoice_type: 0,
      is_debit: false,
      issue_datetime: moment().subtract('days', 1).format(),
      note: '',
      pay_method: 'CS',
      pay_reference: "\"\"",
      status: 2,
      vat: 0,
    },
    products: [
      {
        base_quantity: 1,
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        dosage: '',
        drg_drug_cd: 'S00000001',
        drg_drug_name: 'Panadol Extra giảm đau, hạ sốt (15 vỉ x 12 viên)',
        drug_id: 0,
        drug_unit_id: 0,
        import_price: 12600,
        inv_id: 0,
        lot: 'NO1',
        price: 18000,
        promotion_flg: 0,
        quantity: 5,
        unit_id: 2,
        unit_name: "Vỉ",
        wholesale_price: 12600,
      },
      {
        inv_id: 0,
        drug_unit_id: 0,
        drug_id: 0,
        drg_drug_cd: "S00000002",
        lot: 'NO1',
        drg_drug_name: "Alpha Chymotrypsine Choay (2 vỉ x 15 viên)",
        quantity: 10,
        base_quantity: 1,
        price: 36000,
        wholesale_price: 25200,
        dosage: '',
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        unit_id: 2,
        unit_name: "Vỉ",
        promotion_flg: 0,
        import_price: 25200,
      }
    ]
  },
  {
    invoice: {
      amount: 234000,
      amount_debt: 0,
      amount_paid: 234000,
      amount_vat: 0,
      currency: 'VNĐ',
      current_pay: 234000,
      customer_address: '',
      customer_code: '',
      customer_id: 0,
      customer_name: '',
      customer_phone_no: '',
      discount_amount: 0,
      discount_amount_inv: 0,
      discount_type: 'VNĐ',
      ecoin_minus: 0,
      ecoin_plus: 0,
      fee_ship: 0,
      img_url: '',
      invoice_source: 'IN_STORE',
      invoice_type: 0,
      is_debit: false,
      issue_datetime: moment().subtract('days', 2).format(),
      note: '',
      pay_method: 'CS',
      pay_reference: "\"\"",
      status: 2,
      vat: 0,
    },
    products: [
      {
        base_quantity: 1,
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        dosage: '',
        drg_drug_cd: 'S00000001',
        drg_drug_name: 'Panadol Extra giảm đau, hạ sốt (15 vỉ x 12 viên)',
        drug_id: 0,
        drug_unit_id: 0,
        import_price: 12600,
        inv_id: 0,
        lot: 'NO1',
        price: 18000,
        promotion_flg: 0,
        quantity: 3,
        unit_id: 2,
        unit_name: "Vỉ",
        wholesale_price: 12600,
      },
      {
        inv_id: 0,
        drug_unit_id: 0,
        drug_id: 0,
        drg_drug_cd: "S00000002",
        lot: 'NO1',
        drg_drug_name: "Alpha Chymotrypsine Choay (2 vỉ x 15 viên)",
        quantity: 5,
        base_quantity: 1,
        price: 36000,
        wholesale_price: 25200,
        dosage: '',
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        unit_id: 2,
        unit_name: "Vỉ",
        promotion_flg: 0,
        import_price: 25200,
      }
    ]
  },
  {
    invoice: {
      amount: 360000,
      amount_debt: 0,
      amount_paid: 360000,
      amount_vat: 0,
      currency: 'VNĐ',
      current_pay: 360000,
      customer_address: '',
      customer_code: '',
      customer_id: 0,
      customer_name: '',
      customer_phone_no: '',
      discount_amount: 0,
      discount_amount_inv: 0,
      discount_type: 'VNĐ',
      ecoin_minus: 0,
      ecoin_plus: 0,
      fee_ship: 0,
      img_url: '',
      invoice_source: 'IN_STORE',
      invoice_type: 0,
      is_debit: false,
      issue_datetime: moment().subtract('days', 3).format(),
      note: '',
      pay_method: 'CS',
      pay_reference: "\"\"",
      status: 2,
      vat: 0,
    },
    products: [
      {
        base_quantity: 1,
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        dosage: '',
        drg_drug_cd: 'S00000001',
        drg_drug_name: 'Panadol Extra giảm đau, hạ sốt (15 vỉ x 12 viên)',
        drug_id: 0,
        drug_unit_id: 0,
        import_price: 12600,
        inv_id: 0,
        lot: 'NO1',
        price: 18000,
        promotion_flg: 0,
        quantity: 10,
        unit_id: 2,
        unit_name: "Vỉ",
        wholesale_price: 12600,
      },
      {
        inv_id: 0,
        drug_unit_id: 0,
        drug_id: 0,
        drg_drug_cd: "S00000002",
        lot: 'NO1',
        drg_drug_name: "Alpha Chymotrypsine Choay (2 vỉ x 15 viên)",
        quantity: 5,
        base_quantity: 1,
        price: 36000,
        wholesale_price: 25200,
        dosage: '',
        discount: 0,
        discount_type: 'VNĐ',
        discount_unit: 0,
        unit_id: 2,
        unit_name: "Vỉ",
        promotion_flg: 0,
        import_price: 25200,
      }
    ]
  }
]
function* createInventoryImport(provider: Provider, resDrug: DrgDrug[]) {
  infoInventoryImport.provider_id = provider.provider_id
  const product1 = resDrug.find((item: DrgDrug) => item.drg_drug_cd === productInventoryImport[0].drg_drug_cd)
  if (product1) {
    productInventoryImport[0].drug_units = product1.drug_units
    productInventoryImport[0].drug_id = product1.drug_id
  }
  const product2 = resDrug.find((item: DrgDrug) => item.drg_drug_cd === productInventoryImport[1].drg_drug_cd)
  if (product2) {
    productInventoryImport[1].drug_units = product2.drug_units
    productInventoryImport[1].drug_id = product2.drug_id
  }
  try {
    yield call(api.inventory.importInventory, { info: infoInventoryImport, products: productInventoryImport });
  } catch (err) { }
}

const createInvoiceSample = async (customer: DrgCustomer, drugListInv: any) => {
  listInvoice.map(invoice => {
    invoice.invoice.customer_id = customer.user_id
    invoice.invoice.customer_address = customer.address1
    invoice.invoice.customer_code = customer.customer_code
    invoice.invoice.customer_name = customer.customer_name
    invoice.invoice.customer_phone_no = customer.phone_no
    const product = drugListInv.find((item: DrugInv) => item.drg_drug_cd === invoice.products[0].drg_drug_cd)
    if (product) {
      invoice.products[0].inv_id = product.inv_id
      invoice.products[0].drug_unit_id = product.drug_unit_id
      invoice.products[0].drug_id = product.drug_id
    }
    const product2 = drugListInv.find((item: DrugInv) => item.drg_drug_cd === invoice.products[1].drg_drug_cd)
    if (product2) {
      invoice.products[1].inv_id = product2.inv_id
      invoice.products[1].drug_unit_id = product2.drug_unit_id
      invoice.products[1].drug_id = product2.drug_id
    }
  })

  const createInvoice = (invoice: any, time: number) => new Promise((resolve, reject) => {
    setTimeout(() => { resolve(api.invoice.createInvoice(invoice)) }, time);
  });
  for (let i = 0; i < listInvoice.length; i++) {
    let time = 500 * i
    await createInvoice(listInvoice[i], time);
  }
}


function* createSampleData() {
  let token: string = yield select((state: RootState) => state.auth.token)
  try {
    //resDrugPharmacy
    const resDrugResponse: ResponseAxios = yield call(api.product.createProduct, { new_drugs: LIST_DRUG_SAMPLE })
    const resDrug = resDrugResponse.data
    //getAllDrugOfStore
    const res: ResponseAxios = yield call(api.product.getAllDrugsOfStore)
    yield put(saveListDrugPharmacy(res.data))
    //resProvider
    yield call(api.provider.createProvider, token, LIST_PROVIDER_SAMPLE[0])
    const resProvider2: ResponseAxios = yield call(api.provider.createProvider, token, LIST_PROVIDER_SAMPLE[1])
    //createInventoryImport
    yield call(createInventoryImport, resProvider2.data, resDrug)
    //resCustomer
    yield call(api.customer.createCustomer, token, LIST_CUSTOMER_SAMPLE[0])
    const resCustomer2: ResponseAxios = yield call(api.customer.createCustomer, token, LIST_CUSTOMER_SAMPLE[1])
    //getDrugListInv
    const resDrugListInv: ResponseAxios = yield call(api.invoice.getDrugListInv, token, 1, 10, param)
    //createInvoiceSample
    yield call(createInvoiceSample, resCustomer2.data, resDrugListInv.data)
    yield put(updateLoadingDashboard(true))
  } catch (err) {
  }
}

export default function* () {
  yield takeLatest(CREATE_SAMPLE_DATA, createSampleData)
} 