import client from "./apiConfig";

export default {
  getPermissionForRole() {
    const url = `/oauth/role`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },
}