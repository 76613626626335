import config from "../config";
import { GRANT_TYPE } from "../constants";
import { ChangePasswordRequest, LoginRequest, RegisterRequest } from "../dataType/auth";
import client from "./apiConfig";

export default {
  login(params: LoginRequest) {
    const url = `/oauth/token`;
    const paramsForm = new FormData()
    if (params.refresh_token) {
      paramsForm.append('refresh_token', params.refresh_token)
      paramsForm.append('grant_type', GRANT_TYPE.REFRESH_TOKEN)
    } else {
      paramsForm.append('username', params.username ? params.username : '')
      paramsForm.append('password', params.password ? params.password : '')
      paramsForm.append('grant_type', GRANT_TYPE.PASSWORD)
    }
    if (params.drg_store_id) paramsForm.append('drg_store_id', params.drg_store_id.toString())

    return client.post(url, paramsForm, {
      auth: {
        username: config.axiosConfig().auth.client_id,
        password: config.axiosConfig().auth.client_secret
      },
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  },
  /*******************************************/
  register(params: RegisterRequest) {
    const url = `oauth/pharmacy/signup`;
    return client.post(url, params).then(response => {
      return response;
    });
  },
  verifyAccount(login_id: string, token: string) {
    const url = `oauth/pharmacy/verify-account?login_id=${login_id}&token=${token}`;
    return client.get(url);
  },

  checkAccountExist: (login_id: string) => {
    const url = `oauth/pharmacy/check-account?login_id=${login_id}`;
    return client.get(url);
  },
  confirmPassWord: (params: ChangePasswordRequest) => {
    const url = `/oauth/pharmacy/forgot-password`;
    return client.post(url, params).then(response => {
      return response;
    });
  },
  getCompanyType: () => {
    const url = `/pharmacy/company/type`
    return client.get(url)
  }
};
