export const data = [
  {
    drg_drug_name: "Thuốc cam hàng bạc",
    code_dqg: "DRG0123456",
    license_cd: "QLĐK-12365",
    company_name: "Việt Nam",
    contraindication: "100 ml/gói",
    interation: "Paractamol",
    source: "Danh mục"
  },
  {
    drg_drug_name: "Kem nghệ Thái Dương",
    code_dqg: "DRG0123456",
    license_cd: "QLĐK-12365",
    company_name: "Việt Nam",
    contraindication: "100 ml/gói",
    interation: "Paractamol",
    source: "Danh mục"
  },
  {
    drg_drug_name: "Trị nám Nam Bảo",
    code_dqg: "DRG0123456",
    license_cd: "QLĐK-12365",
    company_name: "Việt Nam",
    contraindication: "100 ml/gói",
    interation: "Paractamol",
    source: "Danh mục"
  },
];
export const dataDrugTopSales = [
  {
    country: "Thuốc cam hàng bạc",
    "Doanh thu": 140
  },
  {
    country: "Paratamol",
    "Doanh thu": 120
  },
  {
    country: "Alpachoay",
    "Doanh thu": 80
  },
  {
    country: "Apicilinc",
    "Doanh thu": 60
  },
];
export const dataDrugTopAmount = [
  {
    country: "Thuốc cam hàng bạc",
    "Doanh thu": 140
  },
  {
    country: "Paratamol",
    "Doanh thu": 110
  },
  {
    country: "Alpachoay",
    "Doanh thu": 50
  },
  {
    country: "Apicilinc",
    "Doanh thu": 10
  },
];
export const dataLineDay = [
  {
    id: "Ngày",
    color: "#73C04E",
    data: [
      {
        x: "1/11",
        y: 215
      },
      {
        x: "2/11",
        y: 14
      },
      {
        x: "3/11",
        y: 101
      },
      {
        x: "4/11",
        y: 140
      },
      {
        x: "5/11",
        y: 195
      },
      {
        x: "6/11",
        y: 155
      },
      {
        x: "7/11",
        y: 229
      },
      {
        x: "8/11",
        y: 235
      },
      {
        x: "9/11",
        y: 119
      },
      {
        x: "10/11",
        y: 54
      },
      {
        x: "11/11",
        y: 181
      },
      {
        x: "12/11",
        y: 272
      }
    ]
  }
];
export const dataLineMonth = [
  {
    id: "Tháng",
    color: "#73C04E",
    data: [
      {
        x: "1",
        y: 22
      },
      {
        x: "2",
        y: 14
      },
      {
        x: "3",
        y: 23
      },
      {
        x: "4",
        y: 140
      },
      {
        x: "5",
        y: 195
      },
      {
        x: "6",
        y: 56
      },
      {
        x: "7",
        y: 229
      },
      {
        x: "8",
        y: 235
      },
      {
        x: "9",
        y: 80
      },
      {
        x: "10",
        y: 54
      },
      {
        x: "11",
        y: 181
      },
      {
        x: "12",
        y: 272
      }
    ]
  }
];
export const dataLineYear = [
  {
    id: "Năm",
    color: "#73C04E",
    data: [
      {
        x: "2016",
        y: 22
      },
      {
        x: "2017",
        y: 26
      },
      {
        x: "2018",
        y: 24
      },
      {
        x: "2019",
        y: 36
      },
    ]
  }
];
export const ChartBarDayEmploye = [
  {
    country: "Giang",
    "VNĐ": 50
  },
  {
    country: "Tú",
    "VNĐ": 30
  },
  {
    country: "Thắng",
    "VNĐ": 20
  },
];
export const ChartBarMonthEmploye = [
  {
    country: "Giang",
    "VNĐ": 40
  },
  {
    country: "Tú",
    "VNĐ": 38
  },
  {
    country: "Thắng",
    "VNĐ": 20
  },
];
export const ChartBarYearEmploye = [
  {
    country: "Giang",
    "VNĐ": 60
  },
  {
    country: "Tú",
    "VNĐ": 50
  },
  {
    country: "Thắng",
    "VNĐ": 10
  },
];
export const ChartBarDayDrug = [
  {
    country: "Panadol",
    "VNĐ": 50
  },
  {
    country: "Thuốc cam hàng bạc",
    "VNĐ": 30
  },
  {
    country: "Kem nghệ Thái Dương",
    "VNĐ": 20
  },
];
export const ChartBarMonthDrug = [
  {
    country: "Panadol",
    "VNĐ": 40
  },
  {
    country: "Thuốc cam hàng bạc",
    "VNĐ": 38
  },
  {
    country: "Kem nghệ Thái Dương",
    "VNĐ": 20
  },
];
export const ChartBarYearDrug = [
  {
    country: "Panadol",
    "VNĐ": 60
  },
  {
    country: "Thuốc cam hàng bạc",
    "VNĐ": 50
  },
  {
    country: "Kem nghệ Thái Dương",
    "VNĐ": 10
  },
];
export const dataTable = [
  {
    key: "1",
    codeProduct: "DRG102354",
    quanlity: 32,
    nameProduct: "Thuốc cam hàng bạc"
  },
  {
    key: "2",
    codeProduct: "DRG569842",
    quanlity: 42,
    nameProduct: "Apicilinc"
  },
  {
    key: "3",
    codeProduct: "DRG59716235",
    quanlity: 32,
    nameProduct: "Alpachoay"
  },
  {
    key: "4",
    codeProduct: "DRG102354",
    quanlity: 32,
    nameProduct: "Thuốc cam hàng bạc"
  },
  {
    key: "5",
    codeProduct: "DRG569842",
    quanlity: 42,
    nameProduct: "Apicilinc"
  },
  {
    key: "6",
    codeProduct: "DRG59716235",
    quanlity: 32,
    nameProduct: "Alpachoay"
  }
];
export const dataNoti = [
  {
    key: 1,
    title:
      "Sản phẩm E00000112 - Neo Dexa nhỏ MMT lô sản xuất 1 hết hạn ngày 2019-10-25"
  },
  {
    key: 2,
    title:
      "Sản phẩm E00000112 - Neo Dexa nhỏ MMT lô sản xuất 1 hết hạn ngày 2019-10-25"
  },
  {
    key: 3,
    title:
      "Sản phẩm E00000112 - Neo Dexa nhỏ MMT lô sản xuất 1 hết hạn ngày 2019-10-25"
  }
];


export const dataInvoice = [
  {
    key: "1",
    codeInvoice: "DRG102354",
    customer: "Nguyễn Văn A",
    phonenumber: "0975829402",
    date: "22/12/2019",
    amount: "1200000",
    discount: "80000",
    point: "0",
    payment: "120000",
    status: "Chưa thanh toán"
  },
  {
    key: "2",
    codeInvoice: "DRG102354",
    customer: "Nguyễn Văn A",
    phonenumber: "0975829402",
    date: "22/12/2019",
    amount: "1200000",
    discount: "80000",
    point: "0",
    payment: "120000",
    status: "Hoàn thành"
  },
  {
    key: "3",
    codeInvoice: "DRG102354",
    customer: "Nguyễn Văn A",
    phonenumber: "0975829402",
    date: "22/12/2019",
    amount: "1200000",
    discount: "80000",
    point: "0",
    payment: "120000",
    status: "Hủy"
  },
  {
    key: "4",
    codeInvoice: "DRG102354",
    customer: "Nguyễn Văn A",
    phonenumber: "0975829402",
    date: "22/12/2019",
    amount: "1200000",
    discount: "80000",
    point: "0",
    payment: "120000",
    status: "Trả lại"
  },
  {
    key: "5",
    codeInvoice: "DRG102354",
    customer: "Nguyễn Văn A",
    phonenumber: "0975829402",
    date: "22/12/2019",
    amount: "1200000",
    discount: "80000",
    point: "0",
    payment: "120000",
    status: "Chưa thanh toán"
  },
  {
    key: "6",
    codeInvoice: "DRG102354",
    customer: "Nguyễn Văn A",
    phonenumber: "0975829402",
    date: "22/12/2019",
    amount: "1200000",
    discount: "80000",
    point: "0",
    payment: "120000",
    status: "Chưa thanh toán"
  }
];
export const dataTimeLine = [
  {
    key: 1,
    nameActivity:
      "Nhà thuốc Ecomedic bán đơn hàng INV0123456 trị giá 600.000đ",
    time: "27/07/2019 23:05",
  },
  {
    key: 2,
    nameActivity:
      "Nhà thuốc Ecomedic nhập kho mã phiếu INV0123456 trị giá 600.000đ",
    time: "27/07/2019 23:05",
  },
  {
    key: 3,
    nameActivity:
      "Nhà thuốc Ecomedic xuất kho mã phiếu INV0123456 trị giá 600.000đ",
    time: "27/07/2019 23:05",
  },
  {
    key: 4,
    nameActivity:
      "Nhà thuốc Ecomedic kiểm kho",
    time: "27/07/2019 23:05",
  },
  {
    key: 5,
    nameActivity:
      "Nhà thuốc Ecomedic bán đơn hàng INV0123456 trị giá 600.000đ",
    time: "27/07/2019 23:05",
  },
  {
    key: 6,
    nameActivity:
      "Nhà thuốc Ecomedic bán đơn hàng INV0123456 trị giá 600.000đ",
    time: "27/07/2019 23:05",
  },
  {
    key: 7,
    nameActivity:
      "Nhà thuốc Ecomedic bán đơn hàng INV0123456 trị giá 600.000đ",
    time: "27/07/2019 23:05",
  },
  {
    key: 8,
    nameActivity:
      "Nhà thuốc Ecomedic bán đơn hàng INV0123456 trị giá 600.000đ",
    time: "27/07/2019 23:05",
  }
];
export const dataInvoiceDetail = [
  {
    key: 1,
    codeDrug: "S00001",
    nameFrug: "Thuốc Cam Hàng Bạc",
    lot: "1234466",
    quantityExists: 120,
    dosage: "Ngày uống 2 lần",
    unit: [
      { key: 1, unitname: "Viên" },
      { key: 2, unitname: "Vỉ" }
    ],
    price: 120000,
    amount: 12000,
    discount: 1222,
    money: 568742,
    vat: "0%"
  },
  {
    key: 2,
    codeDrug: "S00001",
    nameFrug: "Thuốc Cam Hàng Bạc",
    lot: "1234466",
    quantityExists: 120,
    dosage: "Ngày uống 2 lần",
    unit: [{ key: 1, unitname: "Viên" }],
    price: 120000,
    amount: 12000,
    discount: 1222,
    money: 568742,
    vat: "0%"
  },
  {
    key: 3,
    codeDrug: "S00001",
    nameFrug: "Thuốc Cam Hàng Bạc",
    lot: "1234466",
    quantityExists: 120,
    dosage: "Ngày uống 2 lần",
    unit: [{ key: 1, unitname: "Viên" }],
    price: 120000,
    amount: 12000,
    discount: 1222,
    money: 568742,
    vat: "0%"
  }
];
export const dataInvoiceAdd = [
  {
    key: 1,
    promotion: true,
    codeDrug: "S00001",
    nameDrug: "Thuốc Beroca",
    lot: 2323412,
    exist: 232,
    dosage: "",
    unit: [{ key: 1, unitname: "Viên" }],
    price: 120000,
    amount: "",
    discount: "",
    money: ""
  }
];
export const dataCustomer = [
  {
    key: 1,
    codeCustomer: "CUS0123456789",
    nameCustomer: "Nguyễn Văn A",
    phone: "0123456789",
    email: "nguyenvananh@gmail.com",
    gender: "Nam",
    birthday: "21/12/2019",
    typeCustomer: "Khách lẻ",
    sales: "1200000"
  },
  {
    key: 2,
    codeCustomer: "CUS0123456789",
    nameCustomer: "Nguyễn Văn A",
    phone: "0123456789",
    email: "nguyenvananh@gmail.com",
    gender: "Nam",
    birthday: "21/12/2019",
    typeCustomer: "Khách lẻ",
    sales: 1200000
  },
  {
    key: 3,
    codeCustomer: "CUS0123456789",
    nameCustomer: "Nguyễn Văn A",
    phone: "0123456789",
    email: "nguyenvananh@gmail.com",
    gender: "Nam",
    birthday: "21/12/2019",
    typeCustomer: "Khách lẻ",
    sales: "1200000"
  },
  {
    key: 4,
    codeCustomer: "CUS0123456789",
    nameCustomer: "Nguyễn Văn A",
    phone: "0123456789",
    email: "nguyenvananh@gmail.com",
    gender: "Nam",
    birthday: "21/12/2019",
    typeCustomer: "Khách lẻ",
    sales: "1200000"
  },
  {
    key: 5,
    codeCustomer: "CUS0123456789",
    nameCustomer: "Nguyễn Văn A",
    phone: "0123456789",
    email: "nguyenvananh@gmail.com",
    gender: "Nam",
    birthday: "21/12/2019",
    typeCustomer: "Khách lẻ",
    sales: "1200000"
  }
];

export const unitData = [
  {
    type: "Đơn vị cơ bản",
    unit: "Viên",
    value: "Viên",
    price: 20000,
    alert: 200
  },
  {
    type: "Đơn vị quy đổi",
    unit: "Viên",
    value: "Viên",
    price: 20000,
    alert: 200
  }
];
export const dataCountry = [
  { "name": "Hà Nội", "code": "01" },
  { "name": "Hồ Chí Minh", "code": "79" },
  { "name": "Đà Nẵng", "code": "48" },
  { "name": "An Giang", "code": "89" },
  { "name": "Bà Rịa - Vũng Tàu", "code": "77" },
  { "name": "Bình Dương", "code": "74" },
  { "name": "Bình Phước", "code": "70" },
  { "name": "Bình Thuận", "code": "60" },
  { "name": "Bình Định", "code": "52" },
  { "name": "Bạc Liêu", "code": "95" },
  { "name": "Bắc Giang", "code": "24" },
  { "name": "Bắc Kạn", "code": "06" },
  { "name": "Bắc Ninh", "code": "27" },
  { "name": "Bến Tre", "code": "83" },
  { "name": "Cao Bằng", "code": "04" },
  { "name": "Cà Mau", "code": "96" },
  { "name": "Cần Thơ", "code": "92" },
  { "name": "Điện Biên", "code": "11" },
  { "name": "Đắk Lắk", "code": "66" },
  { "name": "Đắk Nông", "code": "67" },
  { "name": "Đồng Nai", "code": "75" },
  { "name": "Đồng Tháp", "code": "87" },
  { "name": "Gia Lai", "code": "64" },
  { "name": "Hoà Bình", "code": "17" },
  { "name": "Hà Giang", "code": "02" },
  { "name": "Hà Nam", "code": "35" },
  { "name": "Hà Tĩnh", "code": "42" },
  { "name": "Hưng Yên", "code": "33" },
  { "name": "Hải Dương", "code": "30" },
  { "name": "Hải Phòng", "code": "31" },
  { "name": "Hậu Giang", "code": "93" },
  { "name": "Khánh Hòa", "code": "56" },
  { "name": "Kiên Giang", "code": "91" },
  { "name": "Kon Tum", "code": "62" },
  { "name": "Lai Châu", "code": "12" },
  { "name": "Long An", "code": "80" },
  { "name": "Lào Cai", "code": "10" },
  { "name": "Lâm Đồng", "code": "68" },
  { "name": "Lạng Sơn", "code": "20" },
  { "name": "Nam Định", "code": "36" },
  { "name": "Nghệ An", "code": "40" },
  { "name": "Ninh Bình", "code": "37" },
  { "name": "Ninh Thuận", "code": "58" },
  { "name": "Phú Thọ", "code": "25" },
  { "name": "Phú Yên", "code": "54" },
  { "name": "Quảng Bình", "code": "44" },
  { "name": "Quảng Nam", "code": "49" },
  { "name": "Quảng Ngãi", "code": "51" },
  { "name": "Quảng Ninh", "code": "22" },
  { "name": "Quảng Trị", "code": "45" },
  { "name": "Sóc Trăng", "code": "94" },
  { "name": "Sơn La", "code": "14" },
  { "name": "Thanh Hóa", "code": "38" },
  { "name": "Thái Bình", "code": "34" },
  { "name": "Thái Nguyên", "code": "19" },
  { "name": "Thừa Thiên Huế", "code": "46" },
  { "name": "Tiền Giang", "code": "82" },
  { "name": "Trà Vinh", "code": "84" },
  { "name": "Tuyên Quang", "code": "08" },
  { "name": "Tây Ninh", "code": "72" },
  { "name": "Vĩnh Long", "code": "86" },
  { "name": "Vĩnh Phúc", "code": "26" },
  { "name": "Yên Bái", "code": "15" }
]
export const dataCustomerSeclect = [
  {
    key: 1,
    name: "Nguyễn Văn Anh",
    phone: "0975829402"
  },
  {
    key: 2,
    name: "Nguyễn Văn Tí",
    phone: "0123456789"
  },
  {
    key: 3,
    name: "Bùi Minh Đức",
    phone: "987654321"
  }
]
export const dataSupplierSeclect = [
  {
    key: 1,
    name: "Nhà thuốc Sao Thái Dương",
    phone: "0975829402"
  },
  {
    key: 2,
    name: "Dược phầm Trung Ương III",
    phone: "0123456789"
  },
  {
    key: 3,
    name: "Công ty dược Decotra",
    phone: "987654321"
  }
]
export const dataDrug = [
  {
    key: 1,
    codeDrug: "S00001",
    nameDrug: "Thuốc Cam Hàng Bạc",
    lot: "1234466",
    quantityExists: 120,
    dosage: "",
    unit: [
      { key: 1, unitname: "Viên", price: 20000 },
      { key: 2, unitname: "Vỉ", price: 5000 }
    ],
    price: 120000,
    amount: "",
    discount: "",
    money: "",
    vat: "0%"
  },
  {
    key: 2,
    codeDrug: "S00002",
    nameDrug: "Thuốc paratamol",
    lot: "1234466",
    quantityExists: 120,
    dosage: "",
    unit: [{ key: 1, unitname: "Viên", price: 2500 },
    { key: 2, unitname: "Bao", price: 5000 }],
    price: 120000,
    amount: "",
    discount: "",
    money: "",
    vat: "0%"
  },
  {
    key: 3,
    codeDrug: "S00003",
    nameDrug: "Thuốc dưỡng da nghệ Thái Dương",
    lot: "1234466",
    quantityExists: 120,
    dosage: "",
    unit: [{ key: 1, unitname: "Viên", price: 5000 }],
    price: 120000,
    amount: "",
    discount: "",
    money: "",
    vat: "0%"
  }
]
export const dataBillInventoryImport = [
  {
    key: "1",
    codeIVNImport: "DRG102354",
    ImportType: "Kiểm kho",
    supplier: "Cure Medicines(I)",
    sumAmount: 120000,
    referenceCode: "",
    date: "30/01/2019",
    time: "14:05:2019",
    timeUpdate: "14:09:10 - 30/01/2019",
    status: "Chờ duyệt"
  },
  {
    key: "2",
    codeIVNImport: "DRG102354",
    ImportType: "Kiểm kho",
    supplier: "Cure Medicines(I)",
    sumAmount: 120000,
    referenceCode: "",
    date: "30/01/2019",
    time: "14:05:2019",
    timeUpdate: "14:09:10 - 30/01/2019",
    status: "Hoàn thành"
  },
  {
    key: "3",
    codeIVNImport: "DRG102354",
    ImportType: "Kiểm kho",
    supplier: "Cure Medicines(I)",
    sumAmount: 120000,
    referenceCode: "",
    date: "30/01/2019",
    time: "14:05:2019",
    timeUpdate: "14:09:10 - 30/01/2019",
    status: "Đã hủy"
  }
]
export const dataBillInventoryImportAfter = [
  {
    key: "4",
    codeIVNImport: "DRG102354",
    ImportType: "Kiểm kho",
    supplier: "Cure Medicines(I)",
    sumAmount: 120000,
    referenceCode: "Thuốc nhập từ excel",
    date: "30/01/2019",
    time: "14:05:2019",
    timeUpdate: "14:09:10 - 30/01/2019",
    status: "Chờ duyệt"
  },
  {
    key: "5",
    codeIVNImport: "DRG102354",
    ImportType: "Kiểm kho",
    supplier: "Cure Medicines(I)",
    sumAmount: 120000,
    referenceCode: "Thuốc nhập từ excel",
    date: "30/01/2019",
    time: "14:05:2019",
    timeUpdate: "14:09:10 - 30/01/2019",
    status: "Hoàn thành"
  },
  {
    key: "6",
    codeIVNImport: "DRG102354",
    ImportType: "Kiểm kho",
    supplier: "Cure Medicines(I)",
    sumAmount: 120000,
    referenceCode: "Thuốc nhập từ excel",
    date: "30/01/2019",
    time: "14:05:2019",
    timeUpdate: "14:09:10 - 30/01/2019",
    status: "Đã hủy"
  }
]
export const dataDetailImport = [
  {
    key: 1,
    codeDrug: "E00000001",
    nameDrug: "Thuốc Berocca",
    dateOfManufacture: "21/12/2019",
    lot: "01293234",
    expiryDate: "09/12/2021",
    unit: "Lọ",
    priceImport: 18000,
    priceSell: [
      { key: 1, unit: "Lọ", priceSell: 20000, vat: "5%" },
      { key: 2, unit: "Chai", priceSell: 15000, vat: "10%" }
    ],
    amount: 15,
    totalMoney: 15000,
    vat: "10%"
  },
  {
    key: 2,
    codeDrug: "E00000002",
    nameDrug: "Thuốc Cam Hàng Bạc",
    dateOfManufacture: "21/12/2019",
    lot: "01293234",
    expiryDate: "09/12/2021",
    unit: "Lọ",
    priceImport: 18000,
    priceSell: [
      { key: 1, unit: "Lọ", priceSell: 20000, vat: "5%" },
      { key: 2, unit: "Vỉ", priceSell: 15000, vat: "10%" }
    ],
    amount: 15,
    totalMoney: 15000,
    vat: "10%"
  }
]
export const dataDrugImport = [
  {
    key: 1,
    codeDrug: "S000001",
    nameDrug: "Thuốc cam hàng bạc",
    lot: "",
    expiryDate: "",
    unit: [
      { key: 1, unitname: "Viên" },
      { key: 2, unitname: "Vỉ" },
      { key: 3, unitname: "Hộp" }
    ],
    amount: "",
    priceImport: "",
    priceSell: [
      { key: 1, unitname: "Viên", price: "", vat: "" },
      { key: 2, unitname: "Vỉ", price: "", vat: "" },
      { key: 3, unitname: "Hộp", price: "", vat: "" }
    ],
    vat: "",
    totalmoney: ""
  },
  {
    key: 2,
    codeDrug: "S000002",
    nameDrug: "Thuốc paratamol",
    lot: "",
    expiryDate: "",
    unit: [
      { key: 1, unitname: "Viên" },
      { key: 2, unitname: "Vỉ" },
      { key: 3, unitname: "Hộp" }
    ],
    amount: "",
    priceImport: "",
    priceSell: [
      { key: 1, unitname: "Viên", price: "", vat: "" },
      { key: 2, unitname: "Vỉ", price: "", vat: "" },
      { key: 3, unitname: "Hộp", price: "", vat: "" }
    ],
    vat: "",
    totalmoney: ""
  },
  {
    key: 3,
    codeDrug: "S000003",
    nameDrug: "Hoạt huyết dưỡng lão",
    lot: "",
    expiryDate: "",
    unit: [
      { key: 1, unitname: "Viên" },
      { key: 2, unitname: "Vỉ" },
      { key: 3, unitname: "Hộp" }
    ],
    amount: "",
    priceImport: "",
    priceSell: [
      { key: 1, unitname: "Viên", price: "", vat: "" },
      { key: 2, unitname: "Vỉ", price: "", vat: "" },
      { key: 3, unitname: "Hộp", price: "", vat: "" }
    ],
    vat: "",
    totalmoney: ""
  }
]
export const dataDrugImportCategory = [
  {
    key: 10,
    codeDrug: "S000324",
    nameDrug: "Thuốc từ danh mục 1",
    lot: "",
    expiryDate: "",
    unit: [
      { key: 1, unitname: "Viên" },
      { key: 2, unitname: "Vỉ" },
      { key: 3, unitname: "Hộp" }
    ],
    amount: "",
    priceImport: "",
    priceSell: [
      { key: 1, unitname: "Viên", price: "", vat: "" },
      { key: 2, unitname: "Vỉ", price: "", vat: "" },
      { key: 3, unitname: "Hộp", price: "", vat: "" }
    ],
    vat: "",
    totalmoney: ""
  },
  {
    key: 11,
    codeDrug: "S00032002",
    nameDrug: "Thuốc từ danh mục 2",
    lot: "",
    expiryDate: "",
    unit: [
      { key: 1, unitname: "Viên" },
      { key: 2, unitname: "Vỉ" },
      { key: 3, unitname: "Hộp" }
    ],
    amount: "",
    priceImport: "",
    priceSell: [
      { key: 1, unitname: "Viên", price: "", vat: "" },
      { key: 2, unitname: "Vỉ", price: "", vat: "" },
      { key: 3, unitname: "Hộp", price: "", vat: "" }
    ],
    vat: "",
    totalmoney: ""
  },
  {
    key: 12,
    codeDrug: "S002320003",
    nameDrug: "Thuốc từ danh mục 3",
    lot: "",
    expiryDate: "",
    unit: [
      { key: 1, unitname: "Viên" },
      { key: 2, unitname: "Vỉ" },
      { key: 3, unitname: "Hộp" }
    ],
    amount: "",
    priceImport: "",
    priceSell: [
      { key: 1, unitname: "Viên", price: "", vat: "" },
      { key: 2, unitname: "Vỉ", price: "", vat: "" },
      { key: 3, unitname: "Hộp", price: "", vat: "" }
    ],
    vat: "",
    totalmoney: ""
  }
]
export const unit_name = [
  {
    "code": "030",
    "type": "DRUG_UNIT",
    "value": "Bánh",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "BÁNH"
  },
  {
    "code": "011",
    "type": "DRUG_UNIT",
    "value": "Bao",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "BAO"
  },
  {
    "code": "022",
    "type": "DRUG_UNIT",
    "value": "Bịch",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "BỊCH"
  },
  {
    "code": "037",
    "type": "DRUG_UNIT",
    "value": "Bình",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "BÌNH"
  },
  {
    "code": "035",
    "type": "DRUG_UNIT",
    "value": "Bộ",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "BỘ"
  },
  {
    "code": "028",
    "type": "DRUG_UNIT",
    "value": "Bút",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "BÚT"
  },
  {
    "code": "001",
    "type": "DRUG_UNIT",
    "value": "Cái",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "CÁI"
  },
  {
    "code": "038",
    "type": "DRUG_UNIT",
    "value": "Can",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "CAN"
  },
  {
    "code": "048",
    "type": "DRUG_UNIT",
    "value": "Cân",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "CÂN"
  },
  {
    "code": "002",
    "type": "DRUG_UNIT",
    "value": "Chai",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "CHAI"
  },
  {
    "code": "003",
    "type": "DRUG_UNIT",
    "value": "Chiếc",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "CHIẾC"
  },
  {
    "code": "033",
    "type": "DRUG_UNIT",
    "value": "Cọc",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "CỌC"
  },
  {
    "code": "004",
    "type": "DRUG_UNIT",
    "value": "Cuộn",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "CUỘN"
  },
  {
    "code": "045",
    "type": "DRUG_UNIT",
    "value": "Dải",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "DẢI"
  },
  {
    "code": "021",
    "type": "DRUG_UNIT",
    "value": "Dây",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "DÂY"
  },
  {
    "code": "026",
    "type": "DRUG_UNIT",
    "value": "Đôi",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "ĐÔI"
  },
  {
    "code": "005",
    "type": "DRUG_UNIT",
    "value": "Gói",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "GÓI"
  },
  {
    "code": "044",
    "type": "DRUG_UNIT",
    "value": "Hòm",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "HÒM"
  },
  {
    "code": "006",
    "type": "DRUG_UNIT",
    "value": "Hộp",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "HỘP"
  },
  {
    "code": "024",
    "type": "DRUG_UNIT",
    "value": "Hộp con",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "HỘP CON"
  },
  {
    "code": "047",
    "type": "DRUG_UNIT",
    "value": "Hũ",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "HŨ"
  },
  {
    "code": "043",
    "type": "DRUG_UNIT",
    "value": "Khay",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "KHAY"
  },
  {
    "code": "049",
    "type": "DRUG_UNIT",
    "value": "Kiện",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "KIỆN"
  },
  {
    "code": "029",
    "type": "DRUG_UNIT",
    "value": "Kim",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "KIM"
  },
  {
    "code": "046",
    "type": "DRUG_UNIT",
    "value": "Kit",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "KIT"
  },
  {
    "code": "039",
    "type": "DRUG_UNIT",
    "value": "Liều",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "LIỀU"
  },
  {
    "code": "007",
    "type": "DRUG_UNIT",
    "value": "Lọ",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "LỌ"
  },
  {
    "code": "034",
    "type": "DRUG_UNIT",
    "value": "Lon",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "LON"
  },
  {
    "code": "042",
    "type": "DRUG_UNIT",
    "value": "Lốc",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "LỐC"
  },
  {
    "code": "050",
    "type": "DRUG_UNIT",
    "value": "Máy",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "MÁY"
  },
  {
    "code": "025",
    "type": "DRUG_UNIT",
    "value": "Miếng",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "MIẾNG"
  },
  {
    "code": "040",
    "type": "DRUG_UNIT",
    "value": "Nang",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "NANG"
  },
  {
    "code": "036",
    "type": "DRUG_UNIT",
    "value": "Ngày",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "NGÀY"
  },
  {
    "code": "008",
    "type": "DRUG_UNIT",
    "value": "Ống",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "ỐNG"
  },
  {
    "code": "041",
    "type": "DRUG_UNIT",
    "value": "Ống tiêm",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "ỐNG TIÊM"
  },
  {
    "code": "032",
    "type": "DRUG_UNIT",
    "value": "Quả",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "QUẢ"
  },
  {
    "code": "031",
    "type": "DRUG_UNIT",
    "value": "Que",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "QUE"
  },
  {
    "code": "051",
    "type": "DRUG_UNIT",
    "value": "Tép",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "TÉP"
  },
  {
    "code": "027",
    "type": "DRUG_UNIT",
    "value": "Thỏi",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "THỎI"
  },
  {
    "code": "023",
    "type": "DRUG_UNIT",
    "value": "Thùng",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "THÙNG"
  },
  {
    "code": "020",
    "type": "DRUG_UNIT",
    "value": "Túi",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "TÚI"
  },
  {
    "code": "009",
    "type": "DRUG_UNIT",
    "value": "Tuýp",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "TUÝP"
  },
  {
    "code": "010",
    "type": "DRUG_UNIT",
    "value": "Vỉ",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "VỈ"
  },
  {
    "code": "013",
    "type": "DRUG_UNIT",
    "value": "Viên",
    "seo_url": null,
    "note": "Đơn vị",
    "language": "VI",
    "uvalue": "VIÊN"
  }
]
export const dataInventoryCheck = [
  {
    key: 1,
    codeCheck: 'S000001',
    date: "22/12/2019",
    user: "Nguyễn Văn Tí"
  },
  {
    key: 2,
    codeCheck: 'S000001',
    date: "22/12/2019",
    user: "Nguyễn Văn Tí"
  },
  {
    key: 3,
    codeCheck: 'S000001',
    date: "22/12/2019",
    user: "Nguyễn Văn Tí"
  }
]
export const dataInventory = [
  {
    code: "IEP00456",
    type: "Kiểm kho",
    date: "31/10/2019",
    warehouse: "Kho Medlink",
    implementer: "Nhân viên bán hàng",
    status: "Hủy"
  },
  {
    code: "IEP00123",
    type: "Bán hàng",
    date: "31/10/2019",
    warehouse: "Kho Medlink",
    implementer: "Nhân viên bán hàng",
    status: "Hoàn thành"
  },
  {
    code: "IEP00789",
    type: "Kiểm kho",
    date: "31/10/2019",
    warehouse: "Kho Medlink",
    implementer: "Nhân viên bán hàng",
    status: "Chờ duyệt"
  },
  {
    code: "IEP00147",
    type: "Bán hàng",
    date: "31/10/2019",
    warehouse: "Kho Medlink",
    implementer: "Nhân viên bán hàng",
    status: "Hoàn thành"
  },
];

export const dataDrugExport = [
  {
    key: 1,
    codeDrug: "S00001",
    nameDrug: "Thuốc Cam Hàng Bạc",
    lot: "1234466",
    quantityExists: 120,
    expiryDate: "30/12/2020",
    number: "",
    unit: [
      { key: 1, unitname: "Viên", price: 20000 },
      { key: 2, unitname: "Vỉ", price: 5000 }
    ],
    price: 120000,
    amount: "",
    money: "",
    unitState: ""
  },
  {
    key: 2,
    codeDrug: "S00002",
    nameDrug: "Thuốc paratamol",
    lot: "1234466",
    quantityExists: 120,
    expiryDate: "30/12/2020",
    number: "",
    unit: [
      { key: 1, unitname: "Viên", price: 2500 },
      { key: 2, unitname: "Bao", price: 5000 }
    ],
    price: 120000,
    amount: "",
    money: "",
    unitState: ""
  }
]
export const allSupplier = [
  {
    key: 1,
    codeSupplier: "NCC000149",
    provider_name: "Traphaco",
    update: "17:28:27 30/10/2019",
    liabilities: "0",
    totalBuy: "0",
    phone_no: '0975829402'
  },
  {
    key: 2,
    codeSupplier: "NCC000149",
    provider_name: "Decotra",
    update: "17:28:27 30/10/2019",
    liabilities: "0",
    totalBuy: "0",
    phone_no: '0975829402'
  },
  {
    key: 1,
    codeSupplier: "NCC000149",
    provider_name: "Traphaco",
    update: "17:28:27 30/10/2019",
    liabilities: "0",
    totalBuy: "0",
    phone_no: '0975829402'
  }
]
export const sex = [
  { key: 0, value: "Nam", code: "M" },
  { key: 1, value: "Nữ", code: "W" },
];
export const typeCustomer = [
  { key: 0, value: "Cá nhân", code: 'consumer' },
  { key: 1, value: "Công ty", code: 'company' }
];

export const typePointOfSale = [
  { key: 0, value: "Cá nhân", code: 'consumer' },
  { key: 1, value: "Công ty", code: 'company' },
  { key: 2, value: "Nhà thuốc", code: 'pharmacy' },
  { key: 3, value: 'Phòng khám', code: 'clinic' },
  { key: 4, value: 'Khác', code: 'other' },
]

export const customerResources = [
  { key: 0, value: "Điện thoại" },
  { key: 1, value: "Facebook" },
  { key: 2, value: "Qua giới thiệu" },
  { key: 3, value: "Trực tiếp" },
  { key: 4, value: "Website" }
];
export const numberPage = [
  20, 50, 100, 200
]
export const department = [
  { key: 0, value: "Chủ sở hữu", role: "OWNER" },
  { key: 1, value: "Quản trị", role: "ADMIN" },
  { key: 2, value: "Nhân viên", role: "MEMBER" },
  { key: 3, value: "Trưởng quầy", role: "HEAD" },
  { key: 4, value: "Kế toán", role: "ACCOUNTING" }
];
export const statusUser = [
  { key: 0, value: "Không hoạt động", },
  { key: 1, value: "Đang hoạt động", },
]
var currentdate = new Date();
export const TIMESTAMP = currentdate.getHours() + ":"
  + currentdate.getMinutes() + ":" + currentdate.getSeconds()
var date = new Date().getDate();
var month = new Date().getMonth() + 1;
var year = new Date().getFullYear();
export const FULLTIME = year + "-" + month + "-" + date;
export const STATUS = ["Tất cả", "Chờ duyệt", "Hoàn thành", "Đã hủy"];
export const VAT = [
  { index: 0, value: 0 },
  { index: 1, value: 5 },
  { index: 2, value: 8 },
  { index: 3, value: 10 }
]

export const role_cd = [
  { key: 0, value: "Quản trị" },
  { key: 1, value: "Nhân viên" },
  { key: 2, value: "Trưởng quầy" },
  { key: 3, value: "Kế toán" }
];

export const BANNER_DETAIL = {
  AFFILIATE: {
    title: "Giới thiệu khách hàng, quà tặng hấp dẫn",
    userApply: `Khách cũ: Là khách đã ký hợp đồng và sử dụng phần mềm quản lý Medlink`,
    userApply2: `Khách mới (Khách được giới thiệu): Là thông tin khách chưa tồn tại trên hệ thống Medlink, khách chưa từng biết đến và chưa từng sử dụng phần mềm quản lý Medlink`,
    joinMethod: "Giới thiệu phần mềm Medlink tới các nhà thuốc có nhu cầu sử dụng phần mềm quản lý",
    rule: "Hợp đồng KHÁCH CŨ phát sinh trước ngày ký hợp đồng KHÁCH MỚI",
    rule2: `Hoa hồng được hưởng trên KHÁCH MỚI, KHÔNG áp dụng trên HỢP ĐỒNG MỚI: Với mỗi một trường hợp giới thiệu Khách mới thành công,
     Khách cũ chỉ được nhận thưởng một lần duy nhất ngay sau lần đầu tiên Khách mới ký hợp đồng với Medlink:`,
    rule2_1: "Không tính hợp đồng thứ 2 trở đi",
    rule2_2: "Khách hàng được giới thiệu ký nhiều chi nhánh, mở rộng chuỗi nhà thuốc ngay lần đầu tiên ký hợp đồng với Medlink thì chỉ được tính là một Khách mới",
    rule3: "Hợp đồng được giới thiệu giá trị 01 năm trở lên",
    rule4: `Trường hợp Khách được giới thiệu trùng với nguồn giới thiệu khác của Medlink: 
    Ghi nhận thưởng đối với Khách hàng mới có nguồn đầu tiên là khách hàng được giới thiệu`,
    rule5: `Để được tính là một trường hợp giới thiệu Khách hàng mới hợp lệ, nhân viên kinh doanh cần thực hiện nhập thông tin Khách hàng lên hệ thống`,
    contentTitle: `Giới thiệu cho Medlink các nhà thuốc có nhu cầu sử dụng phần mềm quản lý, 
    Khách cũ sẽ được nhận 10% - 15% giá trị hợp đồng và 03 tháng sử dụng phần mềm, cụ thể như sau:`,
    content1: "Khi Khách cũ giới thiệu dưới 03 khách mới ký Hợp đồng với Medlink, khách cũ sẽ được nhận 10% giá trị hợp đồng và 03 tháng sử dụng phần mềm",
    content2: "Khi Khách cũ giới thiệu trên 03 khách mới ký Hợp đồng với Medlink, khách cũ sẽ được nhận 15% giá trị hợp đồng và 03 tháng sử dụng phần mềm",
    content3: "Khách mới ký hợp đồng cũng sẽ được tặng thêm 03 tháng sử dụng phần mềm",
    timeApply: "Chương trình áp dụng từ ngày 01/11/2021 đến hết ngày 31/12/2021",
    note: "BTC có quyền thay đổi nội dung, điều chỉnh chương trình vào từng thời điểm phù hợp theo pháp luật hiện hành."
  },
  ONLINE_PHARMACY: {
    title: "Xây dựng nhà thuốc trực tuyến",
    userApply: "Khách hàng có nhu cầu mở rộng danh sách khách hàng, tăng doanh thu cho nhà thuốc bằng hình thức kinh doanh online",
    joinMethod: "Điền đầy đủ theo google form sau: ",
    contentTitle: "",
    content1: "",
    content2: "",
    content3: "",
    timeApply: ""
  }
}

export const TEMPLATE_MESSAGE = {
  CAMPAIGN: 'Cam on anh chi da mua hang tai Medlink. Neu co bat ky kho khan nao trong viec su dung san pham, vui long lien he den hotline 0969.191.355 de duoc tu van truc tiep. Xem chi tiet tai medlink.vn!'
}

export const LIST_INFO_PACKAGE = [
  {
    package_id: 1,
    package_name: 'Gói miễn phí',
    package_code: 'PHARMACY_FREE',
    package_code_primary: 'FREE',
    description: 'Dành cho nhà thuốc nhỏ, nhà thuốc mới. Đầy đủ tính năng quản lý nhà thuốc',
    price: '0',
    count_user: '01',
    multi_device: 'Trọn đời trên Đa thiết bị (Máy tính, điện thoại, máy tính bảng, ...)',
    modules: [
      {
        module_code: '"PRODUCT"',
        description: 'Quản lý sản phẩm',
      },
      {
        module_code: '"INVOICE"',
        description: 'Quản lý hóa đơn, bán hàng',
      },
      {
        module_code: 'INVENTORY',
        description: 'Quản lý kho hàng',
      },
      {
        module_code: 'PARTNER',
        description: 'Quản lý khách hàng, nhà cung cấp',
      },
      {
        module_code: 'REPORT',
        description: 'Báo cáo thống kê doanh thu, lợi nhuận, hàng tồn,...',
      },
      {
        module_code: 'EXPIRYDATE',
        description: 'Cảnh báo tồn kho, cận date, ...',
      },
      {
        module_code: 'REPORT_GPP',
        description: 'Báo cáo hồ sơ GPP',
      },
      {
        module_code: 'LIMIT_ACCOUNT',
        description: 'Giới hạn 01 tài khoản',
      },
      {
        module_code: 'LIMIT_PRODUCT',
        description: 'Giới hạn 1000 sản phẩm',
      },
    ],
  },
  {
    package_id: 2,
    package_name: 'Gói cơ bản',
    package_code: 'PHARMACY_BASIC',
    package_code_primary: 'BASIC',
    description: 'Dành cho nhà thuốc truyền thống. Áp dụng công nghệ cho quản lý nhà thuốc',
    price: '150,000',
    count_user: '02',
    multi_device: '/ 01 Tháng trên Đa thiết bị (Máy tính, điện thoại, máy tính bảng, ...)',
    modules: [
      {
        module_code: 'ALL_FEATURE_FREE',
        description: 'Toàn bộ tính năng của gói miễn phí',
      },
      {
        module_code: 'SYNC',
        description: 'Liên thông dược quốc gia',
      },
      {
        module_code: 'MARKETING',
        description: 'Tích hợp hệ thống marketing',
      },
      {
        module_code: 'LOYALTY',
        description: 'Tích hợp hệ thống tích điểm',
      },
      {
        module_code: 'CASHBOOK',
        description: 'Quản lý sổ quỹ công nợ',
      },
      {
        module_code: 'LIMIT_ACCOUNT',
        description: 'Giới hạn 02 tài khoản',
      },
      {
        module_code: 'LIMIT_PRODUCT',
        description: 'Giới hạn 5000 sản phẩm',
      }
    ],
  },
  {
    package_id: 3,
    package_name: 'Gói nâng cao',
    package_code: 'PHARMACY_PRO',
    package_code_primary: 'PRO',
    description: 'Dành cho nhà thuốc chuyên nghiệp, hệ thống nhà thuốc chuỗi',
    price: '250,000',
    count_user: 'Không giới hạn',
    multi_device: '/ 01 Tháng trên Đa thiết bị (Máy tính, điện thoại, máy tính bảng, ...)',
    modules: [
      {
        module_code: 'ALL_FEATURE_BASIC',
        description: 'Toàn bộ tính năng gói cơ bản',
      },
      {
        module_code: 'UNLIMIT_PRODUCT',
        description: 'Không giới hạn sản phẩm',
      },
      {
        module_code: 'UNLIMIT_ACCOUNT',
        description: 'Không giới hạn số lượng tài khoản',
      },
      {
        module_code: 'SUB_STORES',
        description: 'Quản lí hệ thống chuỗi',
      },
      {
        module_code: 'ADD_BRANCH',
        description: 'Thêm chi nhánh 150,000 đ/ 1 tháng/ 1 chi nhánh',
      }
    ],
  }
]

export const LIST_DRUG_SAMPLE = [
  {
    account_id: 0,
    active_flg: 1,
    active_ingredient: "Cafein, Paracetamol",
    administration: "",
    adverse_reaction: "",
    atc_code: "",
    avatar_url: "",
    barcode_url: "",
    company_code: "",
    company_name: "Sanofi (Pháp)",
    concentration: "Paracetamol 500mg, Caffeine 65mg",
    contraindication: "Paracetamol - Caffein chống chỉ định trên những bệnh nhân có tiền sử quá mẫn với paracetamol, caffeine hoặc với bất kỳ tá dược nào của thuốc",
    country: "",
    created_date: "",
    description: "Viên nén",
    direction_for_use: "Chỉ dùng đường uống.",
    dosage: "Liều tối đa hàng ngày: 4000mg/520mg (paracetamol/ caffeine). Thời gian tối thiểu dùng liều lặp lại: 4 giờ. Trẻ em dưới 12 tuổi: không khuyến nghị dùng thuốc này cho trẻ em dưới 12 tuổi.",
    drg_barcode: "8934612002059",
    drg_drug_alias: "",
    drg_drug_cd: "",
    drg_drug_name: "Panadol Extra giảm đau, hạ sốt (15 vỉ x 12 viên)",
    drg_ref_cd: "DQG00012530",
    drug_classified: "",
    drug_flg: "",
    drug_group: "GR06",
    drug_id: 0,
    drug_kind: "OTC",
    drug_units:
      [
        {
          active_flg: 1,
          default_flg: 1,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 120,
          max_price: 0,
          parent_unit: 14,
          parent_unit_name: "Viên",
          price: 1500,
          import_price: 0,
          price_before_vat: 1500,
          status: 1,
          unit_id: 14,
          unit_name: "Viên",
          unit_qty: 1,
          wholesale_price: 1200,
          wholesale_price_before_vat: 1200,
        },
        {
          active_flg: 1,
          default_flg: 0,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 10,
          max_price: 0,
          parent_unit: 14,
          parent_unit_name: "Vỉ",
          price: 18000,
          import_price: 0,
          price_before_vat: 18000,
          status: 1,
          unit_id: 2,
          unit_name: "Vỉ",
          unit_qty: 12,
          wholesale_price: 14400,
          wholesale_price_before_vat: 14400,
        }
      ],
    employee_commissions: 0,
    image_number: 0,
    image_url: "https://medlinknhathuoclongdev.s3.ap-southeast-1.amazonaws.com/1666588791864_1.png",
    indication: "Hạ sốt, giảm đau",
    interation: "B1 100mg; B6 200mg; B12 200mcg",
    key: 0,
    license_cd: "VD-21189-14",
    loyalty_flg: 0,
    moisture: "Nơi khô ráo",
    note: "Không dùng cho phụ nữ có thai và cho con bú",
    original_product: "Việt Nam",
    overdosage: "Tham khảo ý kiến bác sĩ",
    package_desc: "Vỉ x 12 viên",
    precaution: "Để xa tầm tay trẻ em",
    prefix: "S",
    provider_code: "",
    provider_name: "",
    ref_code: "",
    revision: 0,
    source: "",
    special_control: "",
    status: 1,
    storage_temperature: "",
    updated_date: "",
    updated_user: "Admin",
    validate: true,
    vat_percent: 0
  },
  {
    account_id: 0,
    active_flg: 1,
    active_ingredient: "Chymotrypsin",
    administration: "",
    adverse_reaction: "",
    atc_code: "",
    avatar_url: "",
    barcode_url: "",
    company_code: "Sanofi (Pháp)",
    company_name: "The United Drug (1996) Co.,Ltd.",
    concentration: "25 đơn vị C.Hb/viên",
    contraindication: "Dị ứng với các thành phần của thuốc",
    country: "",
    created_date: "",
    description: "Viên nén",
    direction_for_use: "Dùng đường uống, hoặc ngậm dưới lưỡi",
    dosage: "Uống một lần 2 viên, 3 hoặc 4 lần trong ngày, nên uống với nhiều nước (ít nhất 8 oz tương đương 240 ml) nhằm giúp gia tăng hoạt tính men. Ngậm dưới lưỡi: 4 đến 6 viên chia đều ra trong ngày",
    drg_barcode: "8934612002060",
    drg_drug_alias: "",
    drg_drug_cd: "",
    drg_drug_name: "Alpha Chymotrypsine Choay (Vỉ x 15 viên)",
    drg_ref_cd: "DQG00016472",
    drug_classified: "",
    drug_flg: "",
    drug_group: "GR06",
    drug_id: 0,
    drug_kind: "ETC",
    drug_units:
      [
        {
          active_flg: 1,
          default_flg: 1,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 150,
          max_price: 0,
          parent_unit: 14,
          parent_unit_name: "Viên",
          price: 2500,
          import_price: 0,
          price_before_vat: 2500,
          status: 1,
          unit_id: 14,
          unit_name: "Viên",
          unit_qty: 1,
          wholesale_price: 2000,
          wholesale_price_before_vat: 2000,
        },
        {
          active_flg: 1,
          default_flg: 0,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 10,
          max_price: 0,
          parent_unit: 14,
          parent_unit_name: "Viên",
          price: 36000,
          import_price: 0,
          price_before_vat: 36000,
          status: 1,
          unit_id: 2,
          unit_name: "Vỉ",
          unit_qty: 15,
          wholesale_price: 28800,
          wholesale_price_before_vat: 28800,
        }
      ],
    employee_commissions: 0,
    image_number: 0,
    image_url: "https://medlinknhathuoclongdev.s3.ap-southeast-1.amazonaws.com/1666588824945_2.png",
    indication: "Giảm sưng tấy, phù nề",
    interation: "",
    key: 0,
    license_cd: "VD-23000-15",
    loyalty_flg: 0,
    moisture: "Tránh ẩm và ánh sang",
    note: "Không dùng cho phụ nữ có thai và cho con bú",
    original_product: "Việt Nam",
    overdosage: "Tham khảo ý kiến bác sĩ",
    package_desc: "Vỉ x 15 viên",
    precaution: "Chymotrypsin được dung nạp tốt và không gây tác dụng phụ đáng kể",
    prefix: "S",
    provider_code: "",
    provider_name: "",
    ref_code: "",
    revision: 0,
    source: "",
    special_control: "Với liều cao, phản ứng dị ứng nhẹ như đỏ da có thể xảy ra",
    status: 1,
    storage_temperature: "Dưới 30 độ C",
    updated_date: "",
    updated_user: "Admin",
    validate: true,
    vat_percent: 0
  },
  {
    account_id: 0,
    active_flg: 1,
    active_ingredient: "",
    administration: "",
    adverse_reaction: "",
    atc_code: "",
    avatar_url: "",
    barcode_url: "",
    company_code: "",
    company_name: "Tâm Bình",
    concentration: "Hy thiêm 400mg, Đương quy 350mg, Đỗ trọng 350mg, Tục đoạn 350mg, Cẩu tích 350mg, Độc hoạt 350mg, Ba kích 350mg, Ngưu tất 250mg, Bột Thương truật 110mg, Bột Mã tiền chế 20mg",
    contraindication: "Không sử dụng cho người có mẫn cảm với bất kỳ thành phần nào của sản phẩm",
    country: "",
    created_date: "",
    description: "Viên nang cứng",
    direction_for_use: "Đường uống",
    dosage: "Ngày uống 2 lần, mỗi lần 3 viên, trước khi ăn 30 phút. Người có tiền sử dạ dày nên uống sau khi ăn. Mỗi đợt uống từ 2 – 3 tháng, bệnh mạn tính có thể uống lâu hơn",
    drg_barcode: "8934612002061",
    drg_drug_alias: "",
    drg_drug_cd: "",
    drg_drug_name: "Viên khớp Tâm Bình giảm thoái hóa khớp, viêm khớp hộp 60 viên",
    drg_ref_cd: "",
    drug_classified: "",
    drug_flg: "",
    drug_group: "GR02",
    drug_id: 0,
    drug_kind: "KD01",
    drug_units:
      [
        {
          active_flg: 1,
          default_flg: 1,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 5,
          max_price: 0,
          parent_unit: 8,
          parent_unit_name: "Hộp",
          price: 105000,
          import_price: 0,
          price_before_vat: 105000,
          status: 1,
          unit_id: 8,
          unit_name: "Hộp",
          unit_qty: 1,
          wholesale_price: 84000,
          wholesale_price_before_vat: 84000,
        },
      ],
    employee_commissions: 2,
    image_number: 0,
    image_url: "https://medlinknhathuoclongdev.s3.ap-southeast-1.amazonaws.com/1666588834393_3.png",
    indication: "Hỗ trợ giảm các triệu chứng viêm khớp, thoái hoá khớp, đau nhức xương khớp, đau mỏi cơ bắp",
    interation: "",
    key: 0,
    license_cd: "",
    loyalty_flg: 2,
    moisture: "Nơi khô ráo",
    note: "",
    original_product: "Việt Nam",
    overdosage: "",
    package_desc: "Hộp 60 viên",
    precaution: "Không dùng cho phụ nữ có thai, không uống quá liều chỉ định",
    prefix: "S",
    provider_code: "",
    provider_name: "",
    ref_code: "",
    revision: 0,
    source: "",
    special_control: "",
    status: 1,
    storage_temperature: "Dưới 30 độ C",
    updated_date: "",
    updated_user: "Admin",
    validate: true,
    vat_percent: 5
  },
  {
    account_id: 0,
    active_flg: 1,
    active_ingredient: "",
    administration: "",
    adverse_reaction: "",
    atc_code: "",
    avatar_url: "",
    barcode_url: "",
    company_code: "",
    company_name: "TH Care (Việt Nam)",
    concentration: "",
    contraindication: "",
    country: "",
    created_date: "",
    description: "",
    direction_for_use: "",
    dosage: "",
    drg_barcode: "8934612002062",
    drg_drug_alias: "",
    drg_drug_cd: "",
    drg_drug_name: "Khẩu trang y tế Winer 4 lớp màu xanh hộp 50 cái",
    drg_ref_cd: "",
    drug_classified: "",
    drug_flg: "",
    drug_group: "",
    drug_id: 0,
    drug_kind: "EQUI",
    drug_units:
      [
        {
          active_flg: 1,
          default_flg: 1,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 100,
          max_price: 0,
          parent_unit: 3,
          parent_unit_name: "Cái",
          price: 800,
          import_price: 0,
          price_before_vat: 800,
          status: 1,
          unit_id: 3,
          unit_name: "Cái",
          unit_qty: 1,
          wholesale_price: 640,
          wholesale_price_before_vat: 640,
        },
        {
          active_flg: 1,
          default_flg: 0,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 2,
          max_price: 0,
          parent_unit: 3,
          parent_unit_name: "Cái",
          price: 40000,
          import_price: 0,
          price_before_vat: 40000,
          status: 1,
          unit_id: 8,
          unit_name: "Hộp",
          unit_qty: 50,
          wholesale_price: 32000,
          wholesale_price_before_vat: 32000,
        }
      ],
    employee_commissions: 0,
    image_number: 0,
    image_url: "https://medlinknhathuoclongdev.s3.ap-southeast-1.amazonaws.com/1666588846850_4.png",
    indication: "",
    interation: "",
    key: 0,
    license_cd: "",
    loyalty_flg: 0,
    moisture: "",
    note: "",
    original_product: "Việt Nam",
    overdosage: "",
    package_desc: "Hộp 50 cái",
    precaution: "",
    prefix: "S",
    provider_code: "",
    provider_name: "",
    ref_code: "",
    revision: 0,
    source: "",
    special_control: "",
    status: 1,
    storage_temperature: "",
    updated_date: "",
    updated_user: "Admin",
    validate: true,
    vat_percent: 0
  },
  {
    account_id: 0,
    active_flg: 1,
    active_ingredient: "Glycolic Acid",
    administration: "",
    adverse_reaction: "",
    atc_code: "",
    avatar_url: "",
    barcode_url: "",
    company_code: "",
    company_name: "Gamma chemicals",
    concentration: "",
    contraindication: "",
    country: "",
    created_date: "",
    description: "",
    direction_for_use: "Thoa ngày một lần vào buổi tối lên vùng da bị mụn sau khi rửa sạch và làm khô",
    dosage: "",
    drg_barcode: "8934612002063",
    drg_drug_alias: "",
    drg_drug_cd: "",
    drg_drug_name: "Gel Megaduo Plus ngừa mụn trứng cá, ngừa thâm, dưỡng da tuýp 15g",
    drg_ref_cd: "",
    drug_classified: "",
    drug_flg: "",
    drug_group: "GR17",
    drug_id: 0,
    drug_kind: "KD02",
    drug_units:
      [
        {
          active_flg: 1,
          default_flg: 1,
          drug_id: 0,
          drug_price_id: 0,
          drug_unit_id: 0,
          inv_qty_alarm: 5,
          max_price: 0,
          parent_unit: 11,
          parent_unit_name: "Tuýp",
          price: 125000,
          import_price: 0,
          price_before_vat: 125000,
          status: 1,
          unit_id: 11,
          unit_name: "Tuýp",
          unit_qty: 1,
          wholesale_price: 100000,
          wholesale_price_before_vat: 100000,
        }
      ],
    employee_commissions: 2,
    image_number: 0,
    image_url: "https://medlinknhathuoclongdev.s3.ap-southeast-1.amazonaws.com/1666588860190_5.png",
    indication: "Da mụn",
    interation: "",
    key: 0,
    license_cd: "",
    loyalty_flg: 2,
    moisture: "",
    note: "",
    original_product: "Việt Nam",
    overdosage: "",
    package_desc: "Tuýp 15g",
    precaution: "Kem",
    prefix: "S",
    provider_code: "",
    provider_name: "",
    ref_code: "",
    revision: 0,
    source: "",
    special_control: "",
    status: 1,
    storage_temperature: "",
    updated_date: "",
    updated_user: "Admin",
    validate: true,
    vat_percent: 0
  }
]
export const LIST_CUSTOMER_SAMPLE = [
  {
    address1: "12 Cầu Giấy",
    birthday: "19860425",
    city: "Hà Nội",
    customer_group_cd: "CGRP000002",
    customer_group_name: "Khách hàng thân thiết",
    customer_name: "Nguyễn Tố Như",
    customer_type: 'consumer',
    district: "",
    email: "nhunt@gmail.com",
    note: "",
    phone_no: "0987654321",
    selling_point: 0,
    sex: "W",
    source: "Trực tiếp",
  },
  {
    address1: "115 Hà Đông",
    birthday: "19920712",
    city: "Hà Nội",
    customer_group_cd: "CGRP000001",
    customer_group_name: "Khách lẻ",
    customer_name: "Phan Đăng Mạnh",
    customer_type: 'consumer',
    district: "",
    email: "manhpd@gmail.com",
    note: "",
    phone_no: "0999999999",
    selling_point: 0,
    sex: "F",
    source: "Facebook",
  }
]

export const LIST_PROVIDER_SAMPLE = [
  {
    active_flg: 1,
    address1: "288 Bis Nguyễn Văn Cừ, P. An Hòa, Q. Ninh Kiều, TP. Cần Thơ",
    email: "dhg@gmail.com",
    phone_no: "0862975321",
    provider_name: "Công ty cổ phần Dược Hậu Giang",
    provider_type: "",
    status: 1,
    tax_no: "1800156801",
    website: "dhgpharma.com",
  },
  {
    active_flg: 1,
    address1: "Số 3, ngõ 2, phố Thọ Tháp, phường Dịch Vọng, Cầu Giấy, Hà Nội",
    email: "thaiminhpharma@gmail.com",
    phone_no: "02432003300",
    provider_name: "Công ty cổ phần Dược phẩm Thái Minh",
    provider_type: "",
    status: 1,
    tax_no: "105572847",
    website: "https://tmp.vn/",
  }
]

export const LIST_STRING_PRINT_INVOICE = [
  { value: "{logo_nha_thuoc}", label: "Logo nhà thuốc" },
  { value: "{ten_nha_thuoc}", label: "Tên nhà thuốc" },
  { value: "{sdt_nha_thuoc}", label: "SĐT nhà thuốc" },
  { value: "{dia_chi}", label: "Địa chỉ nhà thuốc" },
  { value: "{thoi_gian}", label: "Thời gian bán hàng" },
  { value: "{ma_don}", label: "Mã đơn hàng" },
  { value: "{nguoi_ban}", label: "Người bán" },
  { value: "{ma_khach_hang}", label: "Mã khách hàng" },
  { value: "{ten_khach_hang}", label: "Tên khách hàng" },
  { value: "{sdt_khach}", label: "SĐT khách hàng" },
  { value: "{diem_tich_luy}", label: "Điểm tích lũy" },
  { value: "{tong_tien}", label: "Tổng tiền" },
  { value: "{thanh_tien}", label: "Thành tiền" },
  { value: "{khach_tra}", label: "Tiền khách trả" },
  { value: "{con_no}", label: "Tiền còn nợ" },
  { value: "{dia_chi_khach_hang}", label: "Địa chỉ khách hàng" },
  { value: "{giam_gia}", label: "Giảm giá" },
  { value: "{vat}", label: "VAT" },
  { value: "{bang_san_pham}", label: "Danh sách sản phẩm" },
  { value: "{chinh_sach_doi_tra}", label: "Chính sách đổi trả" },
  { value: "{qr_code}", label: "Mã QR Code" },
  { value: "{note}", label: "Ghi chú" },
  { value: "{ecoin_minus}", label: "Ecoin đã dùng" },
  
]

export const LIST_STRING_PRINT_IMPORT = [
  { value: "{ten_nha_thuoc}", label: "Tên nhà thuốc" },
  { value: "{sdt_nha_thuoc}", label: "SĐT nhà thuốc" },
  { value: "{dia_chi}", label: "Địa chỉ nhà thuốc" },
  { value: "{ma_phieu_nhap}", label: "Mã phiếu nhập" },
  { value: "{ngay_tao}", label: "Ngày tạo" },
  { value: "{loai_nhap_kho}", label: "Loại nhập kho" },
  { value: "{ma_so_phieu_tt}", label: "Mã số phiếu thực tế" },
  { value: "{pp_thanh_toan}", label: "Phương thức thanh toán" },
  { value: "{nha_cung_cap}", label: "Nhà cung cấp" },
  { value: "{nguoi_tao}", label: "Người tạo" },
  { value: "{ngay_nhap_tt}", label: "Ngày nhập thực tế" },
  { value: "{ghi_chu}", label: "Ghi chú" },
  { value: "{bang_san_pham_nhap_kho}", label: "Bảng sản phẩm nhập kho" },
  { value: "{thanh_tien}", label: "Thành tiền" },
]

export const LIST_STRING_PRINT_EXPORT = [
  { value: "{ten_nha_thuoc}", label: "Tên nhà thuốc" },
  { value: "{sdt_nha_thuoc}", label: "SĐT nhà thuốc" },
  { value: "{dia_chi}", label: "Địa chỉ nhà thuốc" },
  { value: "{ma_phieu_xuat}", label: "Mã phiếu xuất" },
  { value: "{ngay_tao}", label: "Ngày tạo" },
  { value: "{loai_xuat_kho}", label: "Loại xuất kho" },
  { value: "{ma_cua_hang}", label: "Mã cửa hàng" },
  { value: "{ten_cua_hang}", label: "Tên cửa hàng" },
  { value: "{nguoi_tao}", label: "Người tạo" },
  { value: "{trang_thai}", label: "Trạng thái" },
  { value: "{ghi_chu}", label: "Ghi chú" },
  { value: "{bang_san_pham_xuat_kho}", label: "Bảng sản phẩm xuất kho" },
  { value: "{thanh_tien}", label: "Thành tiền" },
]

export const STRING_NULL = 'Chưa có thông tin'

export const titlePromotion = {
  type: 'khuyến mãi',
  name: 'Chương trình khuyến mãi',
  typeSelect: 'Chọn loại khuyến mãi',
  promotionInvoice: 'Khuyến mãi trên toàn đơn',
  promotionProduct: "Khuyến mãi trên sản phẩm",
  messageDelete: 'Xóa chương trình khuyến mãi thành công!',
  messageUpdateSuccess: "Cập nhật khuyến mãi thành công!",
  messageUpdateError: "Cập nhật khuyến mãi thất bại!",
}

export const titleSalePolicy = {
  type: 'chính sách',
  name: 'Chính sách bán hàng',
  typeSelect: 'Chọn loại chính sách',
  promotionInvoice: 'Chính sách trên toàn đơn',
  promotionProduct: "Chính sách trên sản phẩm",
  messageDelete: 'Xóa chính sách thành công!',
  messageUpdateSuccess: "Cập nhật chính sách thành công!",
  messageUpdateError: "Cập nhật chính sách thất bại!",
}