import client from "./apiConfig";

export default {

  getAccountingProduct: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/product/material/export`;
    await client.post(url, data, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_hang_hoa_vat_tu.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingProviderExport: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/provider/export?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_danh_muc_nha_cung_cap.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingCustomer: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/customer/export?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_danh_muc_khach_hang.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInvoiceReceipt: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/invoice/receipt?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_phieu_thu.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInvoiceReport: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/invoice/report?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_bao_cao.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInventoryFile: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/inventory/file?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_hoat_dong_mua_hang_trong_nuoc.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInventoryTransFerList: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/inventory/transferList?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_thong_tin_phieu_xuat_dieu_chuyen.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },
  getAccountingInvoiceExport: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/invoice/export?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_hoat_dong_ban_hang.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInventoryStoreImport: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/inventory/storeImport?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_phieu_nhap_kho.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInventoryStoreExport: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/inventory/storeExport?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_phieu_xuat_kho.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInvoicebBillBackProvider: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/invoice/billBackProvider?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_hoa_don_hang_ban_tra_lai_ncc.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },

  getAccountingInvoicebBillBack: async (token: string, data: any) => {
    const url = `/pharmacy/accounting/fast/invoice/billBack?from_time=${data.from_time}&to_time=${data.to_time}`;
    await client.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: token,
      }
    }).then(response => {
      // response.data is an empty object
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'fast_hoa_don_ban_tra_lai.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  },
}