import { queryString } from "../ultils/Api/queryString";
import client from "./apiConfig";
export interface CheckOtp {
    phone_no: string;
    otp_value: string;
    channel_code: string;
}
export default {
    requestSendOtp(phone_no: string) {
        const param = { phone_no }
        const url = `/sms/message/otp${queryString(param)}`;
        return client.get(url)
    },
    verifyOtp(params: CheckOtp) {
        const url = `/sms/message/otp`;
        return client.post(url, params).then(response => {
            return response;
        });
    },
};
