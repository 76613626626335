import client from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants";
import { SyncBill } from "../dataType/Sync";
import { SyncExport } from "../dataType/SyncExport";
import { SyncImport } from "../dataType/SyncImport";
import axios from "axios";

export default {
  getSyncInvoice: async (token: string, _page: number, _size: number) => {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/sync/invoice?page=${page}&size=${size}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  },

  syncInvoice: async (params: SyncBill) => {
    const url = `/pharmacy/sync/invoice`;
    return await client.post(url, params, { timeout: 150000 });
  },

  unSynsInvoice: async (id: number) => {
    const url = `/pharmacy/invoice/gpp/cancel?invoice_id=${id}`;
    const response = await client.get(url);
    return response.data;
  },

  getSyncImport: async (token: string, _page: number, _size: number) => {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/sync/import?page=${page}&size=${size}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  },

  syncImport: async (params: SyncImport) => {
    const url = `/pharmacy/sync/import`;
    const response = await client.post(url, params, { timeout: 150000 });
    return response.data;
  },

  unSynsImport: async (id: number) => {
    const url = `/pharmacy/inventory/import/gpp/cancel?import_id=${id}`;
    const response = await client.get(url);
    return response.data;
  },

  getSyncExport: async (token: string, _page: number, _size: number) => {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/sync/export?page=${page}&size=${size}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  },

  syncExport: async (params: SyncExport) => {
    const url = `/pharmacy/sync/export`;
    const response = await client.post(url, params, { timeout: 150000 });
    return response.data;
  },

  unSynsExport: async (token: string, id: number) => {
    const url = `/pharmacy/inventory/export/gpp/cancel?export_id=${id}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  },

  linkAccount: async (token: string, params: any) => {
    const url = `/pharmacy/store/gpp/account`;
    const response = await client.put(url, params, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  },
  getInfoPrescription: async (ma_don_thuoc: string) => {
    const res = await axios.get(`https://donthuocquocgia.vn/api/v1/xem-don-thuoc/${ma_don_thuoc}`);
    return res;
  }
};
