export const queryString = (object) => {
    const keys = Object.keys(object)
    const queryString = keys.map(key => {
		const value = object[key];
		if (value === undefined) {
			return '';
		}
		return encodeURIComponent(key) + '=' + encodeURIComponent(value);
	}).filter(x => x.length > 0).join('&');
    return `?${queryString}`
}
