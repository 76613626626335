import { LIMIT, PAGE_DEFAULT } from "../constants";
import { DrgEmployeeShiftRequest, DrgEmployeeTimeKeepingRequest, EmployeeRequest, EmployeeSalaryCreateRequest, ScheduleCreateRequest, TypeEmployeeRequest, TypeEmployeeRequestSearch, TypeTimeSheetUpdate } from "../dataType/Employee";
import client from "./apiConfig";

export default {
  getGroupCustomer() {
    const url = `/pharmacy/customer/group`;
    return client.get(url);
  },
  async getAllShift() {
    const url = `/pharmacy/employee/shift`
    const res = await client.get(url)
    return res
  },
  async getDetailShift(shift_id: number) {
    const url = `/pharmacy/employee/shift/${shift_id}`
    const res = await client.get(url)
    return res
  },
  async createShift(data: DrgEmployeeShiftRequest) {
    const url = `/pharmacy/employee/shift`
    const res = await client.post(url, data)
    return res
  },
  async updateShift(data: DrgEmployeeShiftRequest) {
    const url = `/pharmacy/employee/shift`
    const res = await client.put(url, data)
    return res
  },
  async deleteShift(shift_id: number) {
    const url = `/pharmacy/employee/shift?shift_id=${shift_id}`
    const res = await client.delete(url)
    return res
  },
  async getAllHistoryTimesheet(page: number, size: number, data: TypeEmployeeRequest) {
    const url = `/pharmacy/employee/timesheet/history?page=${page}&size=${size}&account_id=${data.account_id}&drg_store_id=${data.drg_store_id}&date_from=${data.date_from}&date_to=${data.date_to}`
    const res = await client.get(url)
    return res
  },
  async timeKeepingUser(data: DrgEmployeeTimeKeepingRequest) {
    const url = `/pharmacy/employee/timesheet`
    const res = await client.post(url, data)
    return res
  },
  async getReportTimeSheetMember(_page: number, _size: number, data: TypeEmployeeRequestSearch) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/employee/timesheet/report?page=${page}&size=${size}&drg_store_id=${data.drg_store_id}&date_from=${data.date_from}&date_to=${data.date_to}`;
    const res = await client.get(url)
    return res
  },
  async updateCheckedTime(data: TypeTimeSheetUpdate, timesheet_id: number, drg_store_id: number) {
    const url = `/pharmacy/employee/timesheet/${timesheet_id}?checked_time=${data.checked_time}&drg_store_id=${drg_store_id}`
    const res = await client.put(url, data)
    return res
  },
  async getDetailTimeSheetOneDayOfEmployee(employee_id: number, date: string, drg_store_id: number, shift_id: number) {
    const url = `/pharmacy/employee/timesheet?employee_id=${employee_id}&drg_store_id=${drg_store_id}&date=${date}&shift_id=${shift_id}`
    const res = await client.get(url)
    return res
  },
  async deleteTimeSheetOfMember(timesheet_id: number, drg_store_id: number) {
    const url = `/pharmacy/employee/timesheet/${timesheet_id}?drg_store_id=${drg_store_id}`
    const res = await client.delete(url)
    return res.data
  },
  async getAllHistorySchedule(data: TypeEmployeeRequest) {
    const url = `/pharmacy/employee/schedule?account_id=${data.account_id}&drg_store_id=${data.drg_store_id}&date_from=${data.date_from}&date_to=${data.date_to}`
    const res = await client.get(url)
    return res
  },
  async createScheduleForMember(param: ScheduleCreateRequest[], drg_store_id: number) {
    const url = `/pharmacy/employee/schedule?drg_store_id=${drg_store_id}`
    const res = await client.post(url, param)
    return res.data
  },
  async updateScheduleForMember(param: ScheduleCreateRequest[], drg_store_id: number) {
    const url = `/pharmacy/employee/schedule?drg_store_id=${drg_store_id}`
    const res = await client.put(url, param)
    return res.data
  },
  async deleteScheduleForMember(schedule_id: number) {
    const url = `/pharmacy/employee/schedule?${schedule_id}`
    const res = await client.delete(url)
    return res.data
  },
  async getAllEmployee(page: number, size: number, data: EmployeeRequest) {
    const url = `/pharmacy/employee?page=${page}&size=${size}&drg_store_id=${data.drg_store_id}&full_name=${data.full_name}&phone_no=${data.phone_no}&status=${data.status}&role_cd=${data.role_cd}&email=${data.email}`
    const res = await client.get(url)
    return res
  },
  async updateEmployee(employee_id: number, data: EmployeeRequest) {
    const url = `/pharmacy/employee/${employee_id}`
    const res = await client.put(url, data)
    return res
  },
  async createEmployee(data: EmployeeRequest) {
    const url = `/pharmacy/employee`
    const res = await client.post(url, data)
    return res.data
  },
  async getHistorySalary(page: number, size: number, data: TypeEmployeeRequestSearch) {
    const url = `/pharmacy/employee/salary?page=${page}&size=${size}&drg_store_id=${data.drg_store_id}&employee_id=${data.employee_id}&date_from=${data.date_from}&date_to=${data.date_to}`
    const res = await client.get(url)
    return res
  },
  async updateEmployeeSalary(data: EmployeeSalaryCreateRequest) {
    const url = `/pharmacy/employee/salary`
    const res = await client.post(url, data)
    return res.data
  }
}
