import { LoyaltyCompanyRegister } from "../dataType/loyalty";
import client from "./apiConfig";

export default {
  async getLoyaltyCompanyInfo(token?: string) {
    const url = `/loyalty/company`
    const axiosConfig = {
      setToken: token ? true : false,
      headers: {
        Authorization: "Bearer " + token
      }
    }
    const response = await client.get(url, axiosConfig);
    return response;
  },

  async registerLoyalty(data: LoyaltyCompanyRegister) {
    const url = `/loyalty/company`;
    const response = await client.post(url, data);
    return response;
  }
}