import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import AuthRouter from "./layouts";
import rootReducers from "./redux/reducers";
import rootSagas from "./redux/sagas";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import { ConfigProvider } from "antd";
import vi_VN from "antd/lib/locale/vi_VN";
import 'moment/locale/vi';
import moment from "moment";
import UpdateMetaDescription from "./UpdateMetaDescription";
moment.locale('vi');

// persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["dataPersist", "product", "inventory", "invoice", "invoiceWholesale", "invoiceCombo", "cart", "setting", "combo", "invoiceDraft", "invoiceDraftConfirm", 'campaign', 'settingUIDisplay', 'promotion', 'promotionNew']
};
const persistedReducer = persistReducer(persistConfig, rootReducers);

// add middleware
const sagaMiddleware = createSagaMiddleware();
const middleware: any = [];
middleware.push(sagaMiddleware)
process.env.NODE_ENV !== "production" && middleware.push(logger);

// create store in redux
export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);
const persistor = persistStore(store);
sagaMiddleware.run(rootSagas);

const Root = () => {
  useEffect(() => {
    clearCacheData()
  }, [])

  const clearCacheData = async () => {
    let listCaches = await caches.keys()
    if (listCaches.length > 0) {
      listCaches.forEach((name) => {
        caches.delete(name)
      })
    }
  };

  return <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConfigProvider locale={vi_VN}>
        <AuthRouter />
        <UpdateMetaDescription/>
      </ConfigProvider>
    </PersistGate>
  </Provider>

};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
