import { INVOICE_PAY_METHOD, INVOICE_SOURCE_TYPE } from './../../constants/index';
import {
  CHANGE_TAB_INVOICE,
  UPDATE_INVOICE,
  ADD_INVOICE,
  DELETE_INVOICE,
  LOG_OUT,
  UPDATE_INVOICE_INFO,
  UPDATE_INVOICE_PRESCRIPTION,
  TRANSFER_BRANCH,
  UPDATE_AMOUNT_PAID_INVOICE,
  ADD_PROMOTION_GIFT,
  UPDATE_PROMOTION_INVOICE,
  UPDATE_LOYALTY_EXCHANGE_ECOIN,
  UPDATE_LOYALTY_EARN_ECOIN,
  RESET_INVOICE_PRESCRIPTION
} from "../types";
import { TabInvoice, InvoiceRequest, InvoiceProduct } from "../../dataType/Invoice";
import { CURRENCIES, NUMBER_NULL, DISCOUNT_TYPE } from "../../constants";

export type InitStateInvoice = {
  arrayTabInvoice: TabInvoice[];
  activeKey: string;
};

const initialState: InitStateInvoice = {
  arrayTabInvoice: [],
  activeKey: "1"
};

export default function invoice(state = initialState, action: any) {
  const { arrayTabInvoice, activeKey } = state;
  const invoiceTemp: InvoiceRequest = {
    invoice: {
      invoice_type: 0,
      amount: 0,
      amount_paid: NUMBER_NULL,
      amount_debt: 0,
      amount_vat: 0,
      discount_amount: 0,
      currency: CURRENCIES.VN,
      pay_method: INVOICE_PAY_METHOD.CS,
      status: 2,
      note: "",
      customer_id: 0,
      customer_code: "",
      customer_name: "",
      pay_reference: "",
      customer_phone_no: "",
      customer_address: '',
      ecoin_minus: 0,
      ecoin_plus: 0,
      issue_datetime: "",
      img_url: "",
      fee_ship: 0,
      //xóa khi gọi API
      discount_amount_inv: 0,
      discount_type: DISCOUNT_TYPE.MONEY,
      vat: 0,
      current_pay: 0,
      is_debit: false,
      invoice_source: INVOICE_SOURCE_TYPE[0].code
    },
    products: [],
    promotion: null,
  };

  switch (action.type) {
    case CHANGE_TAB_INVOICE:
      return { ...state, activeKey: action.activeKey };

    case ADD_INVOICE:
      const maxKeyCur: string = arrayTabInvoice.length
        ? arrayTabInvoice[arrayTabInvoice.length - 1].key
        : "0";
      const key = (parseInt(maxKeyCur) + 1).toString();
      const newInvoiceTab = {
        key,
        title: `Hóa đơn ${key}`,
        data: { ...invoiceTemp }
      };
      return {
        arrayTabInvoice: [...state.arrayTabInvoice, newInvoiceTab],
        activeKey: key
      };

    case DELETE_INVOICE: {
      //lastIndex: tab ngay truoc tab muon xoa
      //activeKeyCur: key cua tab hien tai
      let lastIndex = 0,
        activeKeyCur = activeKey;
      arrayTabInvoice.forEach((invoiceTab, i) => {
        if (invoiceTab.key === action.keyTab) {
          lastIndex = i - 1;
        }
      });
      const invoices = arrayTabInvoice.filter(
        invoice => invoice.key !== action.keyTab
      );

      if (arrayTabInvoice.length && state.activeKey === action.keyTab) {
        if (lastIndex >= 0) {
          activeKeyCur = arrayTabInvoice[lastIndex].key;
        } else if (lastIndex === -1 && arrayTabInvoice.length > 1) {
          activeKeyCur = arrayTabInvoice[lastIndex + 2].key;
        } else activeKeyCur = arrayTabInvoice[0].key;
      }
      return {
        arrayTabInvoice: invoices,
        activeKey: activeKeyCur
      };
    }
    case UPDATE_INVOICE_INFO: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      const invoice = action.invoiceInfo
      const products: InvoiceProduct[] = action.products
      const earnEcoin = arrayTabInvoice[tabCur].data.earnEcoinProgram
      const exchangeEcoinProgram = arrayTabInvoice[tabCur].data.exchangeEcoinProgram
      const amount_product = getTotalAmount(products);
      invoice.amount = amount_product + invoice.amount_vat - invoice.discount_amount - invoice.ecoin_minus * (exchangeEcoinProgram ? exchangeEcoinProgram.value : 0)
      invoice.amount = invoice.amount < 0 ? 0 : invoice.amount
      invoice.amount_paid = invoice.pay_method === INVOICE_PAY_METHOD.COD ? NUMBER_NULL : invoice.amount;
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0
      arrayTabInvoice[tabCur].data.invoice = invoice
      invoice.ecoin_plus = earnEcoin ? Number((invoice.amount / earnEcoin.point_conv_rate).toFixed(2)) : 0;
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_INVOICE: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data = action.invoiceRequest
      let invoiceRequest: InvoiceRequest = action.invoiceRequest;
      const earnEcoin = arrayTabInvoice[tabCur].data.earnEcoinProgram
      const exchangeEcoinProgram = arrayTabInvoice[tabCur].data.exchangeEcoinProgram
      let { invoice, products } = invoiceRequest;
      arrayTabInvoice[tabCur].data.products = products.map((item: InvoiceProduct, index: number) => {
        return { ...item, ecoin_plus: item.ecoin ? Number(((item.ecoin * item.price - item.discount_unit) * item.quantity / 100000).toFixed(2)) : 0 }
      });
      const amount_product = getTotalAmount(products);
      invoice.amount_vat = Math.round(amount_product * invoice.vat / 100);
      if (invoice.discount_type === DISCOUNT_TYPE.MONEY) {
        invoice.discount_amount = invoice.discount_amount_inv;
      } else {
        invoice.discount_amount = Math.round(invoice.discount_amount_inv * amount_product / 10000) * 100;
      }
      invoice.amount = amount_product + invoice.amount_vat - invoice.discount_amount - invoice.ecoin_minus * (exchangeEcoinProgram ? exchangeEcoinProgram.value : 0);
      invoice.amount_paid = invoice.amount
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0;
      invoice.ecoin_plus = earnEcoin ? Number((invoice.amount / earnEcoin.point_conv_rate).toFixed(2)) : 0;
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_INVOICE_PRESCRIPTION: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data.prescription = action.prescription
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case RESET_INVOICE_PRESCRIPTION: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data.prescription = undefined
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }
    case UPDATE_AMOUNT_PAID_INVOICE: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      const invoice = action.invoiceInfo
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0
      arrayTabInvoice[tabCur].data.invoice = invoice
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case ADD_PROMOTION_GIFT: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data.products = action.products
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_PROMOTION_INVOICE: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      const promotionOld = arrayTabInvoice[tabCur].data.promotion
      const listProductNew = promotionOld ? arrayTabInvoice[tabCur].data.products.filter(item => item.promotion_id !== promotionOld?.promotion_id) : arrayTabInvoice[tabCur].data.products
      const prmotionNew = action.promotion
      arrayTabInvoice[tabCur].data.promotion = prmotionNew
      arrayTabInvoice[tabCur].data.products = listProductNew
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_LOYALTY_EXCHANGE_ECOIN: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data.exchangeEcoinProgram = action.data
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_LOYALTY_EARN_ECOIN: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoice) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data.earnEcoinProgram = action.data
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case LOG_OUT: {
      return {
        ...initialState
      };
    }
    case TRANSFER_BRANCH: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}

function getTotalAmount(products: InvoiceProduct[]) {
  let total = 0;
  products.map((item: InvoiceProduct) => total += item.promotion_flg === 1 ? 0 : (item.price * item.quantity - item.discount));
  return total;
}
