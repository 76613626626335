import { SettingGenaralType } from "../../dataType/Setting"
import { InitStateSetting, OptionPrintInvoice } from "../reducers/setting"
import {
  CHANGE_SHOW_USE_GUIDE, CHANGE_TYPE_SHOW_PRODUCT, CHANGE_POLICY_RETURN_INVOICE,
  CHANGE_TYPE_OPTION_PRINT_BARCODE, CHANGE_TYPE_BUSINESS, CHANGE_POLICY_RETURN_INVOICE_WHOLESALE,
  CHANGE_OPTIONS_PRINT_BARCODE, UPDATE_SETTING, CHANGE_TYPE_SYNC, CHANGE_TYPE_BUSINESS_FORM, UPDATE_OPTION_PRINT_INVOICE, INIT_SETTING_GENERAL, CHANGE_QR_CODE
} from "../types"

// export const changeTypePrint = (type: string) => ({
//   type: CHANGE_TYPE_PRINT,
//   payload: type
// })
export const changeTypeBusinessForm = (type: string[]) => ({
  type: CHANGE_TYPE_BUSINESS_FORM,
  payload: type
})

export const changeShowUseGuide = (showUserGuide: boolean) => ({
  type: CHANGE_SHOW_USE_GUIDE,
  payload: showUserGuide
})

export const changeTypeShowProduct = (type: number) => ({
  type: CHANGE_TYPE_SHOW_PRODUCT,
  payload: type
})

export const changePolicyReturnProduct = (type: string) => ({
  type: CHANGE_POLICY_RETURN_INVOICE,
  payload: type
})

export const changePolicyReturnProductWholesale = (type: string) => ({
  type: CHANGE_POLICY_RETURN_INVOICE_WHOLESALE,
  payload: type
})


export const changeTypePrintBarcode = (type: string) => ({
  type: CHANGE_TYPE_OPTION_PRINT_BARCODE,
  payload: type
})

export const changeOptionsPrintBarcode = (listOptions: string[]) => ({
  type: CHANGE_OPTIONS_PRINT_BARCODE,
  payload: listOptions
})

export const changeTypeBusiness = (type: string[]) => ({
  type: CHANGE_TYPE_BUSINESS,
  payload: type
})

export const changeTypeSync = (type: number) => ({
  type: CHANGE_TYPE_SYNC,
  payload: type
})

export const updateSettingReducer = (setting: InitStateSetting) => ({
  type: UPDATE_SETTING,
  payload: setting
})

export const updateOptionPrintInvoice = (data: OptionPrintInvoice) => ({
  type: UPDATE_OPTION_PRINT_INVOICE,
  payload: data
})

export const initSettingGeneral = (data: SettingGenaralType) => ({
  type: INIT_SETTING_GENERAL,
  payload: data,
})

export const changeQrCode = (data: string) => ({
  type: CHANGE_QR_CODE,
  payload: data,
})