import { put, takeLatest, call, select, takeEvery } from 'redux-saga/effects';
import { loginSuccess, loginFail, updateExpiredLicense, updateRequestLogin, RequestLoginParam, logout } from "../actions/auth";
import { saveAllStoreChainPharmacies, saveInfoUser, saveInfoStore, saveCompanyInfo, saveBrandNameInfo, saveZaloOaInfo, saveLicense, saveCompanyType, saveCompanyLoyalty } from "../actions/dataPersist";
import { LOGIN_REQUEST, REFRESH_TOKEN } from '../types';
import api from '../../api';
import { ResponseAxios } from '../../dataType/Response';
import { DrgAccount } from '../../dataType/User';
import { MASTER_FLG, PACKAGE_CODE_PRIMARY, ROLE_CODE } from '../../constants';
import { InitStateAuth } from '../reducers/auth';
import { RootState } from '../reducers';
import { LoginRequest } from '../../dataType/auth';
import { DrgStore, DrgStoreLicense } from '../../dataType/Store';

type ActionLogin = {
  type: string
  payload: RequestLoginParam
}

var isLoadingRefreshToken = false

function* requestLogin(action: ActionLogin) {
  const { payload } = action
  const { username, password } = payload.user;
  const { history, remember } = payload.moreInfor;
  try {
    /* request token */
    const response: ResponseAxios = yield call(api.auth.login, { username, password });
    const { access_token } = response.data
    // /* request store info */
    const resStore: ResponseAxios = yield call(api.store.getInfoStore, access_token)
    const store: DrgStore = resStore.data
    yield put(saveInfoStore(store));
    /* request account info */
    const resAccount: ResponseAxios = yield call(api.user.getUserInfo, access_token);
    const account: DrgAccount = resAccount.data
    yield put(saveInfoUser(account));
    /* request license of store */
    const resLicense: ResponseAxios = yield call(api.store.getStoreLicense, access_token)
    const license: DrgStoreLicense = resLicense.data
    // request infoCompany
    if (license) yield put(saveLicense(license))
    const res: ResponseAxios = yield call(api.company.getCompanyInfo, access_token);
    yield put(saveCompanyType(res.data))
    // request save loyalty company
    try {
      const responseLoyalty: ResponseAxios = yield call(api.loyaltyCompany.getLoyaltyCompanyInfo, access_token)
      yield put(saveCompanyLoyalty(responseLoyalty.data))
    } catch (err) {
      console.log(err)
    }
    /* when no license */
    if (resLicense.status === 204) { // have not license
      if (store.master_flg === MASTER_FLG.IS_MASTER) {
        yield put(loginSuccess({ ...response.data, userInfo: remember ? { username, password } : null }));
        history.replace("/nhathuoc/tongquan")
      } else {
        yield put(updateExpiredLicense(true))
      }
    }
    /* when have license */
    if (license) {
      /* request all stores */
      const resAllStore: ResponseAxios = yield call(api.store.getAllStoreInCompany, access_token, 1)
      const subStores = resAllStore.data
      yield put(saveAllStoreChainPharmacies(subStores));
      if (license.package_code.includes(PACKAGE_CODE_PRIMARY.PRO) && subStores.length > 1 && account.role_cd === ROLE_CODE.OWNER) {
        yield put(loginSuccess({ ...response.data, userInfo: remember ? { username, password } : null }));
        history.replace("/chi-nhanh", { username, password });
      } else {
        yield put(loginSuccess({ ...response.data, userInfo: remember ? { username, password } : null }));
        history.replace("/nhathuoc/tongquan")
      }
    }
    yield call(requestSmsCompanyInfo)
  } catch (err: any) {
    if (err.response && err.response.data.error_description === "User not active!") {
      yield put(loginFail("Tài khoản chưa được kích hoạt"))
    } else if (err.response && (err.response.data.error === "invalid_grant" || err.response.data.error_description === "User not found or account deactivated.")) {
      yield put(loginFail("Sai mật khẩu hoặc tên đăng nhập"))
    } else {
      yield put(loginFail("Lỗi kết nối đường truyền"))
    }
  } finally {
    yield put(updateRequestLogin(false));
  }
}

function* requestSmsCompanyInfo() {
  try {
    const resCompany: ResponseAxios = yield call(api.smsCompany.getInfSmsService);
    yield put(saveCompanyInfo(resCompany.data))
    const resBrand: ResponseAxios = yield call(api.smsBrandName.getInfoBrandName);
    yield put(saveBrandNameInfo(resBrand.data))
    const resZalo: ResponseAxios = yield call(api.smsZaloOa.getInfoZaloOa);
    yield put(saveZaloOaInfo(resZalo.data))
  } catch (error: any) {
  }
}

function* requestRefreshToken() {
  if (isLoadingRefreshToken === true) return
  isLoadingRefreshToken = true
  const auth: InitStateAuth = yield select((state: RootState) => state.auth)
  const infoStore: DrgStore = yield select((state: RootState) => state.dataPersist.infoStore)
  const { userInfo, refreshToken, drgStoreId } = auth
  if (refreshToken === '' && userInfo === null) {
    yield call(logout)
    return isLoadingRefreshToken = false
  }
  /* request access token by refresh token */
  try {
    const params: LoginRequest = {
      refresh_token: refreshToken,
      drg_store_id: drgStoreId,
    }
    const res: ResponseAxios = yield call(api.auth.login, params)
    const resLicense: ResponseAxios = yield call(api.store.getStoreLicense, res.data.access_token)
    const license = resLicense.data
    if (!license && infoStore.master_flg === MASTER_FLG.NOT_MASTER) {
      yield call(logout)
      return isLoadingRefreshToken = false
    }
    yield put(loginSuccess(res.data))
    yield put(saveLicense(resLicense.data))
    return isLoadingRefreshToken = false
  } catch (e) {
  }
  /* request access token by username password token */
  try {
    const params: LoginRequest = {
      username: userInfo?.username,
      password: userInfo?.password,
      drg_store_id: drgStoreId,
    }
    const res: ResponseAxios = yield call(api.auth.login, params)
    const resLicense: ResponseAxios = yield call(api.store.getStoreLicense, res.data.access_token)
    const license = resLicense.data
    yield put(saveLicense(resLicense.data))
    if (!license && infoStore.master_flg === MASTER_FLG.NOT_MASTER) {
      yield call(logout)
      return isLoadingRefreshToken = false
    }
    yield put(loginSuccess(res.data))
    return isLoadingRefreshToken = false
  } catch (e) {
    yield call(logout)
  } finally {
    isLoadingRefreshToken = false
  }
}

export default function* () {
  yield takeLatest(LOGIN_REQUEST, requestLogin)
  yield takeEvery(REFRESH_TOKEN, requestRefreshToken)
}


