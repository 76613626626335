import client from "./apiConfig";

export type DataAnalyticRequest = {
  linked: number,
  selling_point: number | string,
  from_total_amount: number,
  to_total_amount: number,
  customer_type: string[] | number[],
  customer_group: string,
  drg_drug_cd: string,
  customer_name: string,
  city: string,
  district: string
}
export type DataAnalyticProductRequest = {
  drg_drug_cd: string,
  city: string,
  customer_id: string | number,
  district: string,
  from_time: string,
  customer_group: string,
  to_time: string
}
export default {
  async getDataAnalyticAtPos(data: DataAnalyticRequest) {
    const url = `/pharmacy/analytic/pos`;
    return client
      .post(url, data)
      .then(response => {
        return response;
      });
  },
  async getDataAnalyticProduct(data: DataAnalyticProductRequest, page: number, size: number) {
    const url = `/pharmacy/analytic/pos/product?page=${page}&size=${size}`;
    return client
      .post(url, data)
      .then(response => {
        return response;
      });
  }
}