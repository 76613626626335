import client from "./apiConfig";
import { DrgStore } from '../dataType/Store'
export default {
  getInfoStore(token?: string) {
    const url = `/pharmacy/store/info`;
    const axiosConfig = {
      setToken: token ? true : false,
      headers: {
        Authorization: "Bearer " + token
      }
    }
    return client
      .get(url, axiosConfig)
      .then(response => {
        return response;
      });
  },
  getAllStoreInCompany(token: string | null, active_flg: number) {
    const url = `/pharmacy/store/all?active_flg=${active_flg}`;
    return client
      .get(url, {
        headers: {
          Authorization: "Bearer" + token
        }
      })
      .then(response => {
        return response;
      });
  },
  createNewStore(token: string, data: any) {
    const url = `/pharmacy/store`;
    return client
      .post(url, data, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  updateStore(token: string, dataUpdate: DrgStore) {
    const url = `/pharmacy/store`;
    return client
      .put(url, dataUpdate, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  registrationAccount(drg_store_id: number, account_id: number) {
    const url = `/pharmacy/store/license/registration/create?drg_store_id=${drg_store_id}&&account_id=${account_id}`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },
  getStoreLicense(token?: string) {
    const url = `/pharmacy/store/license`
    const axiosConfig = {
      setToken: token ? true : false,
      headers: {
        Authorization: "Bearer " + token
      }
    }
    return client.get(url, axiosConfig)
  },
  getStorePackage() {
    const url = `/pharmacy/store/package`
    return client.get(url)
  },
  upgradePackage(package_code: string, is_trial: number) {
    const url = `/pharmacy/store/license/registration?package_code=${package_code}&is_trial=${is_trial}`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  }
};
