import { UPDATE_AMOUNT_PAID_INVOICE_COMBO } from './../types/index';
import {
  CHANGE_TAB_INVOICE_COMBO,
  UPDATE_INVOICE_COMBO,
  ADD_INVOICE_COMBO,
  DELETE_INVOICE_COMBO,
  LOG_OUT,
  UPDATE_INVOICE_COMBO_INFO,
  UPDATE_INVOICE_PRESCRIPTION,
  TRANSFER_BRANCH
} from "../types";
import { InvoiceComboProduct, TabInvoiceCombo, InvoiceComboRequest } from "../../dataType/Invoice";
import { CURRENCIES, NUMBER_NULL, DISCOUNT_TYPE, INVOICE_PAY_METHOD } from "../../constants";
import { INVOICE_SOURCE_TYPE } from './../../constants/index';
export interface InitStateInvoiceCombo {
  arrayTabInvoice: TabInvoiceCombo[];
  activeKey: string;
};

const initialState: InitStateInvoiceCombo = {
  arrayTabInvoice: [],
  activeKey: "1"
};

export default function invoice(state = initialState, action: any) {
  const { arrayTabInvoice, activeKey } = state;
  const invoiceTemp: InvoiceComboRequest = {
    invoice: {
      invoice_type: 0,
      amount: 0,
      amount_paid: NUMBER_NULL,
      amount_debt: 0,
      amount_vat: 0,
      discount_amount: 0,
      currency: CURRENCIES.VN,
      pay_method: INVOICE_PAY_METHOD.CS,
      status: 2,
      note: "",
      customer_id: 0,
      customer_code: "",
      customer_name: "",
      customer_address: '',
      pay_reference: "",
      customer_phone_no: "",
      ecoin_minus: 0,
      ecoin_plus: 0,
      issue_datetime: "",
      img_url: "",
      fee_ship: 0,
      //xóa khi gọi API
      discount_amount_inv: 0,
      discount_type: DISCOUNT_TYPE.MONEY,
      vat: 0,
      current_pay: 0,
      is_debit: false,
      invoice_source: INVOICE_SOURCE_TYPE[0].code
    },
    products: [],
    combos: []
  };

  switch (action.type) {
    case CHANGE_TAB_INVOICE_COMBO:
      return { ...state, activeKey: action.activeKey };

    case ADD_INVOICE_COMBO:
      const maxKeyCur: string = arrayTabInvoice.length
        ? arrayTabInvoice[arrayTabInvoice.length - 1].key
        : "0";
      const key = (parseInt(maxKeyCur) + 1).toString();
      const newInvoiceTab = {
        key,
        title: `Hóa đơn ${key}`,
        data: { ...invoiceTemp }
      };
      return {
        arrayTabInvoice: [...state.arrayTabInvoice, newInvoiceTab],
        activeKey: key
      };

    case DELETE_INVOICE_COMBO: {
      //lastIndex: tab ngay truoc tab muon xoa
      //activeKeyCur: key cua tab hien tai
      let lastIndex = 0,
        activeKeyCur = activeKey;
      arrayTabInvoice.forEach((invoiceTab, i) => {
        if (invoiceTab.key === action.keyTab) {
          lastIndex = i - 1;
        }
      });
      const invoices = arrayTabInvoice.filter(
        invoice => invoice.key !== action.keyTab
      );

      if (arrayTabInvoice.length && state.activeKey === action.keyTab) {
        if (lastIndex >= 0) {
          activeKeyCur = arrayTabInvoice[lastIndex].key;
        } else if (lastIndex === -1 && arrayTabInvoice.length > 1) {
          activeKeyCur = arrayTabInvoice[lastIndex + 2].key;
        } else activeKeyCur = arrayTabInvoice[0].key;
      }
      return {
        arrayTabInvoice: invoices,
        activeKey: activeKeyCur
      };
    }
    case UPDATE_INVOICE_COMBO_INFO: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceCombo) => element.key === action.keyTab);
      const invoice = action.invoiceInfo
      const products: InvoiceComboProduct[] = action.products
      const amount_product = getTotalAmount(products);
      invoice.amount = amount_product + invoice.amount_vat - invoice.discount_amount - invoice.ecoin_minus * 1000
      invoice.amount = invoice.amount < 0 ? 0 : invoice.amount
      invoice.amount_paid = invoice.pay_method === INVOICE_PAY_METHOD.COD ? NUMBER_NULL : invoice.amount;
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0
      arrayTabInvoice[tabCur].data.invoice = invoice
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }
    case UPDATE_AMOUNT_PAID_INVOICE_COMBO: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceCombo) => element.key === action.keyTab);
      const invoice = action.invoiceInfo
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0
      arrayTabInvoice[tabCur].data.invoice = invoice
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_INVOICE_COMBO: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceCombo) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data = action.invoiceRequest
      let invoiceRequest: InvoiceComboRequest = action.invoiceRequest;
      let { invoice, products } = invoiceRequest;
      const amount_product = getTotalAmount(products);
      invoice.amount_vat = Math.round(amount_product * invoice.vat / 100);
      if (invoice.discount_type === DISCOUNT_TYPE.MONEY) {
        invoice.discount_amount = invoice.discount_amount_inv;
      } else {
        invoice.discount_amount = Math.round(invoice.discount_amount_inv * amount_product / 10000) * 100;
      }
      invoice.amount = amount_product + invoice.amount_vat - invoice.discount_amount - invoice.ecoin_minus * 1000;
      invoice.amount_paid = invoice.amount
      invoice.amount_debt = invoice.amount - invoice.amount_paid > 0 ? invoice.amount - invoice.amount_paid : 0;
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case UPDATE_INVOICE_PRESCRIPTION: {
      const tabCur = arrayTabInvoice.findIndex((element: TabInvoiceCombo) => element.key === action.keyTab);
      arrayTabInvoice[tabCur].data.prescription = action.prescription
      return { ...state, arrayTabInvoice: [...arrayTabInvoice] };
    }

    case LOG_OUT: {
      return {
        ...initialState
      };
    }

    case TRANSFER_BRANCH: {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
}

function getTotalAmount(products: InvoiceComboProduct[]) {
  let total = 0;
  products.map((item: InvoiceComboProduct) => total += item.promotion_flg === 1 ? 0 : (item.price * item.quantity - item.discount));
  return total;
}
