import { SettingGenaralType } from './../dataType/Setting';
import client from "./apiConfig"

export default {
  async getSettingGeneral() {
    const url = '/pharmacy/store/setting'
    return await client.get(url)
  },
  async createSettingGeneral(data: SettingGenaralType) {
    const url = '/pharmacy/store/setting'
    return await client.post(url, data)
  },
  async updateSettingGeneral(data: SettingGenaralType) {
    const url = '/pharmacy/store/setting'
    return await client.put(url, data)
  },
}