import { all } from "redux-saga/effects";

import authSaga from "./auth";
import cartSaga from "./cart";
import invoiceSaga from "./invoice";
// import { ADD_INVOICE, DELETE_INVOICE, UPDATE_INVOICE } from "../types";
import dataPersist from "./dataPersist";
import createDataSample from './createDataSample'
import promotion from "./promotion";
// import orderSaga from './order';

export default function* rootSaga() {
  yield all([
    authSaga(),
    cartSaga(),
    invoiceSaga(),
    dataPersist(),
    createDataSample(),
    promotion()
    // yield takeLatest(ADD_INVOICE, addInvoice),
    // yield takeLatest(DELETE_INVOICE, deleteInvoice),
    // yield takeLatest(UPDATE_INVOICE, updateInvoice)
  ]);
}
