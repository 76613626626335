import client from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants";
import { OrderSearchRequest, ParamsStoreOrderSearch, confirmOrderOnline, completeOrderStore } from "../dataType/OrderOnline";
export default {
  async orderSearch(
    token: string,
    params: OrderSearchRequest,
    _page: number,
    _size: number
  ) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/order/search?page=${page}&size=${size}`;
    const response = await client.post(url, params, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async orderPickup(token: string, med_order_id: number) {
    const url = `/pharmacy/order/pickup?med_order_id=${med_order_id}`
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async orderStoreSearch(
    token: string,
    params: ParamsStoreOrderSearch,
    _page: number,
    _size: number
  ) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/order/store/search?page=${page}&size=${size}`;
    const response = await client.post(url, params, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async getInfoDetailOrderOnlineNews(
    token: string,
    med_order_id: number
  ) {
    const url = `/pharmacy/order?med_order_id=${med_order_id}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async getInfoDetailOrderOnline(
    token: string,
    store_order_id: number
  ) {
    const url = `/pharmacy/order/store?store_order_id=${store_order_id}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async confirmOrderDetailOfStore(
    token: string,
    data: confirmOrderOnline
  ) {
    const url = `/pharmacy/order/store/confirm`;
    const response = await client.post(url, data, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async cancelOrderDetailOfStore(
    token: string,
    store_order_id: number,
    cancel_reason: string
  ) {
    const url = `/pharmacy/order/store/cancel?store_order_id=${store_order_id}&cancel_reason=${cancel_reason}`;
    const response = await client.get(url, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
  async completedOrderDetailOfStore(
    token: string,
    data: completeOrderStore
  ) {
    const url = `/pharmacy/order/store/delivery`;
    const response = await client.post(url, data, {
      headers: {
        Authorization: token
      }
    });
    return response.data;
  },
};
