import React, { useState } from 'react';
import { Modal, notification } from "antd";
import { DrgAccount } from '../../dataType/User';
import { store } from '../..';
import { IS_TRIAL, PACKAGE_CODE, ROLE_CODE } from '../../constants';
import api from '../../api';

type Props = {
  showAboutToExpire: boolean;
  hiddenModal: Function;
  drgStoreId: number;
  accountId: number;
}

export default function ModalExpiredLicense(props: Props) {
  const [loading, setLoading] = useState(false)
  const infoUser: DrgAccount = store.getState().dataPersist.infoUser

  const registrationAccount = async () => {
    setLoading(true)
    if (infoUser?.role_cd !== ROLE_CODE.OWNER) {
      setLoading(false)
      return notification.error({
        message: "Thất bại",
        description: `Chỉ có chủ sở hữu mới có thể đăng kí gia hạn.`
      })
    }
    try {
      const res = await api.store.upgradePackage(PACKAGE_CODE.PHARMACY_PRO, IS_TRIAL.NO)
      notification.success({
        message: "Thành công",
        description: `${res.data.message}`
      })
      setLoading(false)
    }
    catch (error: any) {
      setLoading(false)
      const messageError = error.response.data.message;
      notification.error({
        message: "Thất bại",
        description: `${messageError}`
      })
    }
  }

  const handleCancel = () => {
    props.hiddenModal();
  }
  return (
    <Modal
      title="Gia hạn cửa hàng"
      visible={props.showAboutToExpire}
      onOk={registrationAccount}
      okText="Gia hạn ngay"
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <div style={{ textAlign: 'center' }}>
        <h5>Cửa hàng hiện tại chưa được gia hạn</h5>
        <p>Vui lòng liên hệ qua số điện thoại hoặc email để tiếp tục sử dụng</p>
        <p>Hotline: 0969 191 355 </p>
        <p>Email: hotro@medlink.vn</p>
      </div>
    </Modal>
  )
}
