import { put, takeLatest, select, call, all } from 'redux-saga/effects';
import { GET_ALL_INITIAL_DATA, REQUEST_UPDATED_LIST_USER, REQUEST_UPDATE_PRODUCT_SORT, UPDATE_LIST_PRODUCT_PHARMACY, TRANSFER_BRANCH, GET_LIST_PACKAGE } from '../types';
import {
  saveListDrugPharmacy,
  saveDrugGroup,
  saveUnitsStandard,
  saveDrugKind,
  saveDrugDescriptions,
  saveImportTypes,
  savePaymentMethods,
  saveExportType,
  saveCustomerGroups,
  saveListUser,
  saveListCashflowType,
  saveListUserGroup,
  saveListCashflowPartnerType,
  saveVersion,
  saveInfoUser,
  saveInvoiceSource,
  savePackage,
  saveLicense,
  saveListCampaginType,
  saveCompanyLoyalty,
} from "../actions/dataPersist";
import { saveLocationOfStore } from "../actions/auth";
import api from '../../api';
import { API_KEY_MAP, DEFAULT_VALUE, LIMIT, PAGE_DEFAULT, PRINT_TYPE } from "../../constants";
import { ResponseAxios } from '../../dataType/Response';
import { RootState } from '../reducers';
import { CustomerGroup } from '../../dataType/Customer';
import { Properties } from '../../dataType/Properties';
import { ImportType, PaymentMethod } from '../../dataType/Import';
import { DrgUpdatePrice, DrugGroup, DrugKind, ProductSort, UnitProduct, UnitStandard } from '../../dataType/Product';
import { DrgStore, DrgStoreLicense, DrgStorePackage } from '../../dataType/Store';
import { LocationStore } from '../reducers/auth';
import config from '../../config';
import { Invoice } from '../../dataType/Invoice';
import { CashflowType } from '../../dataType/Cashflow';
import { CashflowPartnerType, UserGroup } from '../../dataType/User';
import { SmsCampaignType } from '../../dataType/smsCampaign';
import { InitSettingPrint, SettingPrintParams, SettingPrintResponse } from '../../dataType/Setting';
import { initSettingPrint } from '../actions/settingPrint';
import { initSettingGeneral } from '../actions/setting';
import { saveLoyaltyRank } from '../actions/loyalty';
import { EmployeeRequest } from '../../dataType/Employee';

function* getGroupCustomer() {
  try {
    let listGroupCustomer: CustomerGroup[] = yield select((state: RootState) => state.dataPersist.listGroupCustomer);
    if (listGroupCustomer && listGroupCustomer.length > 0) return;
    const response: ResponseAxios = yield call(api.customer.getGroupCustomer);
    yield put(saveCustomerGroups(
      response.data
    ))
  } catch (error: any) {
    console.log(error);
  }
};

function* getInfoLicense() {
  try {
    let infoLicense: DrgStoreLicense = yield select((state: RootState) => state.dataPersist.infoLicense);
    if (infoLicense) return
    const res: ResponseAxios = yield call(api.store.getStoreLicense);
    yield put(saveLicense(res.data))
  } catch (err: any) {
    console.log(err);
  }
}

function* getStorePackage() {
  try {
    let listPackage: DrgStorePackage[] = yield select((state: RootState) => state.dataPersist.listPackage);
    if (listPackage && listPackage.length > 0 && listPackage[2].modules.length > 13) return;
    const response: ResponseAxios = yield call(api.store.getStorePackage);
    yield put(savePackage(response.data))
  } catch (error: any) {
    console.log(error);
  }
}

function* updateGroupCustomer() {
  try {
    const response: ResponseAxios = yield call(api.customer.getGroupCustomer);
    yield put(saveCustomerGroups(
      response.data
    ))
  } catch (error: any) {
    console.log(error);
  }
};

function* getExportType() {
  try {
    let listTypeExport: Properties[] = yield select((state: RootState) => state.dataPersist.listTypeExport);
    if (listTypeExport && listTypeExport.length > 0) return;
    const response: ResponseAxios = yield call(api.inventory.getListExportType);
    yield put(saveExportType(response.data))
  } catch (error: any) {
    console.log(error);
  }
};

function* getPaymentMethod() {
  try {
    let listPaymentMethod: PaymentMethod[] = yield select((state: RootState) => state.dataPersist.listPaymentMethod);

    if (listPaymentMethod && listPaymentMethod.length > 0) return;
    const response: ResponseAxios = yield call(api.inventory.getListPaymentMethod);
    yield put(savePaymentMethods(response.data))
  } catch (error: any) {
    console.log(error);
  }
};
function* getImportType() {
  try {
    let listImportType: ImportType[] = yield select(
      (state: RootState) => state.dataPersist.listImportType
    );
    if (listImportType && listImportType.length > 0) return;
    const response: ResponseAxios = yield call(api.inventory.getListImportType);
    yield put(saveImportTypes(response.data))
  } catch (error: any) {
    console.log(error);
  }
};
function* getDescription() {
  try {
    let listDescription: Properties[] = yield select(
      (state: RootState) => state.dataPersist.listDescription
    );
    if (listDescription && listDescription.length > 0) return
    const response: ResponseAxios = yield call(api.product.getDescription);
    yield put(saveDrugDescriptions(response.data))
  } catch (error: any) {
    console.log(error);
  }
};
function* getKindDrug() {
  try {
    let listKindDrug: DrugKind[] = yield select(
      (state: RootState) => state.dataPersist.listKindDrug
    );
    if (listKindDrug && listKindDrug.length > 0) return;
    const response: ResponseAxios = yield call(api.product.getKindDrug);
    yield put(saveDrugKind(response.data))
  } catch (error: any) {
    console.log(error);
  }
};
function* getGroupDrug() {
  try {
    let listDrugGroups: DrugGroup[] = yield select(
      (state: RootState) => state.dataPersist.listGroupDrug
    );

    if (listDrugGroups && listDrugGroups.length > 0) return;
    const response: ResponseAxios = yield call(api.product.getGroupDrug);
    yield put(saveDrugGroup(response.data))
  } catch (error: any) {
    console.log(error);
  }
};
function* getUnitDrug() {
  try {
    let listUnitType: UnitStandard[] = yield select(
      (state: RootState) => state.dataPersist.listUnitType
    );

    if (listUnitType && listUnitType.length > 0) return;
    const response: ResponseAxios = yield call(api.product.getUnitStandard);
    yield put(saveUnitsStandard(response.data))
  } catch (error: any) {
    console.log(error);
  }
};

function* requestListCampaignType() {
  try {
    let listCampaignType: SmsCampaignType[] = yield select(
      (state: RootState) => state.dataPersist.listCampaignType
    );
    if (listCampaignType && listCampaignType.length > 0) return;
    const response: ResponseAxios = yield call(api.smsCampaign.getSmsCampaignType)
    yield put(saveListCampaginType(response.data));
  } catch (err: any) {
    console.log(err);
  }
}

function* getDrugsOfStore() {
  try {
    let version: string = yield select((state: RootState) => state.dataPersist.version ? state.dataPersist.version : '')
    let listDrugPharmacy: ProductSort[] = yield select((state: RootState) => state.dataPersist.listDrugPharmacy);
    if (listDrugPharmacy && listDrugPharmacy.length > 0 && version === config.version) return
    const response: ResponseAxios = yield call(api.product.getAllDrugsOfStore)
    yield put(saveListDrugPharmacy(response.data));
  } catch (error: any) {
    console.log(error)
  }
}

function* getLatLogForAddress(address: string) {
  try {
    let infoStore: DrgStore = yield select((state: RootState) => state.dataPersist.infoStore);
    const response: LocationStore = yield call(api.user.getAddress, address, API_KEY_MAP)
    yield put(saveLocationOfStore({ ...response, drg_store_id: infoStore.drg_store_id }))
  } catch (error: any) {
    console.error(error);
  }
}

function* saveListLoyaltyRank() {
  try {
    const response: ResponseAxios = yield call(api.loyaltyRank.getLoyaltyRank)
    yield put(saveLoyaltyRank(response.data))
  } catch (error: any) {
    console.error(error);
  }
}

function* getListUserOfStore() {
  try {
    const param: EmployeeRequest = {
      drg_store_id: '',
      full_name: '',
      phone_no: '',
      status: '',
      role_cd: '',
      email: '',
    }
    const response: ResponseAxios = yield call(api.employee.getAllEmployee, PAGE_DEFAULT, LIMIT, param)
    yield put(saveListUser(response.data.data))
  } catch (error: any) {
    console.error(error);
  }
}

function getDataLocation() {
  navigator.geolocation.getCurrentPosition(
    position => {
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
    },
    err => console.log(err)
  );
}

function* getLocation() {
  let infoStore: DrgStore = yield select((state: RootState) => state.dataPersist.infoStore);
  let location: LocationStore = yield select((state: RootState) => state.auth.location);
  if (!infoStore || (location && location.drg_store_id === infoStore.drg_store_id)) return;
  if ((infoStore.address1 && infoStore.address1 !== "") ||
    (infoStore.district && infoStore.district !== "") ||
    (infoStore.address1 && infoStore.address1 !== "")) {
    const address = `${infoStore.address1 ? infoStore.address1 : ""} ${infoStore.district ? infoStore.district : ""} ${infoStore.city ? infoStore.city : ""}`
    yield call(getLatLogForAddress, address);
  } else {
    const location: LocationStore = yield call(getDataLocation)
    yield put(saveLocationOfStore({ ...location, drg_store_id: infoStore.drg_store_id }))
  }
};

function* updateListProduct() {
  try {
    const response: ResponseAxios = yield call(api.product.getAllDrugsOfStore)
    yield put(saveListDrugPharmacy(response.data));
  } catch (error: any) {
    console.log(error)
  }
}
function* getSettingGeneral() {
  try {
    const res: ResponseAxios = yield call(api.settingGeneral.getSettingGeneral)
    yield put(initSettingGeneral(res.data));
  } catch (err) {
    console.log(err)
  }
}

function* updateListProductPharmacy(action: any) {
  const data: DrgUpdatePrice[] = action.payload
  const listDrugPharmacy: ProductSort[] = yield select((state: RootState) => state.dataPersist.listDrugPharmacy);
  for (let i = 0; i < data.length; i++) {
    const drug: DrgUpdatePrice = data[i]
    const drugUpdate = listDrugPharmacy.find((drugPharmacy: ProductSort) => drugPharmacy.drug_id === drug.drug_id)
    const drugUnitUpdate = drugUpdate && drugUpdate.units.find((unit: UnitProduct) => unit.unit_name === drug.unit_name)
    if (!drugUpdate || !drugUnitUpdate) {
      const params = {
        drg_barcode: '',
        drug_name: drug.drg_drug_cd,
        drug_group_cd: '',
        drug_kind_cd: '',
        company: '',
      }
      try {
        const res: ResponseAxios = yield call(api.product.getDrugStorePharmacy, params);
        if (!res.data.data.length) return
        const drugPharmacy = { ...res.data.data[0], units: res.data.data[0].drug_units }
        listDrugPharmacy.push(drugPharmacy)
      } catch (error: any) {
        console.log(error)
      }
    } else {
      const indexUnit = drugUpdate.units.findIndex((unit: UnitProduct) => unit.unit_name === drug.unit_name)
      if (indexUnit < 0) return
      drugUpdate.units[indexUnit].price = drug.price
      drugUpdate.units[indexUnit].price_before_vat = drug.price_before_vat
      drugUpdate.units[indexUnit].wholesale_price = drug.wholesale_price
      drugUpdate.units[indexUnit].wholesale_price_before_vat = drug.wholesale_price_before_vat
    }
  }
  yield put(saveListDrugPharmacy(listDrugPharmacy));
}

function* getCashflowType() {
  const cashFlowType: CashflowType[] = yield select((state: RootState) => state.dataPersist.listCashflowType)
  if (cashFlowType && cashFlowType.length > 0) return;
  try {
    const res: ResponseAxios = yield call(api.cashflow.getListCashflowType)
    yield put(saveListCashflowType(res.data));
  } catch (error: any) {
    console.log(error)
  }
}

function* getUserGroup() {
  const userGroup: UserGroup[] = yield select((state: RootState) => state.dataPersist.listUserGroup);
  if (userGroup && userGroup.length > 0) return;
  try {
    const res: ResponseAxios = yield call(api.user.getListUserGroup)
    yield put(saveListUserGroup(res.data));
  } catch (error: any) {
    console.log(error)
  }
}

function* getInitSettingPrint() {
  try {
    const infoStore: DrgStore = yield select((state: RootState) => state.dataPersist.infoStore);
    const data: SettingPrintParams = {
      document_type_code: '',
      drg_store_id: infoStore.drg_store_id,
      document_size: '',
    }
    const res: ResponseAxios = yield call(api.settingPrint.getSettingPrint, data)
    const listTemplatePrint: SettingPrintResponse[] = res.data
    const printInvoiceType = listTemplatePrint.find(item => item.status === DEFAULT_VALUE.ACTIVE && item.document_type_code === PRINT_TYPE.INVOICE)
    const printExportType = listTemplatePrint.find(item => item.status === DEFAULT_VALUE.ACTIVE && item.document_type_code === PRINT_TYPE.EXPORT)
    const printImportType = listTemplatePrint.find(item => item.status === DEFAULT_VALUE.ACTIVE && item.document_type_code === PRINT_TYPE.IMPORT)
    const initSetting: InitSettingPrint = {
      invoice: {
        print_size: printInvoiceType ? printInvoiceType.document_size : '',
        template: printInvoiceType ? printInvoiceType.template : "",
      },
      templateExport: printExportType ? printExportType.template : "",
      templateImport: printImportType ? printImportType.template : '',
    }
    yield put(initSettingPrint(initSetting));
  } catch (err) {
    console.log(err)
  }
}

function* getCashflowPartnerType() {
  const cashflowPartnerType: CashflowPartnerType[] = yield select((state: RootState) => state.dataPersist.listCashflowPartnerType)
  if (cashflowPartnerType && cashflowPartnerType.length > 1) return;
  try {
    const res: ResponseAxios = yield call(api.cashflow.getCashflowPartnerType)
    yield put(saveListCashflowPartnerType(res.data))
  } catch (error: any) {
    console.log(error)
  }
}

function* getUserInfo() {
  try {
    const res: ResponseAxios = yield call(api.user.getUserInfo)
    yield put(saveInfoUser(res.data))
  } catch (error: any) {
    console.log(error)
  }
}
function* getInvoiceSource() {
  const invoiceSoure: Invoice[] = yield select((state: RootState) => state.dataPersist.listInvoiceSource);
  if (invoiceSoure && invoiceSoure.length > 0) return;
  try {
    const res: ResponseAxios = yield call(api.invoice.getInvoiceSource)
    yield put(saveInvoiceSource(res.data))
  } catch (error: any) {
    console.log(error)
  }
}

function* getVersion() {
  let version: string = yield select((state: RootState) => state.dataPersist.version ? state.dataPersist.version : '')
  if (version !== config.version) {
    yield put(saveVersion(config.version))
    yield call(getStorePackage)
    yield call(getInfoLicense)
  }

}
function* getInitialData() {
  yield all([
    call(getVersion),
    call(getInfoLicense),
    call(getStorePackage),
    call(getUserInfo),
    call(getGroupCustomer),
    call(getExportType),
    call(getPaymentMethod),
    call(getDescription),
    call(getImportType),
    call(getKindDrug),
    call(getGroupDrug),
    call(getUnitDrug),
    call(getDrugsOfStore),
    call(getLocation),
    call(getListUserOfStore),
    call(getCashflowType),
    call(getUserGroup),
    call(getCashflowPartnerType),
    call(getInvoiceSource),
    call(requestListCampaignType),
    call(getInitSettingPrint),
    call(getSettingGeneral),
    call(saveListLoyaltyRank)
  ]);
}

function* updateInitialData() {
  yield all([
    call(updateGroupCustomer),
    call(updateListProduct)
  ])
}

export default function* () {
  yield takeLatest(GET_ALL_INITIAL_DATA, getInitialData)
  yield takeLatest(TRANSFER_BRANCH, updateInitialData)
  yield takeLatest(REQUEST_UPDATE_PRODUCT_SORT, updateListProduct)
  yield takeLatest(REQUEST_UPDATED_LIST_USER, getListUserOfStore)
  yield takeLatest(UPDATE_LIST_PRODUCT_PHARMACY, updateListProductPharmacy)
  yield takeLatest(GET_LIST_PACKAGE, getStorePackage)
}
