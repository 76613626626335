import { PromotionCreateRequest, Promotion, PromotionSearch } from './../dataType/Promotion';
import client from './apiConfig';

export default {
  async getAllPromotion(page: number, size: number, data: PromotionSearch) {
    const url = `/loyalty/promotion/list?page=${page}&size=${size}`;
    const response = await client.post(url, data);
    return response;
  },
  async createPromotion(data: PromotionCreateRequest) {
    const url = `/loyalty/promotion`;
    const response = await client.post(url, data);
    return response;
  },
  async deletePromotion(id: number) {
    const url = `/loyalty/promotion/${id}`;
    const response = await client.delete(url);
    return response;
  },

  async getDetailPromotion(id: number) {
    const url = `/loyalty/promotion/${id}`;
    const response = await client.get(url);
    return response;
  },

  async updatePromotion(id: number, data: Promotion) {
    const url = `/loyalty/promotion/${id}`;
    const response = await client.put(url, data);
    return response;
  },

  async getHistoryPromtion(page: number, size: number, promotion_id?: number) {
    const url = `/loyalty/promotion/history?page=${page}&size=${size}&promotion_id=${promotion_id}`;
    const response = await client.get(url);
    return response;
  }
}