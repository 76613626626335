import {
  CHANGE_TAB_INVOICE,
  ADD_INVOICE,
  DELETE_INVOICE,
  UPDATE_INVOICE,
  UPDATE_INVOICE_INFO,
  UPDATE_INVOICE_PRESCRIPTION,
  UPDATE_AMOUNT_PAID_INVOICE,
  UPDATE_PROMOTION_INVOICE,
  ADD_PROMOTION_GIFT,
  UPDATE_PROMOTION_PRODUCT,
  ADD_PRODUCT_GIFT_TO_INVOICE,
  UPDATE_LOYALTY_EXCHANGE_ECOIN,
  UPDATE_LOYALTY_EARN_ECOIN,
  RESET_INVOICE_PRESCRIPTION
} from "../types/";
import { AddProductGiftToInvoiceRequest, InvoiceInfo, InvoiceProduct, InvoiceRequest, Prescription, PromotionProductInvoiceType } from "../../dataType/Invoice";
import { Promotion } from "../../dataType/Promotion";
import { LoyaltyEcoinResponse, LoyaltyExchangResponse } from "../../dataType/loyalty";

export const changeTabInvoice = (activeKey: string) => ({
  type: CHANGE_TAB_INVOICE,
  activeKey: activeKey
});
export const addInvoice = () => ({
  type: ADD_INVOICE
});
//keyTab: Each tab has unique key
export const deleteInvoice = (keyTab: string) => ({
  type: DELETE_INVOICE,
  keyTab
});

export const updateInvoice = (keyTab: string, invoiceRequest: InvoiceRequest) => ({
  type: UPDATE_INVOICE,
  keyTab,
  invoiceRequest
});

export const updateInvoiceInfo = (keyTab: string, invoiceInfo: InvoiceInfo, products: InvoiceProduct[]) => ({
  type: UPDATE_INVOICE_INFO,
  keyTab,
  invoiceInfo,
  products
});

export const updateAmountPaidInfo = (keyTab: string, invoiceInfo: InvoiceInfo) => ({
  type: UPDATE_AMOUNT_PAID_INVOICE,
  keyTab,
  invoiceInfo
});

export const updatePrescription = (keyTab: string, prescription: Prescription) => ({
  type: UPDATE_INVOICE_PRESCRIPTION,
  keyTab,
  prescription
});

export const updatePromotionInvoice = (keyTab: string, promotion: Promotion) => ({
  type: UPDATE_PROMOTION_INVOICE,
  keyTab,
  promotion
})

export const addProductGift = (keyTab: string, products: InvoiceProduct[]) => ({
  type: ADD_PROMOTION_GIFT,
  keyTab,
  products
})

export const updatePromotionProductSaga = (data: PromotionProductInvoiceType) => ({
  type: UPDATE_PROMOTION_PRODUCT,
  payload: data
})

export const addProductGiftToInvoice = (data: AddProductGiftToInvoiceRequest) => ({
  type: ADD_PRODUCT_GIFT_TO_INVOICE,
  payload: data
})

export const updateExchangeEcoin = (data: LoyaltyExchangResponse, keyTab: string) => ({
  type: UPDATE_LOYALTY_EXCHANGE_ECOIN,
  data,
  keyTab
})

export const updateEarnEcoin = (data: LoyaltyEcoinResponse, keyTab: string) => ({
  type: UPDATE_LOYALTY_EARN_ECOIN,
  data,
  keyTab
})

export const resetatePrescription = (keyTab: string) => ({
  type: RESET_INVOICE_PRESCRIPTION,
  keyTab,
});