export type LocationResponse = {
  lat: number,
  lng: number,
}
export default {
  async getLocationOfCus(address: string) {
    const geocoder = new google.maps.Geocoder()
    let lat = 0;
    let lng = 0;
    await geocoder.geocode({ 'address': address }, (results, status) => {
      if (status === 'OK') {
        lat = results ? results[0].geometry.location.lat() : 0
        lng = results ? results[0].geometry.location.lng() : 0
      }
    })
    return { lat, lng }
  },
  async getAddress(lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder()
    let address = '';
    await geocoder.geocode({ "location": { lat: lat, lng: lng } }, (results, status) => {
      if (status === 'OK') {
        address = results ? results[0].formatted_address : ''
      }
    })
    return address;
  }
}