import { LoyaltyRankRequest } from "../dataType/loyalty";
import client from "./apiConfig";

export default {
  async getLoyaltyRank() {
    const url = `/loyalty/rank`;
    const response = await client.get(url);
    return response;
  },
  async createLoyaltyRank(data: LoyaltyRankRequest) {
    const url = `/loyalty/rank`;
    const response = await client.post(url, data);
    return response;
  },
  async deleteLoyaltyRank(id: number) {
    const url = `/loyalty/rank/${id}`
    const res = await client.delete(url)
    return res.data
  },
  async updateLoyaltyRank(id: number, data: LoyaltyRankRequest) {
    const url = `/loyalty/rank/${id}`
    const res = await client.put(url, data)
    return res.data
  }
}