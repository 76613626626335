import { LIMIT, PAGE_DEFAULT } from "../constants";
import { CreateCustomerRequest, DrgCustomer, FilterCustomer, SearchCustomerByListGroupRequest, UpdateCustomerGroupRequest } from "../dataType/Customer";
import { queryString } from "../ultils/Api/queryString";
import client from "./apiConfig";
export interface SearchGroupCustomer {
  customer_group_type: string,
  customer_group_name: string,
  page?: number,
  size?: number
}
export default {
  async getCustomer(
    params: {
      phone?: string;
      customer_name?: string;
      page: number;
      size: number;
      customer_type?: string;
      customer_group_cd?: string;
      sort_type?: string;
      sort_property?: string;
      exist_phone?: string;
      selling_point?: number | string;
      city?: string,
      district?: string,
    },
  ) {
    const queryParam = queryString(params)
    const url = `/pharmacy/customer${queryParam}`
    const res = await client.get(url)
    return res
  },
  createCustomer(token: string, data: any) {
    const url = `/pharmacy/customer`;
    return client
      .post(url, data, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  getGroupCustomer() {
    const url = `/pharmacy/customer/group`;
    return client.get(url);
  },
  updateCustomer(data: DrgCustomer) {
    const url = `/pharmacy/customer`;
    return client
      .put(url, data)
      .then(response => {
        return response;
      });
  },
  searchGroupCustomer(params: SearchGroupCustomer) {
    const url = `/pharmacy/customer/group/search${queryString(params)}`
    return client.get(url);
  },
  getInforCustomer(idCustomer: number) {
    const url = `/pharmacy/customer/detail?customer_id=${idCustomer}`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },

  async searchCustomer(page = PAGE_DEFAULT, size = LIMIT, filter: FilterCustomer) {
    const url = `/pharmacy/customer/search${queryString({ page, size })}`
    const res = await client.post(url, filter)
    return res.data
  },

  async createCustomerGroup(param: CreateCustomerRequest) {
    const url = `/pharmacy/customer/group`
    const res = await client.post(url, param)
    return res.data
  },

  async deleteCustomerGroup(customer_group_id: number) {
    const url = `/pharmacy/customer/group${queryString({ customer_group_id })}`
    const res = await client.delete(url)
    return res.data
  },
  async updateCustomerGroup(param: UpdateCustomerGroupRequest) {
    const url = `/pharmacy/customer/group`
    const res = await client.put(url, param)
    return res.data
  },
  async getCustomerByListGroup(page = 1, size = 20, param: SearchCustomerByListGroupRequest) {
    const queryParam = queryString({ page, size })
    const url = `/pharmacy/customer/search-by-group${queryParam}`
    const res = await client.post(url, param)
    return res.data
  },
  async checkCustomerLinked (refCompanyId: number) {
    const url = `/pharmacy/customer/${refCompanyId}`
    return client.get(url);
  }
};
