import { RegisterSmsServiceRequest } from "../dataType/SmsCompany";
import client from "./apiConfig";

export default {
  getInfSmsService() {
    const url = `/sms/company`;
    return client.get(url)
  },
  registerSmsService(params: RegisterSmsServiceRequest) {
    const url = `/sms/company`;
    return client.post(url, params).then(response => {
      return response;
    });
  },

};
