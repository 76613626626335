import { RegisterBrandNameRequest, UpdateBrandNameRequest } from "../dataType/smsBrandname";
import client from "./apiConfig";

export default {
  getInfoBrandName() {
    const url = `/sms/brand-name/search`;
    return client.get(url)
  },
  registerInfoBrandName(params: RegisterBrandNameRequest) {
    const url = `/sms/brand-name`;
    return client.post(url, params).then(response => {
      return response;
    });
  },
  updateInfoBrandName(params: UpdateBrandNameRequest) {
    const url = `/sms/brand-name`;
    return client.put(url, params).then(response => {
      return response;
    });
  },
  deleteInfoBrandName(brand_id: number) {
    const url = `/sms/brand-name?brand_id=${brand_id}`;
    return client.delete(url).then(response => {
      return response;
    });
  }
};
