import auth from './authApi';
import product from './product';
import store from './store';
import user from './user';
import inventory from './inventory'
import provider from './provider'
import customer from './customer'
import notification from './notification'
import report from './report'
import order from "./orderOnline";
import supplyOrder from "./supplyOrder";
import sync from "./sync";
import accountant from './accountant';
import invoice from './invoice';
import media from './media';
import combo from './combo';
import cashflow from './cashflow'
import otp from './otp'
import smsCompany from './smsCompany'
import smsBrandName from './smsBrandName'
import smsMessage from './smsMessage'
import smsCampaign from './smsCampaign'
import smsZaloOa from './smsZaloOa'
import role from './role'
import permission from './permission'
import smsCashflow from './smsCashflow'
import smsWallet from './smsWallet'
import googleMap from './googleMap'
import company from './company'
import analyticDataAtPos from './analyticDataAtPos';
import employee from './employee';
import settingPrint from './settingPrint';
import sellingPoint from './sellingPoint';
import settingGeneral from './settingGeneral';
import promotion from './promotion';
import loyaltyCustomer from './loyaltyCustomer';
import loyaltyRank from './loyaltyRank';
import loyaltyCashflow from './loyaltyCashflow';
import loyaltyEcoin from './loyaltyEcoin';
import loyaltyCompany from './loyaltyCompany';
import loyaltyProduct from './loyaltyProduct';
import banner from './banner';
import promotionProgram from './promotionProgram';

export default {
  auth,
  product,
  store,
  user,
  inventory,
  provider,
  customer,
  notification,
  report,
  order,
  supplyOrder,
  sync,
  accountant,
  invoice,
  media,
  combo,
  cashflow,
  otp,
  smsCompany,
  smsBrandName,
  smsMessage,
  smsCampaign,
  smsZaloOa,
  role,
  permission,
  smsCashflow,
  smsWallet,
  googleMap,
  company,
  analyticDataAtPos,
  employee,
  settingPrint,
  sellingPoint,
  settingGeneral,
  promotion,
  loyaltyCustomer,
  loyaltyRank,
  loyaltyCashflow,
  loyaltyEcoin,
  loyaltyCompany,
  loyaltyProduct,
  banner,
  promotionProgram,
}
