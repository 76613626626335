import {
  ADD_TAB_IMPORT,
  DELETE_TAB_IMPORT,
  SAVE_INFO_IMPORT,
  ADD_TAB_EXPORT,
  DELETE_TAB_EXPORT,
  SAVE_INFO_EXPORT,
  CHANGE_TAB_IMPORT,
  CHANGE_TAB_EXPORT,
  ADD_DRUG_CHECK_INVENTORY,
  DELETE_DRUG_CHECK_INVENTORY,
  REMOVE_DRUG_CHECK_INVENTORY,
  LOG_OUT,
  IMPORT_EXCEL_DRUG_CHECK_INVENTORY,
  IMPORT_DELETE_ALL_NEW_PRODUCT,
  TRANSFER_BRANCH
} from "../types";
import { TabExport } from "../../dataType/Export.d";
import { TabImport } from "../../dataType/Import.d";
import { DrugCheck } from "../../dataType/Inventory";
import { EXPORT_TYPE } from "../../constants";
type inventory = {
  key: string;
  title: string;
};

type actionType = {
  type: string;
  payload: any;
};

export type InitStateInventory = {
  tabImports: TabImport[];
  arrayTabExport: TabExport[];
  activeKeyImport: string;
  activeKeyExport: string;
  arrayDrugCheck: DrugCheck[];
};

const initialState: InitStateInventory = {
  tabImports: [],
  arrayTabExport: [],
  arrayDrugCheck: [],
  activeKeyImport: "1",
  activeKeyExport: "1"
};
export default function inventory(state = initialState, action: actionType) {
  const { arrayTabExport, tabImports, arrayDrugCheck } = state;
  const { type, payload } = action;
  switch (type) {
    case ADD_TAB_IMPORT: {
      const data = [...state.tabImports, payload];
      const dataFilter = data
        .map(e => e["key"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e: any) => data[e])
        .map((e: any) => data[e]);
      return {
        ...state,
        tabImports: dataFilter,
        activeKeyImport: payload.key
      };
    }
    case DELETE_TAB_IMPORT: {
      let activeKeyImport = state.activeKeyImport;
      const imports = state.tabImports.filter(imp => imp.key !== payload);
      if (activeKeyImport === payload && imports.length > 0) {
        activeKeyImport = imports[0].key;
      }
      return {
        ...state,
        tabImports: imports,
        activeKeyImport
      };
    }

    case CHANGE_TAB_IMPORT:
      return { ...state, activeKeyImport: payload };

    case SAVE_INFO_IMPORT: {
      return { ...state, tabImports: [...state.tabImports] };
    }

    case ADD_TAB_EXPORT:
      const data = [...arrayTabExport, payload];
      const dataFilter = data
        .map(e => e["key"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e: any) => data[e])
        .map((e: any) => data[e]);
      return {
        ...state,
        arrayTabExport: dataFilter,
        activeKeyExport: payload.key
      };

    case DELETE_TAB_EXPORT: {
      let activeKeyExport = state.activeKeyExport;
      const exports = payload.isTransferWarehouse
        ? state.arrayTabExport.filter(exp => exp.key !== payload.key && exp.info.export_type === EXPORT_TYPE.TRS)
        : state.arrayTabExport.filter(exp => exp.key !== payload.key && exp.info.export_type !== EXPORT_TYPE.TRS);

      if (activeKeyExport === payload.key && exports.length > 0) {
        activeKeyExport = exports[0].key;
      }
      return {
        ...state,
        arrayTabExport: exports,
        activeKeyExport
      };
    }

    case CHANGE_TAB_EXPORT:
      return { ...state, activeKeyExport: payload };

    case SAVE_INFO_EXPORT: {
      return { ...state, arrayTabExport: [...state.arrayTabExport] };
    }
    case ADD_DRUG_CHECK_INVENTORY: {
      const arrayDrugCheck = state.arrayDrugCheck;
      const product = arrayDrugCheck.find((item: DrugCheck) => item.inv_id === payload.inv_id)
      if (product) {
        const dataFilter = [...state.arrayDrugCheck]
        return {
          ...state,
          arrayDrugCheck: dataFilter
        };
      } else {
        const dataFilter = [payload, ...state.arrayDrugCheck];
        return {
          ...state,
          arrayDrugCheck: dataFilter
        };
      }
    }
    case IMPORT_EXCEL_DRUG_CHECK_INVENTORY: {
      const data = [...state.arrayDrugCheck, ...payload];
      const dataFilter = data
        .map(e => e["inv_id"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e: any) => data[e])
        .map((e: any) => data[e])
        .map(function (el, index) {
          var o = Object.assign({}, el);
          o.key = index;
          return o;
        });
      return {
        ...state,
        arrayDrugCheck: dataFilter
      };
    }
    case IMPORT_DELETE_ALL_NEW_PRODUCT: {
      const tabImport = tabImports.find((element: TabImport) => action.payload === element.key)
      if (tabImport) tabImport.listNewProductsImport = []
      return {
        ...state,
        tabImports: [...tabImports]
      }
    }
    case DELETE_DRUG_CHECK_INVENTORY: {
      const listDrugCheck = arrayDrugCheck.filter(
        (exp: any) => exp !== payload
      );
      return {
        ...state,
        arrayDrugCheck: listDrugCheck
      };
    }
    case REMOVE_DRUG_CHECK_INVENTORY: {
      return {
        ...state,
        arrayDrugCheck: []
      };
    }
    case LOG_OUT: {
      return {
        ...initialState
      };
    }
    case TRANSFER_BRANCH: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
