import client from "./apiConfig";
import { ImportInfoRequest, ImportInvDetailRequest, UpdateImportRequest } from '../dataType/Import.d'
import { PAGE_DEFAULT, LIMIT } from "../constants";
import { SearchExportPayload } from "../dataType/Inventory";
import { ExportRequest, ExportRequestDetail } from "../dataType/Export";

export type ProductCheck = {
  drug_id: number,
  inv_id: number,
  drug_name: string,
  drug_unit_id: number,
  cur_qty: number
}

export type ParamChecking = {
  check_date: string,
  check_user: string,
  note?: string,
  products: ProductCheck[]
}

export default {

  // api create  new import
  importInventory(data: { info: ImportInfoRequest, products: ImportInvDetailRequest[] }) {
    const url = `/pharmacy/inventory/import`;
    let timeout = Math.round(data.products.length / 100) * 10000
    return client
      .post(url, data, {
        timeout: timeout
      })
      .then(response => {
        return response;
      });
  },
  getListImportType() {
    const url = `/pharmacy/inventory/import/type`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },
  getHistoryImport(data: any, pageAPI?: number,
    perPageAPI?: number) {
    let page = pageAPI ? pageAPI : PAGE_DEFAULT;
    let per_page = perPageAPI ? perPageAPI : LIMIT;
    const url = `/pharmacy/inventory/import/search?page=${page}&size=${per_page}`;
    return client
      .post(url, data)
      .then(response => {
        return response;
      });
  },
  getListPaymentMethod() {
    const url = `/pharmacy/payment/method`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },
  // Xuất kho
  getListExportType() {
    const url = `/pharmacy/inventory/export/type`;
    return client
      .get(url)
      .then(response => {
        return response;
      });
  },
  getListDrugInInv(data: {
    drug_id?: number;
    lot?: string;
    page?: number;
    per_page?: number;
    drug_barcode?: string,
    drug_name?: string,
    company?: string,
    status?: string,
    quantity?: number,
    exp_date: boolean
  }) {
    let page = data.page ? data.page : PAGE_DEFAULT;
    let per_page = data.per_page ? data.per_page : LIMIT;
    const url = `/pharmacy/inventory/product?page=${page}&size=${per_page}`;
    return client
      .post(url, data)
      .then(response => {
        return response;
      });
  },
  createExport(token: string, data: { info: ExportRequest, products: ExportRequestDetail[] }) {
    const url = `/pharmacy/inventory/export`
    let timeout = Math.round(data.products.length / 100) * 10000
    return client
      .post(url, data, {
        headers: {
          Authorization: token
        },
        timeout: timeout
      })
      .then(response => {
        return response;
      });
  },
  getHistoryExport(token: string, data: SearchExportPayload, pageAPI?: number,
    perPageAPI?: number) {
    let page = pageAPI ? pageAPI : PAGE_DEFAULT;
    let per_page = perPageAPI ? perPageAPI : LIMIT;
    const url = `/pharmacy/inventory/export/search?page=${page}&size=${per_page}`;
    return client
      .post(url, data, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  createCheckInventory(data: ParamChecking) {
    const url = `/pharmacy/inventory/check`;
    return client
      .post(url, data, {
        timeout: 20000 + data.products.length / 100 * 10000
      })
      .then(response => {
        return response;
      });
  },
  getHistoryCheck(pageAPI: number, perPageAPI: number,
    data: {
      check_user: string,
      from_date: string,
      to_date: string,
      drug_name: string
    }) {
    let page = pageAPI ? pageAPI : PAGE_DEFAULT;
    let per_page = perPageAPI ? perPageAPI : LIMIT;
    const url = `/pharmacy/inventory/check/search?page=${page}&size=${per_page}`;
    return client
      .post(url, data)
      .then(response => {
        return response;
      });
  },
  changeStatusImport(token: string, data: { status: number, import_id: number }) {
    const url = `/pharmacy/inventory/import/approved`;
    return client
      .put(url, data, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  cancelImportSuccess(token: string, importId: number) {
    const url = `/pharmacy/inventory/import/cancel?import_id=${importId}`;
    return client
      .get(url, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },
  changeStatusExport(data: { status: number, export_id: number }) {
    const url = `/pharmacy/inventory/export/approved`;
    return client
      .put(url, data)
      .then(response => {
        return response;
      });
  },

  cancelExport(exportId: number) {
    const url = `/pharmacy/inventory/export?export_id=${exportId}`
    return client.delete(url)
  },

  getInfoImportInvForIdCheckInv(token: string, summary_id: number) {
    const url = `/pharmacy/inventory/check/import?summary_id=${summary_id}`;
    return client
      .get(url, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },

  getInfoExportInvForIdCheckInv(token: string, summary_id: number) {
    const url = `/pharmacy/inventory/check/export?summary_id=${summary_id}`;
    return client
      .get(url, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  },

  updateImportInventory(data: UpdateImportRequest) {
    const url = `/pharmacy/inventory/import`;
    return client
      .put(url, data)
      .then(response => {
        return response;
      });
  },

  getLastProductPrice(token: string, drug_id: number) {
    const url = `/pharmacy/inventory/import/product/price?drug_id=${drug_id}`;
    return client
      .get(url, {
        headers: {
          Authorization: token
        }
      })
      .then(response => {
        return response;
      });
  }
}