import client from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants/";
import { SearchImportInventory, SearchExpiredDate, SearchReportInputDrug, SearchReportQuanlityControl, SearchReportPatient } from "../dataType/Report";
export default {
  reportDashboard(
    token: string
  ) {
    const url = `/pharmacy/report/dashboard`;
    return client.get(url, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },
  getListRevenue(data: {
    from_time: string,
    to_time: string,
    type: string,
    employee_id: number,
    invoice_type?: number,
    invoice_source?: string
  }) {
    const url = `/pharmacy/report/revenue`;
    return client.post(url, data).then(response => {
      return response;
    });
  },
  getExpDate(
    token: string,
    data: {
      page: number,
      per_page: number
    }
  ) {
    let page = data.page ? data.page : PAGE_DEFAULT;
    let per_page = data.per_page ? data.per_page : LIMIT;
    const url = `/pharmacy/product/expDate?page=${page}&size=${per_page}&sorted_by_column=`;
    return client.get(url, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },
  getAboutToExpire(page: number, per_page: number) {
    const url = `/pharmacy/product/aboutToExpire?page=${page}&size=${per_page}`;
    return client.get(url).then(response => {
      return response;
    });
  },
  getAlmostOver(
    _page?: number,
    _per_page?: number
  ) {
    let page = _page ? _page : PAGE_DEFAULT;
    let per_page = _per_page ? _per_page : LIMIT;
    const url = `/pharmacy/product/almostOver?page=${page}&size=${per_page}`;
    return client.get(url).then(response => {
      return response;
    });
  },
  getTopDrug(
    token: string,
    _page?: number,
    _size?: number,
    params?: { date_from: string, date_to: string }
  ) {
    let page = _page ? _page : PAGE_DEFAULT;
    let per_page = _size ? _size : LIMIT;
    const url = `/pharmacy/product/top/drug?page=${page}&size=${per_page}`;
    return client.post(url, params, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },
  reportExportImportExits(
    pageEIP: number,
    perPageEIP: number,
    data: {
      date_from: string,
      date_to: string,
      drg_drug_name: string,
      drg_drug_cd: string
    }
  ) {
    let page = pageEIP ? pageEIP : PAGE_DEFAULT;
    let per_page = perPageEIP ? perPageEIP : LIMIT;
    const url = `/pharmacy/report/statistics/product?page=${page}&size=${per_page}`;
    return client.post(url, data
    ).then(response => {
      return response;
    });
  },

  reportExportImportExitsTotal(
    data: {
      date_from: string,
      date_to: string,
      drg_drug_name: string,
      drg_drug_cd: string
    }
  ) {
    const url = `/pharmacy/report/statistics/product/total`;
    return client.post(url, data).then(response => {
      return response;
    });
  },

  reportExportImportExitsDetail(
    token: string,
    data: {
      date_from: string,
      date_to: string,
      drg_drug_name: string,
      drg_drug_cd: string
    }
  ) {
    const url = `/pharmacy/report/statistics/product/detail`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  detailReportExportImportExits(drug_id: number) {
    const url = `/pharmacy/inventory/product?drug_id=${drug_id}`;
    return client.get(url).then(response => {
      return response;
    });
  },

  getListReportRevenueProduct(token: string,
    _page: number,
    _size: number,
    data: {
      from_time: string,
      to_time: string,
      drug_group: string,
      drug_kind: string,
      company_name: string,
      drg_drug_name: string,
    }) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;

    const url = `/pharmacy/report/revenue/product?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getListReportRevenueEmployee(
    token: string,
    _page: number,
    _size: number,
    data: {
      from_time: string,
      to_time: string,
      employee_id: number
    }) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/report/revenue/employee?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getListReportImportInventory(token: string,
    _page: number,
    _size: number,
    data: SearchImportInventory) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/report/inventory/import?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getListExpiredDateReport(token: string,
    _page: number,
    _size: number,
    data: SearchExpiredDate) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/report/inventory/expireDate?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  detailReportImportInventory(
    token: string,
    data: SearchImportInventory
  ) {
    const url = `/pharmacy/report/inventory/import/detail`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataExportExcel(
    token: String,
    data: SearchImportInventory
  ) {
    const url = `/pharmacy/report/inventory/import/all`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataExportExcelExpDate(
    token: String,
    data: SearchExpiredDate
  ) {
    const url = `/pharmacy/report/inventory/expireDate/all`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataReportInputDrugList(token: string, _page: number, _size: number, data: any) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/report/gpp/product/list?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataExportExcelReportDrug(
    token: String,
    data: SearchReportInputDrug,
    page: number,
    size: number
  ) {
    const url = `/pharmacy/report/gpp/product/list?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataReportQuanlityControl(token: string, _page: number, _size: number, data: any) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/report/gpp/drug/quality?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataExportExcelQuanlityControl(
    token: String,
    data: SearchReportQuanlityControl,
    page: number,
    size: number
  ) {
    const url = `/pharmacy/report/gpp/drug/quality?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataReportPatient(token: string, _page: number, _size: number, data: any) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/report/gpp/customer/history?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataExportExcelPatient(
    token: String,
    data: SearchReportPatient,
    page: number,
    size: number
  ) {
    const url = `/pharmacy/report/gpp/customer/history?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },

  getDataReportOtherProduct(token: string, _page: number, _size: number, data: any) {
    const page = _page ? _page : PAGE_DEFAULT;
    const size = _size ? _size : LIMIT;
    const url = `/pharmacy/report/gpp/product/list?page=${page}&size=${size}`;
    return client.post(url, data, {
      headers: {
        Authorization: token
      }
    }).then(response => {
      return response;
    });
  },
}
