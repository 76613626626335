import React, { Component, Suspense, lazy } from "react";
import { createBrowserHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { logout, loginSuccess, refreshToken } from '../redux/actions/auth';
import { RootState } from "../redux/reducers";
import { UserLogin } from "../redux/reducers/auth";
import { DrgStore } from "../dataType/Store";
import Login from "../views/Login";
import { DrgAccount } from "../dataType/User";
import { getListPackage } from "../redux/actions/dataPersist";
import { updateOptionPrintInvoice } from "../redux/actions/setting";
import { OptionPrintInvoice } from "../redux/reducers/setting";
import { LIST_TYPE_PRINT_STORE_INVOICE } from "../constants";
// import { VERSION_UPDATE_DATA_PERSIST } from "../constants";
// import LoginSubStore from "../views/LoginSubStore";
// import RegisterScreen from "../views/RegisterScreen";
// import Verify from "../views/RegisterVerify";
// import ForgotPassword from '../views/ForgotPassword';


const App = lazy(() => import("./Admin"));
const LoginSubStore = lazy(() => import("../views/LoginSubStore"));
const Verify = lazy(() => import("../views/RegisterVerify"));
const ForgotPassword = lazy(() => import("../views/ForgotPassword"));
const Register = lazy(() => import('../views/Register'))

const renderLoading = () => (
  <div className="d-flex justify-content-center align-self-center">
    <div className="spinner-grow text-success" role="status" style={{ width: 60, height: 60 }}>
    </div>
  </div>
)

const RegisterScreen = (props: any) => (
  <Suspense fallback={renderLoading()}>
    <Register {...props} />
  </Suspense>
)

const LoginSubStoreScreen = (props: any) => (
  <Suspense fallback={renderLoading()}>
    <LoginSubStore {...props} />
  </Suspense>
)

const VerifyScreen = (props: any) => (
  <Suspense fallback={renderLoading()}>
    <Verify {...props} />
  </Suspense>
)

const ForgotPasswordScreen = (props: any) => (
  <Suspense fallback={renderLoading()}>
    <ForgotPassword {...props} />
  </Suspense>
)

const history = createBrowserHistory();


type States = {
  isRender: boolean
}
type Props = {
  token: string | null,
  userInfo: UserLogin | null,
  expertTime: number | null,
  logout: Function,
  storeInfo: DrgStore | null;
  loginSuccess: Function;
  refreshToken: Function;
  subStores: DrgStore[];
  infoUser: DrgAccount | null
  version: string
  getListPackage: Function,
  optionPrintInvoice: OptionPrintInvoice,
  updateOptionPrintInvoice: (data: OptionPrintInvoice) => void
}


class AuthRoute extends Component<Props, States> {

  timeoutInterval: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isRender: false
    }
  }



  async componentWillMount() {
    const { expertTime, refreshToken, getListPackage, optionPrintInvoice, updateOptionPrintInvoice } = this.props;

    /* count time to get refesh token
      - start when open app
      - finish when first refresh token  
    */
    // if (version < '2.3.1') {
    //   logout()
    // }
    /** Get list store package */
    getListPackage()

    if (expertTime && expertTime < Date.now()) {
      refreshToken()
      setTimeout(() => this.setState({ isRender: true }), 500)
    } else {
      this.setState({ isRender: true })
    }
    this.trackingRefreshToken()
    if (optionPrintInvoice === undefined) {
      const initOptionPrintInvoice: OptionPrintInvoice = {
        isShowLoyalty: false,
        showStore: LIST_TYPE_PRINT_STORE_INVOICE[0],
        storeInfo: ['BRANCH_NAME', 'ADDRESS', 'PHONE_NO'],
        listStoreIdShow: []
      }
      updateOptionPrintInvoice(initOptionPrintInvoice)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { expertTime } = this.props;
    if (expertTime !== prevProps.expertTime) {
      /* count time to get refesh token
        - start after first refresh token
        - finish when close app
      */
      this.trackingRefreshToken()
    }
  }

  trackingRefreshToken = () => {
    clearInterval(this.timeoutInterval)
    this.timeoutInterval = setInterval(() => {
      const { expertTime, refreshToken } = this.props;
      if (expertTime == null) clearInterval(this.timeoutInterval)
      if (expertTime && expertTime < Date.now()) {
        refreshToken()
        clearInterval(this.timeoutInterval)
      }
    }, 1000)
  }

  renderLoading = () => (
    <div className="d-flex justify-content-center align-self-center">
      <div className="spinner-grow text-success" role="status" style={{ width: 60, height: 60 }}>
      </div>
    </div>
  )

  renderAppComponent = (props: any) => {
    const { token } = this.props;
    return (
      token ?
        <Suspense fallback={this.renderLoading()}>
          <App {...props} />
        </Suspense> :
        <Route
          render={({ location }) => (
            <Redirect
              to={{
                pathname: "",
                state: { from: location }
              }}
            />
          )}
        />
    )
  }

  renderHomePageScreen = (props: any) => {
    const { token, infoUser } = this.props;
    if (token && infoUser) {
      history.replace("/nhathuoc/tongquan")
    }
    return (
      <Suspense fallback={this.renderLoading()}>
        <Login {...props} />
      </Suspense>
    )
  }

  render() {
    const { isRender } = this.state
    return (
      <Router history={history}>
        {isRender
          ? <Switch>
            <Route exact path="/" component={this.renderHomePageScreen} />
            <Route path="/dang-ky" component={RegisterScreen} />
            <Route path="/chi-nhanh" component={LoginSubStoreScreen} />
            <Route path="/kich-hoat" component={VerifyScreen} />
            <Route path="/quen-mat-khau" component={ForgotPasswordScreen} />
            <Route path="/nhathuoc" component={this.renderAppComponent} />
            <Route path="*"
              render={({ location }) => (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: location }
                  }}
                />
              )}
            />
          </Switch>
          : renderLoading()}
      </Router>
    )
  }
}
function mapStateToProps(state: RootState) {
  const { token, userInfo, expertTime } = state.auth;
  const infoUser = state.dataPersist.infoUser
  const storeInfo = state.dataPersist.infoStore;
  const subStores = state.dataPersist.listAllStorePharmacy;
  const version = state.dataPersist.version;
  const optionPrintInvoice = state.setting.optionPrintInvoice;
  return { token, userInfo, expertTime, storeInfo, subStores, infoUser, version, optionPrintInvoice };
}
const mapDispatchToProps = {
  logout,
  loginSuccess,
  refreshToken,
  getListPackage,
  updateOptionPrintInvoice,
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);


