import { DrgEmployeeType } from './../../dataType/Employee';
import { LoyaltyRankResponse, LoyaltyCompany } from '../../dataType/loyalty';
import { SAVE_INFO_COMPANY, SAVE_INFO_BRAND_NAME, SAVE_INFO_ZALO_OA, SAVE_STORE_LICENSE, SAVE_STORE_PACKAGE, GET_LIST_CAMPAGIN, SAVE_COMPANY_TYPE, SAVE_LOYALTY_RANK, SAVE_COMPANY_LOYALTY, UPDATE_SHOW_BANNER } from './../types/index';
import {
  KIND_DRUG,
  UNIT_TYPE,
  GROUP_DRUG,
  DESCRIPTION,
  SAVE_USER_INFO,
  GET_INFO_USER,
  LOG_OUT,
  ADD_LIST_DRUG_PHARMACY,
  TYPE_IMPORT,
  PAYMENT_METHOD,
  TYPE_EXPORT,
  GROUP_CUSTOMER,
  REMOVE_DRUG_PHARMACY,
  GET_ALL_STORE_PHARMACY,
  GET_LIST_USER,
  ADD_LIST_CASHFLOW_TYPE,
  ADD_LIST_USER_GROUP,
  ADD_LIST_CASHFLOW_PARTNER_TYPE,
  ADD_CURRENT_VERSION,
  SAVE_INVOICE_SOURCE,
} from "../types";
import {
  DrugKind,
  UnitStandard,
  DrugGroup,
  ProductSort,
} from "../../dataType/Product";
import { DrgStore, DrgStoreLicense, DrgStorePackage } from "../../dataType/Store.d";
import { CashflowPartnerType, DrgAccount, UserGroup } from "../../dataType/User.d";
import { ImportType, InvoiceSource } from "../../dataType/Import.d";
import { PaymentMethod } from "../../dataType/Import.d";
import { CustomerGroup } from "../../dataType/Customer.d";
import { Properties } from "../../dataType/Properties";
import { CashflowType } from "../../dataType/Cashflow";
import { SmsCompany } from '../../dataType/SmsCompany';
import config from '../../config';
import { SmsCampaignType } from '../../dataType/smsCampaign';
import { CompanyInfoResponse } from '../../dataType/Company';

export type InitStateDataPersist = {
  listKindDrug: DrugKind[];
  listUnitType: UnitStandard[];
  listGroupDrug: DrugGroup[];
  listDescription: Properties[];
  infoStore: DrgStore | null;
  infoLicense: DrgStoreLicense | null;
  infoUser: DrgAccount | null;
  listDrugPharmacy: ProductSort[];
  listImportType: ImportType[];
  listPaymentMethod: PaymentMethod[];
  listInvoiceSource: InvoiceSource[];
  listTypeExport: Properties[];
  groupCustomer: CustomerGroup[];
  listAllStorePharmacy: DrgStore[];
  listGroupCustomer: CustomerGroup[];
  listUser: DrgEmployeeType[];
  listCashflowType: CashflowType[];
  listUserGroup: UserGroup[];
  listCashflowPartnerType: CashflowPartnerType[]
  version: string
  smsCompany: SmsCompany | null
  listPackage: DrgStorePackage[]
  listCampaignType: SmsCampaignType[]
  infoCompany: CompanyInfoResponse | null,
  listLoyaltyRank: LoyaltyRankResponse[],
  loyaltyCompany: LoyaltyCompany | null,
  dateShowBanner: string,
};
type actionType = {
  type: string;
  payload: any;
};
const initialState: InitStateDataPersist = {
  listKindDrug: [],
  listUnitType: [],
  listGroupDrug: [],
  listDescription: [],
  listDrugPharmacy: [],
  listImportType: [],
  listPaymentMethod: [],
  listInvoiceSource: [],
  listTypeExport: [],
  listGroupCustomer: [],
  infoStore: null,
  infoLicense: null,
  infoUser: null,
  listAllStorePharmacy: [],
  groupCustomer: [],
  listUser: [],
  listCashflowType: [],
  listUserGroup: [],
  listCashflowPartnerType: [],
  version: config.version,
  smsCompany: null,
  listPackage: [],
  listCampaignType: [],
  infoCompany: null,
  listLoyaltyRank: [],
  loyaltyCompany: null,
  dateShowBanner: ''
};
export default function dataPersist(state = initialState, action: actionType) {
  switch (action.type) {
    case KIND_DRUG: {
      return {
        ...state,
        listKindDrug: action.payload
      };
    }
    case UNIT_TYPE: {
      return {
        ...state,
        listUnitType: action.payload
      };
    }
    case GROUP_DRUG: {
      return {
        ...state,
        listGroupDrug: action.payload
      };
    }
    case DESCRIPTION: {
      return {
        ...state,
        listDescription: action.payload
      };
    }
    case TYPE_IMPORT: {
      return {
        ...state,
        listImportType: action.payload
      };
    }
    case TYPE_EXPORT: {
      return {
        ...state,
        listTypeExport: action.payload
      };
    }
    case PAYMENT_METHOD: {
      return {
        ...state,
        listPaymentMethod: action.payload
      };
    }
    case SAVE_USER_INFO: {
      return {
        ...state,
        infoStore: action.payload
      };
    }
    case SAVE_STORE_LICENSE: {
      return {
        ...state,
        infoLicense: action.payload
      }
    }
    case SAVE_STORE_PACKAGE: {
      return {
        ...state,
        listPackage: action.payload
      }
    }
    case GET_INFO_USER: {
      return {
        ...state,
        infoUser: action.payload
      };
    }
    case GET_LIST_CAMPAGIN: {
      return {
        ...state,
        listCampaignType: action.payload
      }
    }
    case GROUP_CUSTOMER: {
      return {
        ...state,
        listGroupCustomer: action.payload
      };
    }
    case GET_ALL_STORE_PHARMACY: {
      return {
        ...state,
        listAllStorePharmacy: action.payload
      };
    }
    case GET_LIST_USER: {
      return {
        ...state,
        listUser: action.payload
      };
    }
    case LOG_OUT: {
      return {
        ...initialState
      };
    }
    case REMOVE_DRUG_PHARMACY: {
      return {
        ...state,
        listDrugPharmacy: []
      };
    }
    case ADD_LIST_DRUG_PHARMACY: {
      return {
        ...state,
        listDrugPharmacy: action.payload,
      };
    }
    case ADD_LIST_CASHFLOW_TYPE: {
      return {
        ...state,
        listCashflowType: action.payload
      };
    }
    case ADD_LIST_USER_GROUP: {
      return {
        ...state,
        listUserGroup: action.payload
      };
    }
    case ADD_LIST_CASHFLOW_PARTNER_TYPE: {
      return {
        ...state,
        listCashflowPartnerType: action.payload
      }
    }

    case ADD_CURRENT_VERSION: {
      return {
        ...state,
        version: action.payload
      }
    }

    case SAVE_INVOICE_SOURCE: {
      return {
        ...state,
        listInvoiceSource: action.payload
      }
    }
    case SAVE_INFO_COMPANY: {
      return {
        ...state,
        smsCompany: action.payload
      }
    }
    case SAVE_COMPANY_TYPE: {
      return {
        ...state,
        infoCompany: action.payload
      }
    }
    case SAVE_INFO_BRAND_NAME: {
      const smsCompany = JSON.parse(JSON.stringify(state.smsCompany))
      smsCompany && (smsCompany.sms_brand_name = action.payload)
      return {
        ...state,
        smsCompany: smsCompany
      }
    }
    case SAVE_INFO_ZALO_OA: {
      const smsCompany: SmsCompany = JSON.parse(JSON.stringify(state.smsCompany))
      smsCompany && (smsCompany.sms_zalo_oa = action.payload)
      return {
        ...state,
        smsCompany: smsCompany
      }
    }
    case SAVE_LOYALTY_RANK: {
      return {
        ...state,
        listLoyaltyRank: action.payload
      }
    }

    case SAVE_COMPANY_LOYALTY: {
      return {
        ...state,
        loyaltyCompany: action.payload
      }
    }

    case UPDATE_SHOW_BANNER: {
      return {
        ...state,
        dateShowBanner: action.payload
      }
    }

    default:
      return state;
  }
}
