import { PromotionSupplierSearch } from './../dataType/Supplier';
import client, { clientCRM } from "./apiConfig";
import { PAGE_DEFAULT, LIMIT } from "../constants/";
import { SupplierOrderInfoRequest, SupplierOrderRequest } from "../dataType/SupplierOrder";

export interface ProductSearchSystem {
  drug_name?: string,
  drug_code?: string,
  sort_by?: string,
  provider_name?: string,
}

export interface SupplierSearchParams {
  company_id: number;
  company_name: string;
  company_type: string,
  business_type: string
}

export default {
  getListProductInSystem: async (params: ProductSearchSystem, _page?: number, _size?: number) => {
    let page = _page ? _page : PAGE_DEFAULT;
    let size = _size ? _size : LIMIT;
    const url = `/pharmacy/supply/get_drug_list?page=${page}&page_size=${size}`;
    console.log("get api getListAllProductSystem api", url, params)
    return await clientCRM.post(url, params, {});
  },
  getSupplierOrderHistory(
    params: {
      invoice_code: string,
      pay_method: string,
      invoice_type: string,
      drg_drug_name: string,
      from_time: string,
      to_time: string,
      from_issue_datetime: string,
      to_issue_datetime: string
    },
    page: number,
    size: number,
  ) {
    let url = `/pharmacy/supply/order/search?page=${page}&size=${size}`
    return client.post(url, params);
  },

  getSupplierOderHistoryDetail(supply_order_id: number) {
    const url = `/pharmacy/supply/get_order_history/detail?supply_order_id=${supply_order_id}`;
    return clientCRM
      .get(url, {})
      .then(response => {
        return response;
      });
  },

  createSupplierOrder(params: SupplierOrderRequest) {
    console.log(params);
    const url = `/pharmacy/supply/create_order`;
    return clientCRM
      .post(url, params)
      .then(response => {
        return response;
      });
  },
  getListSupplierProduct(page = PAGE_DEFAULT, size = LIMIT, params: any) {
    const url = `/pharmacy/supply/product?page=${page}&size=${size}&product_name=${params.product_name}&company_id=${params.company_id}`;
    return client.get(url);
  },
  createSupplierOrders(data: SupplierOrderInfoRequest) {
    const url = `/pharmacy/supply/order`
    return client.post(url, data);
  },
  getListSupplierSystem(page = PAGE_DEFAULT, size = LIMIT, params: SupplierSearchParams) {
    const url = `/pharmacy/supply/supplier?page=${page}&size=${size}&company_id=${params.company_id}&company_name=${params.company_name}&business_type=${params.business_type}&company_type=${params.company_type}&`;
    return client.get(url);
  },
  supplierLoyalty(ref_company_id: number) {
    const url = `/pharmacy/supply/loyalty/${ref_company_id}`;
    return client.get(url);
  },
  getPromotionSupplier(page: number, size: number, data: PromotionSupplierSearch) {
    const url = `/loyalty/supplier/promotion?page=${page}&size=${size}`;
    return client.post(url, data);
  }
}